import React from "react";

import { Card } from "react-bootstrap";

import UserAccountForm from "./UserAccountForm";

const UserAccountCard = ({ selectedUser, setSelectedUser, setModal }) => {
  return (
    <Card className="sticky-top" style={{ top: 20 }}>
      {selectedUser ? (
        <>
          <Card.Header>
            <Card.Title tag="h5" className="text-center mb-0">
              User Account Information:
            </Card.Title>
          </Card.Header>
          <Card.Body>
            <UserAccountForm
              isEdit={true}
              setModal={setModal}
              selectedUser={selectedUser}
              setSelectedUser={setSelectedUser}
            />
          </Card.Body>
        </>
      ) : (
        <>
          <Card.Header>
            <Card.Title tag="h5" className="text-center mb-0">
              User Account Information:
            </Card.Title>
          </Card.Header>
          <Card.Body className="text-center">No Account Selected</Card.Body>
        </>
      )}
    </Card>
  );
};

export default UserAccountCard;
