import React, { useState, useContext, useEffect } from "react";

import { Formik } from "formik";
import { Alert, Button, Form } from "react-bootstrap";
import Select from "react-select";
import { AlertTriangle } from "react-feather";
import toast from "react-hot-toast";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import axios from "axios";
const HOST_URL = process.env.REACT_APP_HOST_URL;
const ReportForm = ({ selectedStore, setModal, createReport }) => {
  const [reportTypeOptions, setReportTypeOptions] = useState();
  const [reportTypeObjects, setReportTypeObjects] = useState();
  const [selectedReportType, setSelectedReportType] = useState();
  const [selectedDateRange, setSelectedDateRange] = useState([
    new Date(
      new Date(new Date().setDate(new Date().getDate() - 30)).setHours(
        0,
        0,
        0,
        0
      )
    ),
    new Date(),
  ]);
  const [showDateRange, setShowDateRange] = useState();
  const [showReportTypeAlert, setShowReportTypeAlert] = useState(false);
  const [showDateRangeAlert, setShowDateRangeAlert] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const handleSelectReportType = (value) => {
    setSelectedReportType(value);
    setShowReportTypeAlert(false);
    const selectedReportObject = reportTypeObjects.find(
      (reportObject) => reportObject.reportType === value.value
    );
    setShowDateRange(!selectedReportObject.doesntUseDates);
  };
  const handleSelectDateRange = (value) => {
    setSelectedDateRange(value);
    setShowDateRangeAlert(false);
  };
  useEffect(() => {
    const getReportTypes = async () => {
      try {
        const reportTypes = await axios.get(
          `${HOST_URL}/reports/getReportTypes`
        );
        const reportTypesArray = reportTypes.data;
        setReportTypeObjects(reportTypesArray);
        const reportOptions = reportTypesArray.map(({ reportType }) => {
          return {
            value: reportType,
            label: reportType,
          };
        });

        setReportTypeOptions(reportOptions);
      } catch (error) {
        console.log(error);
      }
    };
    getReportTypes();
  }, []);
  const handleSubmit = async () => {
    // check report type is set:
    if (!selectedReportType || !selectedDateRange) {
      if (!selectedReportType) {
        setShowReportTypeAlert(true);
      }
      if (!selectedDateRange) {
        setShowDateRangeAlert(true);
      }
      return;
    }
    setSubmitting(true);
    const result = await createReport(
      selectedReportType.value,
      selectedDateRange
    );
    setSubmitting(false);
    toast.success("Recent reports reloaded");

    setModal({ show: false });
  };

  return (
    <>
      <Form.Label>Report Type:</Form.Label>
      <Select
        color="grey"
        className="react-select-container mb-3"
        classNamePrefix="react-select"
        placeholder="Select a Report Type"
        onChange={handleSelectReportType}
        options={reportTypeOptions}
        isSearchable
        value={selectedReportType}
      />
      {showReportTypeAlert && (
        <Alert className="py-2 justify-content-center" variant="danger">
          Please select a report type
        </Alert>
      )}
      {showDateRange && (
        <>
          <Form.Label className="pe-3 d-block">Date Range: </Form.Label>
          <DateRangePicker
            onChange={handleSelectDateRange}
            value={selectedDateRange}
            className="mb-3"
          />
          {showDateRangeAlert && (
            <Alert className="py-2 justify-content-center" variant="danger">
              Please select a date range
            </Alert>
          )}
        </>
      )}
      <div className="d-flex justify-content-between mt-4 mb-2">
        <Button onClick={handleSubmit} disabled={submitting}>
          Create Amazon Report
        </Button>
        <Button
          variant="secondary"
          onClick={() => {
            setModal({ show: false });
          }}
        >
          Cancel
        </Button>
      </div>
    </>
  );
};

export default ReportForm;
