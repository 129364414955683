import { useState, useEffect } from "react";
import { Col, Row, Card, Button, Tab, ButtonGroup } from "react-bootstrap";
import axios from "axios";
import { X } from "react-feather";
import UIDefaultPage from "../../components/ui/UIDefaultPage";
import AcceptanceDataShipmentTable from "./AcceptanceDataShipmentTable";
import AcceptanceDataItemTable from "./AcceptanceDataItemTable";
import AdminFilters from "../../components/admin/AdminFilters";
import Loader from "../../components/Loader";

const HOST_URL = process.env.REACT_APP_HOST_URL;

const AcceptancePage = () => {
  const [acceptanceDataShipments, setAcceptanceDataShipments] = useState();
  const [acceptanceDataItems, setAcceptanceDataItems] = useState();
  const [activeTab, setActiveTab] = useState("viewShipments");
  const [currentShipment, setCurrentShipment] = useState();
  const [alias, setAlias] = useState();

  const { vendorId, isAdmin } = JSON.parse(localStorage.getItem("user"));
  const needAlias = isAdmin && !alias;

  const fetchData = async () => {
    if (activeTab === "viewShipments" && (!acceptanceDataShipments || alias)) {
      // retrieves structured data -  each shipment with its items
      const data = (
        await axios.get(
          `${HOST_URL}/getData/getVendorAcceptanceData/${vendorId}?viewShipments=true${
            alias ? `&aliasType=${alias.type}&aliasValue=${alias.value}` : ""
          }`
        )
      ).data.vendorAcceptanceData;
      setAcceptanceDataShipments(data);
    } else if (activeTab === "viewItems" && (!acceptanceDataItems || alias)) {
      // viewItems: // just retrieves all the items for all orders (not structured)
      const data = (
        await axios.get(
          `${HOST_URL}/getData/getVendorAcceptanceData/${vendorId}${
            alias ? `?aliasType=${alias.type}&aliasValue=${alias.value}` : ""
          }`
        )
      ).data.vendorAcceptanceData;
      setAcceptanceDataItems(data);
    }
  };

  useEffect(() => {
    if (!needAlias) {
      fetchData();
    }
  }, [activeTab, alias]);

  // admins only - clear all fetched data so the loader will show when new vendor is selected:
  const clearDataHandler = () => {
    setAcceptanceDataShipments(null);
    setAcceptanceDataItems(null);
  };

  const MainTable = (
    <>
      {isAdmin && (
        <AdminFilters
          alias={alias}
          setAlias={setAlias}
          clearData={clearDataHandler}
        />
      )}
      {needAlias ? (
        <Card>
          <Card.Body>
            <h3 className="text-center">Please choose a vendor to view data</h3>
          </Card.Body>
        </Card>
      ) : (
        <Tab.Container id="view-tabs" activeKey={activeTab}>
          <Card>
            <Card.Body>
              <Row className="justify-content-center mt-3 mb-2">
                <Col xs="auto">
                  <ButtonGroup className="mb-3">
                    <Button
                      variant="outline-primary"
                      onClick={() => setActiveTab("viewShipments")}
                      className={activeTab === "viewShipments" ? "active" : ""}
                    >
                      View Shipments
                    </Button>
                    <Button
                      variant="outline-primary"
                      onClick={() => setActiveTab("viewItems")}
                      className={activeTab === "viewItems" ? "active" : ""}
                    >
                      View Items
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <Tab.Content>
            <Tab.Pane eventKey="viewShipments">
              {acceptanceDataShipments ? (
                <Row>
                  <Col md={6}>
                    <Card>
                      {acceptanceDataShipments.length > 0 && (
                        <Card.Header>
                          <p className="text-center">
                            Click on a shipment row to view items for the
                            shipment.
                          </p>
                        </Card.Header>
                      )}
                      <Card.Body>
                        <AcceptanceDataShipmentTable
                          acceptanceDataShipments={acceptanceDataShipments}
                          setCurrentShipment={setCurrentShipment}
                        />
                      </Card.Body>
                    </Card>
                  </Col>
                  {currentShipment && (
                    <Col md={6}>
                      <Card>
                        <Card.Header className="d-flex justify-content-between align-items-center">
                          <div>
                            <h4>
                              Tracking Number:{" "}
                              <span className="text-muted">
                                {currentShipment.trackingNumber}
                              </span>
                            </h4>
                            <h4>
                              Received:{" "}
                              <span className="text-muted">
                                {new Date(
                                  currentShipment.receivedDate
                                ).toLocaleDateString()}
                              </span>
                            </h4>
                          </div>
                          <Button
                            variant="danger"
                            onClick={() => setCurrentShipment()}
                          >
                            <X size={16} />
                          </Button>
                        </Card.Header>
                        <Card.Body>
                          <AcceptanceDataItemTable
                            acceptanceDataItems={currentShipment.items}
                            filteredByShipment={true}
                            currentTrackingNumber={
                              currentShipment.trackingNumber
                            }
                          />
                        </Card.Body>
                      </Card>
                    </Col>
                  )}
                </Row>
              ) : (
                <Card>
                  <Card.Body>
                    <Loader />
                  </Card.Body>
                </Card>
              )}
            </Tab.Pane>
            <Tab.Pane eventKey="viewItems">
              {acceptanceDataItems ? (
                <Card>
                  <Card.Body>
                    <AcceptanceDataItemTable
                      acceptanceDataItems={acceptanceDataItems}
                    />
                  </Card.Body>
                </Card>
              ) : (
                <Card>
                  <Card.Body>
                    <Loader />
                  </Card.Body>
                </Card>
              )}
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      )}
    </>
  );

  return <UIDefaultPage title="Acceptance / Processing" children={MainTable} />;
};

export default AcceptancePage;
