import { ReactComponent as AmazonLogo } from "../../assets/img/marketplace-logos/amazon/amazon-48.svg";
import { ReactComponent as EbayLogo } from "../../assets/img/marketplace-logos/ebay/ebay-48.svg";
import { ReactComponent as ShopifyLogo } from "../../assets/img/marketplace-logos/shopify/shopify-48.svg";

const marketplaces = [
  {
    name: "Amazon",
    marketplaceCode: 0,
    logo: <AmazonLogo />,
    authLink: `https://sellercentral.amazon.com/apps/authorize/consent?application_id=amzn1.sellerapps.app.28bffaaa-5875-48b5-baf2-6495578338b9&redirect_uri=${process.env.REACT_APP_SELLING_PARTNER_APP_REDIRECT_URI}`,
  },
  {
    name: "Ebay",
    marketplaceCode: 1,
    logo: <EbayLogo />,
    authLink: "#",
  },
  {
    name: "Shopify",
    marketplaceCode: 2,
    logo: <ShopifyLogo />,
    authLink: "#",
  },
];

export default marketplaces;
