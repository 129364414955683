import React, { useState, useEffect } from "react";
import axios from "axios";

import { Card, Col } from "react-bootstrap";

import UITablePage from "../../components/ui/UITablePage";
import Table from "../../components/table/Table";
import InfoCards from "./InfoCards";
import AdminFilters from "../../components/admin/AdminFilters";
import Loader from "../../components/Loader";

import baseColumns from "./tableColumns";
import { needStoreNameColumn } from "../../utils/helper";

const HOST_URL = process.env.REACT_APP_HOST_URL;

const FbaCustomerReturnsPage = () => {
  const [customerReturns, setCustomerReturns] = useState();
  const [lastSyncDate, setLastSyncDate] = useState();
  const [infoCardData, setInfoCardData] = useState();
  const [alias, setAlias] = useState();
  const { userId, isAdmin } = JSON.parse(localStorage.getItem("user"));

  const needAlias = isAdmin && !alias;

  const fetchData = async () => {
    const { fbaCustomerReturns: data, syncDate } = (
      await axios.get(
        `${HOST_URL}/getData/${userId}/fbaCustomerReturns${
          alias ? `?aliasType=${alias.type}&aliasValue=${alias.value}` : ""
        }`
      )
    ).data.data;

    setCustomerReturns(data);
    //console.log(syncDate);
    setLastSyncDate(syncDate);
  };

  useEffect(() => {
    if (!needAlias) {
      fetchData();
    }
  }, [userId, alias]);

  const infoCards = <InfoCards cardsData={infoCardData} />;

  let columns = baseColumns;
  if (customerReturns && needStoreNameColumn(customerReturns)) {
    columns = [{ Header: "Store", accessor: "storeName" }, ...baseColumns];
  }

  // admins only - clear fetched data so the loader will show when new vendor is selected:
  const clearDataHandler = () => {
    setCustomerReturns(null);
  };

  const FbaCustomerReturnsTable = () => {
    return (
      <>
        {isAdmin && (
          <AdminFilters
            alias={alias}
            setAlias={setAlias}
            clearData={clearDataHandler}
          />
        )}
        <Col>
          <Card>
            <Card.Body>
              {customerReturns ? (
                <>
                  <div className="d-flex align-items-center justify-content-center">
                    <span className="pt-2 font-weight-bold">
                      Last Synced:{" "}
                      {lastSyncDate &&
                        new Date(lastSyncDate)
                          .toLocaleString("en", {
                            timeStyle: "short",
                            dateStyle: "short",
                          })
                          .replace(",", "")}
                    </span>
                  </div>
                  <Table
                    columns={columns}
                    data={customerReturns}
                    pageName="CustomerReturnHistory"
                  />
                </>
              ) : needAlias ? (
                <h3 className="text-center">
                  Please choose a vendor to view data
                </h3>
              ) : (
                <Loader />
              )}
            </Card.Body>
          </Card>
        </Col>
      </>
    );
  };

  const childTable = <FbaCustomerReturnsTable />;
  return (
    <>
      {
        <UITablePage
          title="Amazon Customer Return History"
          //this will need work when implemented
          // childCardBodies={infoCards}
          children={childTable}
        />
      }
    </>
  );
};

export default FbaCustomerReturnsPage;
