import React, { useState, useEffect } from "react";

import { Button, Card, Col, ListGroup, Row } from "react-bootstrap";

import { Copy, Edit } from "react-feather";
import { CopyToClipboard } from "react-copy-to-clipboard";
import toast from "react-hot-toast";

import UIDefaultPage from "../../components/ui/UIDefaultPage";
import CompanyDetails from "./CompanyDetails";
import PayoutDetails from "./PayoutDetails";

const Navigation = () => (
  <Card>
    <Card.Header>
      <Card.Title tag="h5" className="mb-0">
        Profile Settings
      </Card.Title>
    </Card.Header>
    <ListGroup variant="flush">
      <ListGroup.Item tag="a" href="#" action active>
        Details
      </ListGroup.Item>
      <ListGroup.Item tag="a" href="#" action>
        Users
      </ListGroup.Item>
    </ListGroup>
  </Card>
);

const RemovalAddress = () => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    toast.success("Copied to clipboard");
    setCopied(true);
  };

  const vendor = JSON.parse(localStorage.getItem("vendor"));
  const companyName = vendor?.name || "";

  return (
    <Card>
      <Card.Header>
        <Card.Title tag="h5" className="mb-0">
          <>
            <span>Removal Address</span>
            <CopyToClipboard
              text={`Returns Worldwide/${companyName} 200 South Pemberton Road, Pemberton, NJ 08068 7323130855`}
              onCopy={handleCopy}
            >
              <Button className="ms-3" variant="info" title="Copy to clipboard">
                <Copy size={16} />
              </Button>
            </CopyToClipboard>
          </>
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Card.Text>
          <strong>Returns Worldwide/{companyName}</strong>
          <br /> 200 South Pemberton Road
          <br /> Pemberton NJ
          <br /> 08068
          <br /> 7323130855
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

const children = (
  <>
    <Row>
      {/* <Col md="3" xl="2">
        <Navigation />
      </Col> */}
      <Col md="9" xl="10">
        <CompanyDetails />
        <PayoutDetails />
        {/* <RemovalAddress /> */}
      </Col>
    </Row>
  </>
);

const ProfilePage = () => {
  return <UIDefaultPage title="Manage Profile" children={children} />;
};

export default ProfilePage;
