import {
  Clipboard,
  Tag,
  DollarSign,
  User,
  Clock,
  Link,
  Check,
  Home,
  Truck,
  Smile,
  List,
  BarChart2,
  FileText,
} from "react-feather";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAmazon } from "@fortawesome/free-brands-svg-icons";

const isAdmin = JSON.parse(localStorage.getItem("user"))?.isAdmin;

const homePageSection = [
  {
    href: "/",
    icon: Home,
    title: "Home",
  },
  /*{
    href: "/analytics",
    icon: BarChart2,
    title: "Analytics",
  }, */
];

const amazonPagesSection = [
  {
    href: "/fbaCustomerReturns",
    icon: Clock,
    title: "Customer Return History",
  },
  {
    href: "/removalOrders",
    icon: Clipboard,
    title: "Removal Orders",
  },
  {
    href: "/removalShipments",
    icon: Truck,
    title: "Removal Shipments",
  },
];

const processingPagesSection = [
  {
    href: "/acceptance",
    icon: Check,
    title: "Acceptance / Processing",
  },
  {
    href: "/listings",
    icon: List,
    title: "Inventory / Listings",
  },
  {
    href: "/sales",
    icon: Tag,
    title: "Sales Activity",
  },
];

const accountPagesSection = [
  {
    href: "/payouts",
    icon: DollarSign,
    title: "Payouts",
  },
];

!isAdmin &&
  accountPagesSection.push(
    {
      href: "/linkedAccounts",
      icon: Link,
      title: "Linked Accounts",
    },
    {
      href: "/profile",
      icon: User,
      title: "Manage Profile",
    }
  );

const adminPagesSection = [
  {
    href: "/admin/accounts",
    icon: Smile,
    title: "Accounts",
  },
  {
    href: "/admin/amazonOrders",
    icon: () => <FontAwesomeIcon icon={faAmazon} size="lg" />,
    title: "Amazon Orders",
  },
  {
    href: "/admin/amazonReports",
    icon: FileText,
    title: "Amazon Reports",
  },

  {
    href: "/admin/cronJobs",
    icon: Clock,
    title: "Cron Jobs",
  },
];

const navItems = [
  {
    title: "",
    pages: homePageSection,
  },
  {
    title: "Amazon Info",
    pages: amazonPagesSection,
  },
  {
    title: "Processing",
    pages: processingPagesSection,
  },
  {
    title: "Account",
    pages: accountPagesSection,
  },
  isAdmin
    ? { title: "Admin", pages: adminPagesSection }
    : {
        title: "",
        pages: [],
      },
];

export default navItems;
