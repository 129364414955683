import React from "react";
import { Pie } from "react-chartjs-2";

import { Card, Dropdown, Table } from "react-bootstrap";

import { MoreHorizontal } from "react-feather";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare } from "@fortawesome/free-solid-svg-icons";

import usePalette from "../../../hooks/usePalette";

const PieChart = ({ processingMetrics }) => {
  const palette = usePalette();

  const data = {
    // labels: ["Received", "Inspected", "Sold"],
    labels: ["Received", "Inspected"],
    datasets: [
      {
        data: [
          processingMetrics.totalReceivedItems,
          processingMetrics.totalInspectedItems,
          // processingMetrics.totalSoldItems,
        ],
        backgroundColor: [palette.primary, palette.warning, palette.success],
        borderWidth: 5,
        borderColor: palette.white,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    cutoutPercentage: 70,
    legend: {
      display: false,
    },
  };

  return (
    <Card className="flex-fill w-100">
      <Card.Header>
        <Card.Title tag="h5" className="mb-0">
          Processing Metrics
        </Card.Title>
      </Card.Header>
      <Card.Body className="d-flex">
        <div className="align-self-center w-100">
          <div className="py-3">
            <div className="chart chart-xs">
              <Pie data={data} options={options} />
            </div>
          </div>

          <Table className="mb-0">
            <thead>
              <tr>
                <th>Processing Stage</th>
                <th className="text-end">Total Items</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <FontAwesomeIcon icon={faSquare} className="text-primary" />{" "}
                  Received
                </td>
                <td className="text-end">
                  {processingMetrics.totalReceivedItems}
                </td>
              </tr>
              <tr>
                <td>
                  <FontAwesomeIcon icon={faSquare} className="text-warning" />{" "}
                  Inspected
                </td>
                <td className="text-end">
                  {processingMetrics.totalInspectedItems}
                </td>
              </tr>
              {/* <tr>
                <td>
                  <FontAwesomeIcon icon={faSquare} className="text-success" />{" "}
                  Sold
                </td>
                <td className="text-end">{processingMetrics.totalSoldItems}</td>
              </tr> */}
            </tbody>
          </Table>
        </div>
      </Card.Body>
    </Card>
  );
};

export default PieChart;
