import React from "react";
import { Badge, Card } from "react-bootstrap";
import { DollarSign, Package, Percent, Meh } from "react-feather";

//this should take in an array of data for the cards
function InfoCards({ cardsData }) {
  //map through the array of data and create a card for each item
  //there should be a generic card component that takes in the data and renders the card
  const infoCards = [
    <div className="d-flex align-items-start">
      <div className="flex-grow-1">
        <h3 className="mb-2">5</h3>
        <p className="mb-2">Total Returns</p>
        <div className="mb-0">
          <Badge bg="" className="badge-soft-danger me-2">
            +5.35%
          </Badge>
          <span className="text-muted">Since last month</span>
        </div>
      </div>
      <div className="d-inline-block ms-3">
        <div className="stat">
          <Package className="align-middle text-success" />
        </div>
      </div>
    </div>,
    <div className="d-flex align-items-start">
      <div className="flex-grow-1">
        <h3 className="mb-2">8.27 %</h3>
        <p className="mb-2">Return Rate</p>
        <div className="mb-0">
          <Badge bg="" className="badge-soft-success me-2">
            +5.35%
          </Badge>
          <span className="text-muted">Since last month</span>
        </div>
      </div>
      <div className="d-inline-block ms-3">
        <div className="stat">
          <Percent className="align-middle text-success" />
        </div>
      </div>
    </div>,
    <div className="d-flex align-items-start">
      <div className="flex-grow-1">
        <h3 className="mb-2">B079J3TH9M</h3>
        <p className="mb-2">Most Returned ASIN</p>
      </div>
      <div className="d-inline-block ms-3">
        <div className="stat">
          <Meh className="align-middle text-success" />
        </div>
      </div>
    </div>,
    <div className="d-flex align-items-start">
      <div className="flex-grow-1">
        <h3 className="mb-2">$ 175.33</h3>
        <p className="mb-2">Total Refunds</p>
        <div className="mb-0">
          <Badge bg="" className="badge-soft-success me-2">
            +8.65%
          </Badge>
          <span className="text-muted">Since last month</span>
        </div>
      </div>
      <div className="d-inline-block ms-3">
        <div className="stat">
          <DollarSign className="align-middle text-success" />
        </div>
      </div>
    </div>,
  ];

  return infoCards;
}

export default InfoCards;
