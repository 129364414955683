import React from "react";
import { Helmet } from "react-helmet-async";

import { Container, Row, Col, Card } from "react-bootstrap";

function UITablePage({ title = "Portal", childCardBodies, children }) {
  const cards =
    childCardBodies &&
    childCardBodies.map((body, index) => {
      return (
        <Col key={index} md="6" xl className="d-flex">
          <Card className="flex-fill">
            <Card.Body className="py-4">{body}</Card.Body>
          </Card>
        </Col>
      );
    });

  return (
    <React.Fragment>
      <Helmet title={title} />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">{title}</h1>
        <Row>{cards}</Row>
        <Row>{children}</Row>
      </Container>
    </React.Fragment>
  );
}

export default UITablePage;
