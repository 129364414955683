import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table, Card } from "react-bootstrap";
const HOST_URL = process.env.REACT_APP_HOST_URL;

const ReportCard = ({ store, reportType }) => {
  const [currentLog, setCurrentLog] = useState();

  const fetchLog = async () => {
    const data = await axios.get(
      `${HOST_URL}/reports/getReportLog?storeId=${store.value}&reportType=${reportType}`
    );
    const reportLog = data.data;
    setCurrentLog(reportLog);
  };
  useEffect(() => {
    if (store && reportType) {
      fetchLog();
    }
  }, []);
  const ReportLog = () => {
    return (
      <Card bg="light" style={{ height: "450px", overflowY: "scroll" }}>
        <Card.Body>
          <Table size="sm">
            <thead>
              <tr>
                <th>Date</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {currentLog.map((report, i) => {
                return (
                  <tr key={i}>
                    <td>
                      {new Date(report.updatedAt)
                        .toLocaleString()
                        .replace(",", "")}
                    </td>
                    <td>{report.status}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    );
  };
  return (
    <Card>
      {store && reportType && (
        <>
          <Card.Header>
            <Card.Title tag="h5" className="text-center mb-0">
              {store.label} <br /> {reportType}
              <br /> Log
            </Card.Title>
          </Card.Header>
          <Card.Body>{currentLog && <ReportLog />}</Card.Body>
        </>
      )}
    </Card>
  );
};

export default ReportCard;
