import axios from "axios";
import React, { useEffect, useState } from "react";

import { Button, ButtonGroup, Card, Col, Row, Tab } from "react-bootstrap";
import Loader from "../../components/Loader";

import AdminFilters from "../../components/admin/AdminFilters";
import UIDefaultPage from "../../components/ui/UIDefaultPage";
import RemovalOrderShipmentItemsTable from "./RemovalOrderShipmentItemsTable";
import RemovalOrderShipmentsTable from "./RemovalOrderShipmentsTable";

const HOST_URL = process.env.REACT_APP_HOST_URL;

function RemovalOrderShipmentsPage() {
  const [removalOrderShipments, setRemovalOrderShipments] = useState();
  const [removalOrderShipmentItems, setRemovalOrderShipmentItems] = useState();
  const [lastSyncDate, setLastSyncDate] = useState();
  const [activeTab, setActiveTab] = useState("viewShipments");
  const [currentShipment, setCurrentShipment] = useState();
  const [filter, setFilter] = useState();
  const [alias, setAlias] = useState();

  const { userId, isAdmin } = JSON.parse(localStorage.getItem("user"));

  const queryParams = new URLSearchParams(window.location.search);
  const aliasType = queryParams.get("aliasType");
  const aliasValue = queryParams.get("aliasValue");

  const needAlias = isAdmin && !alias;

  const fetchData = async () => {
    let removalOrderShipments, data, syncDate;
    if (activeTab === "viewShipments" && (!removalOrderShipments || alias)) {
      // retrieves structured data -  each shipment with its shipment items
      ({ removalOrderShipments: data, syncDate } = (
        await axios.get(
          `${HOST_URL}/getData/getRemovalOrderShipments/${userId}?viewShipments=true${
            alias ? `&aliasType=${alias.type}&aliasValue=${alias.value}` : ""
          }`
        )
      ).data);
      setRemovalOrderShipments(data);
    } else if (
      activeTab === "viewItems" &&
      (!removalOrderShipmentItems || alias)
    ) {
      // viewItems: // just retrieves all the items for all shipments (not structured)
      ({ removalOrderShipments: data, syncDate } = (
        await axios.get(
          `${HOST_URL}/getData/getRemovalOrderShipments/${userId}${
            alias ? `?aliasType=${alias.type}&aliasValue=${alias.value}` : ""
          }`
        )
      ).data);
      setRemovalOrderShipmentItems(data);
    }
    setLastSyncDate(syncDate);
  };

  useEffect(() => {
    if (!needAlias) {
      fetchData();
    }
  }, [activeTab, alias]);

  useEffect(() => {
    if (queryParams.get("orderId")) {
      setActiveTab("viewShipments");
      setFilter({
        id: "orderId",
        value: queryParams.get("orderId"),
      });
    }

    if (aliasType && aliasValue) {
      setAlias({ type: aliasType, value: Number(aliasValue) });
    }
  }, []);

  useEffect(() => {
    if (currentShipment) {
      setActiveTab("viewItems");
      setFilter({
        id: "trackingNumber",
        value: currentShipment.trackingNumber,
      });
    }
  }, [currentShipment]);

  // admins only - clear all fetched data so the loader will show when new vendor is selected:
  const clearDataHandler = () => {
    setRemovalOrderShipments(null);
    setRemovalOrderShipmentItems(null);
    setLastSyncDate();
  };

  const MainTable = () => {
    return (
      <>
        {isAdmin && (
          <AdminFilters
            alias={alias}
            setAlias={setAlias}
            clearData={clearDataHandler}
          />
        )}
        <Card>
          {needAlias ? (
            <Card.Body>
              <h3 className="text-center">
                Please choose a vendor to view data
              </h3>
            </Card.Body>
          ) : (
            <Tab.Container id="view-tabs" activeKey={activeTab}>
              <div className="d-flex align-items-center justify-content-center">
                <span className="pt-2 font-weight-bold">
                  Last Synced:{" "}
                  {lastSyncDate &&
                    new Date(lastSyncDate)
                      .toLocaleString("en", {
                        timeStyle: "short",
                        dateStyle: "short",
                      })
                      .replace(",", "")}
                </span>
              </div>
              <Row className="justify-content-center mt-3 mb-2">
                <Col xs="auto">
                  <ButtonGroup className="mb-3">
                    <Button
                      variant="outline-primary"
                      onClick={() => {
                        setActiveTab("viewShipments");
                        setFilter();
                      }}
                      className={activeTab === "viewShipments" ? "active" : ""}
                    >
                      View Shipments
                    </Button>
                    <Button
                      variant="outline-primary"
                      onClick={() => {
                        setActiveTab("viewItems");
                        setFilter();
                      }}
                      className={activeTab === "viewItems" ? "active" : ""}
                    >
                      View Items
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>
              <Tab.Content>
                <Tab.Pane eventKey="viewShipments">
                  <Card.Body>
                    {removalOrderShipments ? (
                      <RemovalOrderShipmentsTable
                        removalOrderShipments={removalOrderShipments}
                        setCurrentShipment={setCurrentShipment}
                        filter={filter}
                        alias={alias}
                      />
                    ) : (
                      <Loader />
                    )}
                  </Card.Body>
                </Tab.Pane>
                <Tab.Pane eventKey="viewItems">
                  <Card.Body>
                    {removalOrderShipmentItems ? (
                      <RemovalOrderShipmentItemsTable
                        removalOrderShipmentItems={removalOrderShipmentItems}
                        filter={filter}
                        alias={alias}
                      />
                    ) : (
                      <Loader />
                    )}
                  </Card.Body>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          )}
        </Card>
      </>
    );
  };

  return (
    <UIDefaultPage
      title="Amazon FBA Removal Shipments"
      children={
        <Col md={12}>
          <MainTable />
        </Col>
      }
    />
  );
}

export default RemovalOrderShipmentsPage;
