import React, { useState } from "react";

import { Card, Tab, Tabs, Button, ListGroup, Modal } from "react-bootstrap";

import ActiveVendorAccountForm from "./ActiveVendorAccountForm";
import UserAccountForm from "./UserAccountForm";
import StoresForm from "./StoresForm";

const ActiveVendorAccountCard = ({
  selectedAccount,
  setSelectedAccount,
  setModal,
  setActiveVendors,
  vendorUsers,
  vendorStores,
}) => {
  const [cardModal, setCardModal] = useState({ show: false, type: null });
  const [selectedVendorUser, setSelectedVendorUser] = useState();
  const [selectedVendorStore, setSelectedVendorStore] = useState();

  // Differentiate between differnt types of modals
  const renderModal = (cardModal) => {
    switch (cardModal.type) {
      case "create_user":
        return {
          title: "Create Vendor User",
          body: (
            <UserAccountForm
              isEdit={false}
              setModal={setCardModal}
              vendorId={selectedAccount.vendorId}
            />
          ),
        };
      case "edit_user":
        return {
          title: "Edit Vendor User",
          body: (
            <UserAccountForm
              isEdit={true}
              setModal={setCardModal}
              selectedUser={selectedVendorUser}
              vendorId={selectedAccount.vendorId}
            />
          ),
        };
      case "create_store":
        return {
          title: "Create Vendor Store",
          body: (
            <StoresForm
              isEdit={false}
              setModal={setCardModal}
              vendorId={selectedAccount.vendorId}
            />
          ),
        };
      case "edit_store":
        return {
          title: "Edit Vendor Store",
          body: (
            <StoresForm
              isEdit={true}
              setModal={setCardModal}
              selectedStore={selectedVendorStore}
              vendorId={selectedAccount.vendorId}
            />
          ),
        };
      default:
        return "";
    }
  };

  return (
    <>
      <Modal
        size="sm"
        show={cardModal.show}
        onHide={() => {
          setCardModal({ show: false, type: null });
        }}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-center w-100">
            {renderModal(cardModal).title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{renderModal(cardModal).body}</Modal.Body>
      </Modal>
      <Card className="sticky-top" style={{ top: 20 }}>
        {selectedAccount ? (
          <Card.Body>
            <Tabs defaultActiveKey="info" className="mb-3" fill>
              <Tab eventKey="info" title="Info">
                <ActiveVendorAccountForm
                  isEdit={true}
                  setModal={setModal}
                  selectedAccount={selectedAccount}
                  setSelectedAccount={setSelectedAccount}
                  setActiveVendors={setActiveVendors}
                />
              </Tab>
              <Tab eventKey="users" title="Users">
                <div className="d-grid mb-3">
                  <Button
                    variant="primary"
                    onClick={() => {
                      setCardModal({ show: true, type: "create_user" });
                    }}
                  >
                    Create User For {selectedAccount.name}
                  </Button>
                </div>
                {vendorUsers && (
                  <ListGroup>
                    {vendorUsers.map((user, i) => (
                      <ListGroup.Item key={i}>
                        <div className="d-flex justify-content-between align-items-center">
                          {user.name}{" "}
                          <Button
                            variant="warning"
                            onClick={() => {
                              setCardModal({ show: true, type: "edit_user" });
                              setSelectedVendorUser(user);
                            }}
                          >
                            Edit
                          </Button>
                        </div>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                )}
              </Tab>
              <Tab eventKey="stores" title="Stores">
                <div className="d-grid mb-3">
                  <Button
                    variant="primary"
                    onClick={() => {
                      setCardModal({ show: true, type: "create_store" });
                    }}
                  >
                    Create Store For {selectedAccount.name}
                  </Button>
                </div>
                {vendorStores && (
                  <ListGroup>
                    {vendorStores.map((store, i) => (
                      <ListGroup.Item key={i}>
                        <div className="d-flex justify-content-between align-items-center">
                          {store.storeName}{" "}
                          <div>
                            <Button
                              variant="primary"
                              title={"Click to go to the reports page"}
                              onClick={() =>
                                (window.location = `/admin/amazonReports?storeId=${store.storeId}&storeName=${store.storeName}`)
                              }
                              className="mx-2"
                            >
                              Reports
                            </Button>
                            <Button
                              variant="warning"
                              onClick={() => {
                                setCardModal({
                                  show: true,
                                  type: "edit_store",
                                });
                                setSelectedVendorStore(store);
                              }}
                            >
                              Edit
                            </Button>
                          </div>
                        </div>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                )}
              </Tab>
            </Tabs>
          </Card.Body>
        ) : (
          <>
            <Card.Header>
              <Card.Title tag="h5" className="text-center mb-0">
                Active Account Information:
              </Card.Title>
            </Card.Header>
            <Card.Body className="text-center">No Account Selected</Card.Body>
          </>
        )}
      </Card>
    </>
  );
};

export default ActiveVendorAccountCard;
