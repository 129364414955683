import { useState, Fragment, useEffect } from "react";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import jwt from "jsonwebtoken";
import { Helmet } from "react-helmet-async";
import { Card } from "react-bootstrap";
import ResetPasswordForm from "../../components/auth/ResetPasswordForm";
import ResetPasswordEmailForm from "../../components/auth/ResetPasswordEmailForm";
import axios from "axios";
const HOST_URL = process.env.REACT_APP_HOST_URL;

const ResetPasswordPage = () => {
  const { token } = useParams();
  const [showNewPassword, setShowNewPassword] = useState(false);
  const decodedJwt = token ? jwt.decode(token) : null;
  const user = decodedJwt?.username;

  useEffect(() => {
    if (decodedJwt) {
      setShowNewPassword(true);
    }
  }, []);

  async function validateResetPasswordToken(token) {
    try {
      await axios.post(`${HOST_URL}/auth/validateResetPasswordToken`, {
        token,
      });
    } catch (error) {
      toast.error(
        "This link has expired, or has been used. Please request a new password reset link."
      );
      setTimeout(() => {
        window.location.href = "/resetPassword";
      }, 3000);
    }
  }
  useEffect(() => {
    if (token) {
      validateResetPasswordToken(token);
    }
  }, [token]);

  return (
    <Fragment>
      <Helmet title="Reset Password" />
      {showNewPassword ? (
        <div className="text-center mt-4">
          {user ? <h2>Welcome back, {user}!</h2> : <h2>Welcome!</h2>}
          <p className="lead">
            Enter a new password below to reset your password.
          </p>
        </div>
      ) : (
        <div className="text-center mt-4">
          <h1 className="h2">Reset password</h1>
          <p className="lead">Enter your email to reset your password.</p>
        </div>
      )}
      <Card className="m-auto w-25">
        <Card.Body>
          <div className="m-sm-4">
            {showNewPassword ? (
              <ResetPasswordForm token={token} />
            ) : (
              <ResetPasswordEmailForm />
            )}
          </div>
        </Card.Body>
      </Card>
    </Fragment>
  );
};

export default ResetPasswordPage;
