import axios from "axios";
import { generatePassword } from "../utils/helper";

const HOST_URL = process.env.REACT_APP_HOST_URL;

const getUsers = async () => {
  const response = (await axios.get(`${HOST_URL}/utils/users`)).data;

  const users = response.data;

  return users;
};

const createUser = async (formValues) => {
  let result;
  // Create a random password initially.
  const password = generatePassword();

  // Create an object with the password and form values.
  const newUser = {
    firstName: formValues.firstName,
    lastName: formValues.lastName,
    username: formValues.username,
    email: formValues.email,
    password: password,
    isAdmin: formValues.isAdmin,
    vendorId: formValues.vendorId,
  };

  // Call the API endpoint for creating a new user.
  const response = (await axios.post(`${HOST_URL}/users/createUser`, newUser))
    .data;

  // If user creation was a success, send a reset password email.
  if (response.success) {
    const resetPasswordResponse = (
      await axios.post(`${HOST_URL}/auth/initiateResetPassword`, {
        email: newUser.email,
      })
    ).data;

    // If the password reset email was sent, respond accordingly.
    if (resetPasswordResponse.success) {
      result = {
        success: true,
        message: `User ${newUser.username} has been successfully created. An email to reset password has been sent.`,
      };
    } else {
      result = {
        success: false,
        message: `User ${newUser.username} has been successfully created. An email to reset password has failed to be sent.`,
      };
    }
  } else {
    result = {
      success: false,
      message: `User ${newUser.username} has failed to be created.`,
    };
  }

  return result;
};

const updateUser = async (selectedUser, formValues) => {
  const updatedUser = Object.assign(selectedUser, formValues);

  const response = (
    await axios.post(`${HOST_URL}/users/updateUser/`, updatedUser)
  ).data;

  return response;
};

const deleteUser = async (userId) => {
  const response = (
    await axios.delete(`${HOST_URL}/users/deleteUser?userId=${userId}`)
  ).data;

  return response;
};

const validateField = async (fieldName, fieldValue) => {
  const response = await axios.get(
    `${HOST_URL}/utils/checkForExistingUserField?${fieldName}=${fieldValue}`
  );

  return response;
};

const signInAsUser = async (signIn, username, adminUser) => {
  await signIn(username, adminUser);
};

const service = {
  getUsers,
  createUser,
  updateUser,
  deleteUser,
  validateField,
  signInAsUser,
};

export default service;
