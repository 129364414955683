import React from "react";
import { Button } from "react-bootstrap";

function ShippingInfoLink({ value, orderId, alias }) {
  return (
    <Button
      variant="link"
      className="link-info"
      title={"Click to view shipment info"}
      onClick={() =>
        (window.location = `/removalShipments/?orderId=${orderId}${
          alias ? `&aliasType=${alias.type}&aliasValue=${alias.value}` : ""
        }`)
      }
    >
      {value}
    </Button>
  );
}

export default ShippingInfoLink;
