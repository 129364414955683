import React from "react";
import * as Yup from "yup";
import { Formik, Field } from "formik";
import { Alert, Button, Form, Card, Row, Col } from "react-bootstrap";

import axios from "axios";

import toast from "react-hot-toast";

const HOST_URL = process.env.REACT_APP_HOST_URL;

function PayoutDetailForm({
  accountNumber,
  routingNumber,
  amazonPreference,
  setEditing,
}) {
  const vendor = JSON.parse(localStorage.getItem("vendor"));

  return (
    <Formik
      initialValues={{
        routingNumber: routingNumber || "",
        accountNumber: accountNumber || "",
        amazonPreference: amazonPreference || "",
        vendorId: vendor.vendorId,
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        routingNumber: Yup.string()
          .matches(/^[0-9]*$/, "Routing Number must be digits only.")
          .max(255)
          .required("Routing Number is required."),
        accountNumber: Yup.string()
          .matches(/^[0-9]*$/, "Account Number must be digits only.")
          .max(255)
          .required("Account Number is required."),
      })}
      onSubmit={async (
        values,
        { setErrors, setStatus, setSubmitting, resetForm }
      ) => {
        try {
          const body = {
            oldRoutingNumber: routingNumber || "",
            oldAccountNumber: accountNumber || "",
            oldAmazonPreference: amazonPreference || "",
            newRoutingNumber: values.routingNumber,
            newAccountNumber: values.accountNumber,
            newAmazonPreference: values.amazonPreference,
            vendorId: vendor.vendorId,
            name: vendor.name,
            email: vendor.email || "",
            phone: vendor.phone || "",
          };

          await axios.post(
            `${HOST_URL}/management/sendPayoutRequestEmail`,
            body
          );

          toast.success("Account details request sent!");
          setEditing(false);
        } catch (error) {
          resetForm();
          console.log(error.response.data);
          const message =
            error.response?.data?.message || "Something went wrong";

          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <Form onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert className="my-3" variant="danger">
              <div className="alert-message">{errors.submit}</div>
            </Alert>
          )}
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Card.Title>Bank Routing Number</Card.Title>
                <Form.Control
                  type="text"
                  name="routingNumber"
                  label="Routing Number"
                  value={values.routingNumber}
                  isInvalid={Boolean(
                    touched.routingNumber && errors.routingNumber
                  )}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  autoFocus
                />
                {!!touched.routingNumber && (
                  <Form.Control.Feedback type="invalid">
                    {errors.routingNumber}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Card.Title>Bank Account Number</Card.Title>
                <Form.Control
                  type="text"
                  name="accountNumber"
                  label="Account Number"
                  value={values.accountNumber}
                  isInvalid={Boolean(
                    touched.accountNumber && errors.accountNumber
                  )}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {!!touched.accountNumber && (
                  <Form.Control.Feedback type="invalid">
                    {errors.accountNumber}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Card.Title>
                  <div className="mb-3">
                    Any restrictions on listing your products on Amazon?
                  </div>
                </Card.Title>
                <div className="d-flex flex-column">
                  <label>
                    <Field
                      type="radio"
                      name="amazonPreference"
                      value="noAmazonRestrictions"
                    />{" "}
                    Sell new as new
                  </label>
                  <label>
                    <Field
                      type="radio"
                      name="amazonPreference"
                      value="restrictAmazonNewListing"
                    />{" "}
                    Sell as used regardless of condition
                  </label>
                  <label>
                    <Field
                      type="radio"
                      name="amazonPreference"
                      value="restrictAmazonAllListings"
                    />{" "}
                    Do not sell on Amazon
                  </label>
                </div>
                {!!touched.amazonPreference && (
                  <Form.Control.Feedback type="invalid">
                    {errors.amazonPreference}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
          </Row>
          <div className="text-center mt-3 d-flex justify-space-between">
            <Button
              type="submit"
              variant="success"
              disabled={
                isSubmitting ||
                (routingNumber === values.routingNumber &&
                  accountNumber === values.accountNumber &&
                  amazonPreference === values.amazonPreference) ||
                errors.routingNumber ||
                errors.accountNumber ||
                errors.amazonPreference
              }
            >
              Request Update
            </Button>
            <Button
              className="mx-3"
              type="submit"
              variant="danger"
              disabled={isSubmitting}
              onClick={() => setEditing(false)}
            >
              Cancel
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default PayoutDetailForm;
