import React from "react";
import { Table } from "react-bootstrap";

import UICard from "../../components/ui/UICard";

import LpnListItem from "./LpnListItem";

function LpnList({ scannedLpns, isLoading, error }) {
  const header = <h4 className="text-center">{"Scanned LPNs"}</h4>;

  const LpnTable = ({ scannedLpnsLocal }) => (
    <Table striped hover>
      <thead>
        <tr>
          <th>LPN</th>
          <th>Name</th>
          <th>ASIN</th>
          <th>Condition</th>
        </tr>
      </thead>
      <tbody>
        {scannedLpnsLocal?.map((scannedLpn, index) => (
          <tr key={index}>
            <LpnListItem scannedItem={scannedLpn} />
          </tr>
        ))}
      </tbody>
    </Table>
  );

  const body = isLoading ? (
    <h1>Loading...</h1>
  ) : error ? (
    <h1>Error</h1>
  ) : !scannedLpns || scannedLpns?.length === 0 ? (
    "No LPNs scanned yet"
  ) : (
    <LpnTable scannedLpnsLocal={scannedLpns} />
  );

  return (
    <UICard
      className="text-center w-75 m-auto my-3"
      header={header}
      body={body}
    />
  );
}

export default LpnList;
