import AmazonASINLink from "../../components/AmazonASINLink";
import imageNotFound from "../../assets/img/imageNotFound.png";

const MAX_LENGTH = 50;

const listingsColumns = [
  {
    id: "image",
    Header: "Product Image",
    accessor: (row) => (
      <a
        href={`https://www.amazon.com/dp/${row.asin}?th=1&psc=1`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          className="img-thumbnail"
          style={{ maxHeight: "75px" }}
          src={`https://ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=${row.asin}&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=SL250`}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = imageNotFound;
          }}
          alt="Item"
        />
      </a>
    ),
  },
  {
    Header: "ASIN",
    accessor: "asin",
    Cell: ({ value }) => <AmazonASINLink ASIN={value} />,
  },
  {
    Header: "SKU",
    accessor: "sku",
  },
  {
    Header: "Title",
    accessor: "productName",
    Cell: ({ value }) => {
      const truncated = value.length > MAX_LENGTH;
      return (
        <span className={truncated ? "cursor-pointer" : ""} title={value}>
          {value.slice(0, MAX_LENGTH)}
          {truncated && "..."}
        </span>
      );
    },
  },
  {
    Header: "Condition",
    accessor: "condition",
  },
  {
    Header: "Quantity",
    accessor: "afnWarehouseQuantity",
  },
  {
    Header: "Your Price",
    accessor: "yourPrice",
  },
];

export default listingsColumns;
