import {
  DateRangeFilter,
  dateBetweenFilterFn,
} from "../../components/table/DateRangeFilter";

export const columns = [
  {
    Header: "Store Name",
    accessor: "storeName",
    disableSortBy: true,
  },
  {
    Header: "Amazon Order ID",
    accessor: "amazonOrderId",
    disableSortBy: true,
  },
  {
    Header: "Fulfillment Channel",
    accessor: "fulfillmentChannel",
    disableSortBy: true,
  },
  {
    Header: "Order Status",
    accessor: "orderStatus",
    disableSortBy: true,
  },
  {
    Header: "Order Type",
    accessor: "orderType",
    disableSortBy: true,
  },
  {
    Header: "Premium Order",
    accessor: ({ isPremiumOrder }) => (isPremiumOrder === false ? "No" : "Yes"),
    id: "premiumOrder",
    disableSortBy: true,
  },
  {
    Header: "Number Of Items Unshipped",
    accessor: "numberOfItemsUnshipped",
    disableSortBy: true,
  },
  {
    Header: "Latest Ship Date",
    sortType: "datetime",
    Cell: ({ value }) => {
      return <span>{new Date(value).toLocaleDateString()}</span>;
    },
    accessor: ({ latestShipDate }) => new Date(latestShipDate),
    id: "latestShipDate",
    Filter: DateRangeFilter,
    filter: dateBetweenFilterFn,
    disableSortBy: true,
  },
  {
    Header: "Ship Service Level",
    accessor: "shipServiceLevel",
    disableSortBy: true,
  },
  { Header: "Marketplace", accessor: "marketplace", disableSortBy: true },
  {
    Header: "Order Total",
    accessor: "orderTotal",
    disableSortBy: true,
  },
  {
    Header: "Last Update Date",
    sortType: "datetime",
    Cell: ({ value }) => {
      return <span>{new Date(value).toLocaleDateString()}</span>;
    },
    accessor: ({ lastUpdateDate }) => new Date(lastUpdateDate),
    id: "lastUpdateDate",
    Filter: DateRangeFilter,
    filter: dateBetweenFilterFn,
    disableSortBy: true,
  },
];

export const columnsItems = [
  {
    Header: "Store Name",
    accessor: "storeName",
    disableSortBy: true,
  },
  {
    Header: "Amazon Order ID",
    accessor: "amazonOrderId",
    disableSortBy: true,
  },
  {
    Header: "Title",
    accessor: "productName",
    disableSortBy: true,
  },
  {
    Header: "SKU",
    accessor: "sku",
    disableSortBy: true,
  },
  {
    Header: "ASIN",
    accessor: "asin",
    disableSortBy: true,
  },
  {
    Header: "Order Item ID",
    accessor: "amazonOrderItemId",
    disableSortBy: true,
  },
  {
    Header: "Quantity Ordered",
    accessor: "quantity",
    disableSortBy: true,
  },
  {
    Header: "Quantity Shipped",
    accessor: "quantityShipped",
    disableSortBy: true,
  },
  {
    Header: "Item Price",
    accessor: "itemPrice",
    disableSortBy: true,
  },
  {
    Header: "Buyer Requested Cancel",
    accessor: ({ buyerRequestedCancel }) => (buyerRequestedCancel ? "Yes" : ""),
    id: "buyerRequestedCancel",
    disableSortBy: true,
  },
];
