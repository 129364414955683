import React, { useState, useEffect } from "react";

import Select from "react-select";

import axios from "axios";

import storeService from "../../services/storeService";
const HOST_URL = process.env.REACT_APP_HOST_URL;

function StoreSelect({ selectedStore, setSelectedStore, clearData }) {
  const [storeOptions, setStoreOptions] = useState();

  useEffect(() => {
    const getStores = async () => {
      try {
        const stores = await storeService.getStores().catch((error) => {
          console.error(error);
        });

        const storeOptions = stores.map((store) => {
          return {
            value: store.storeId,
            label: store.storeName,
          };
        });
        storeOptions.sort((a, b) => {
          if (a.label < b.label) {
            return -1;
          }
          if (a.label > b.label) {
            return 1;
          }
          // names must be equal
          return 0;
        });

        setStoreOptions(storeOptions);
      } catch (error) {
        console.log(error);
      }
    };
    getStores();
  }, []);

  return (
    <Select
      className="react-select-container m-auto"
      classNamePrefix="react-select"
      placeholder="Select a Store"
      onChange={(selectedValue) => {
        clearData();
        setSelectedStore(selectedValue);
      }}
      options={storeOptions}
      isSearchable
      value={selectedStore}
    />
  );
}

export default StoreSelect;
