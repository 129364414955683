import React from "react";
import { Card, Col, Placeholder, Row } from "react-bootstrap";

const StatisticsPlaceholder = () => {
  return (
    <Card className="flex-fill">
      <Card.Body>
        <Row>
          <Col className="mt-0">
            <Placeholder as={Card.Title} animation="glow">
              <Placeholder xs={5} />
            </Placeholder>
          </Col>

          <Col xs="auto">
            <Placeholder animation="glow">
              <Placeholder className="stat-placeholder stat-sm" />
            </Placeholder>
          </Col>
        </Row>
        <Placeholder animation="glow">
          <Placeholder
            xs={2}
            style={{ height: "26.4px" }}
            className="mt-1 mb-4"
          />{" "}
          <Placeholder
            xs={3}
            style={{ height: "26.4px" }}
            className="mt-1 mb-4"
          />
        </Placeholder>
        <Placeholder as={Card.Text} animation="glow">
          <Placeholder xs={2} /> <Placeholder xs={1} /> <Placeholder xs={2} />
        </Placeholder>
      </Card.Body>
    </Card>
  );
};

export default StatisticsPlaceholder;
