import React, { useState, useEffect } from "react";

import axios from "axios";
import { Alert, Button } from "react-bootstrap";
import useAuth from "../../hooks/useAuth";
const HOST_URL = process.env.REACT_APP_HOST_URL;

function MasterAlert() {
  const [alert, setAlert] = useState(null);
  const [alert2, setAlert2] = useState(null);

  const { signOut } = useAuth();
  const user = JSON.parse(localStorage.getItem("user"));
  const hasLinkedAccountsLS = JSON.parse(
    localStorage.getItem("hasLinkedAccounts")
  );
  const isMissingCredentialsLS = JSON.parse(
    localStorage.getItem("isMissingCredentials")
  );
  const wasRememberMeChecked = JSON.parse(
    localStorage.getItem("portal.returnsWorldwide.rememberMe")
  );
  const userId = user?.userId;
  const isAdmin = user?.isAdmin;

  useEffect(() => {
    const fetchData = async () => {
      try {
        let needAlert = false;
        let hasLinkedAccounts, missingCredentials;
        if (!hasLinkedAccountsLS || isMissingCredentialsLS) {
          const linkedAccounts = (
            await axios.get(`${HOST_URL}/linkedAccounts/${userId}`)
          ).data.linkedAccounts;

          hasLinkedAccounts = linkedAccounts?.length > 0;
          if (hasLinkedAccounts) {
            localStorage.setItem("hasLinkedAccounts", true);
          } else {
            localStorage.setItem("hasLinkedAccounts", false);
          }

          missingCredentials = linkedAccounts.some(
            (account) => !account.hasCredentials
          );
          if (missingCredentials) {
            localStorage.setItem("isMissingCredentials", true);
          } else {
            localStorage.setItem("isMissingCredentials", false);
          }
          needAlert = !hasLinkedAccounts || missingCredentials;
        }

        if (needAlert) {
          const message = !hasLinkedAccounts ? (
            <strong>
              You have no linked accounts. Please click{" "}
              <a href="/linkedAccounts">here</a> to get started.
            </strong>
          ) : (
            <strong>
              You have linked accounts with missing credentials. Please click{" "}
              <a href="/linkedAccounts">here</a> to update them.
            </strong>
          );
          setAlert({
            variant: "warning",
            message: message,
          });
        }
      } catch (error) {
        console.log();
      }
    };
    if (!isAdmin && userId) {
      fetchData();
    }

    if (userId && user.adminSignedInAsUser) {
      setAlert2({
        variant: "warning",
        message: (
          <>
            <div className="pt-1">
              <strong>YOU ARE SIGNED IN AS {user.username}</strong>
            </div>

            <Button
              variant="outline-secondary"
              className="mx-2"
              onClick={() => {
                signOut(!wasRememberMeChecked);
                window.location = "/";
              }}
            >
              SIGN OUT
            </Button>
          </>
        ),
      });
    }
  }, [isAdmin, userId, setAlert, hasLinkedAccountsLS]);

  return (
    <>
      {alert2 && (
        <Alert variant={alert2.variant} className="p-3">
          {alert2.message}
        </Alert>
      )}
      {alert && (
        <Alert variant={alert.variant} className="p-3">
          {alert.message}
        </Alert>
      )}
    </>
  );
}

export default MasterAlert;
