import React, { useState, useEffect } from "react";
import { Card, Col, Row } from "react-bootstrap";

import DownloadCSV from "../../components/table/DownloadCSV";
const NewReportCard = ({ myRef, newReportData }) => {
  const { storeId, reportId, reportType } =
    newReportData.reportResponse || newReportData;
  const { downloadedReport } = newReportData;
  return (
    <Card ref={myRef}>
      {reportId && (
        <>
          <Row className="justify-content-md-center mt-4">
            <Col md="auto">
              <Card.Header>
                {storeId && "Store ID: " + storeId + " "}
                Report ID: {reportId}
              </Card.Header>
            </Col>
            {downloadedReport && (
              <Col md="auto">
                <DownloadCSV
                  data={downloadedReport}
                  pageName={reportType}
                  filteredBy={reportId}
                />
              </Col>
            )}
          </Row>
          <Card.Body>
            <Card
              bg="light"
              style={{
                border: "1px solid",
              }}
            >
              <Card.Body>
                <Card.Title>{reportType}</Card.Title>

                <pre
                  style={{
                    maxHeight: "650px",
                  }}
                >
                  {JSON.stringify(downloadedReport, null, 2)}
                </pre>
              </Card.Body>
            </Card>
          </Card.Body>
        </>
      )}
    </Card>
  );
};

export default NewReportCard;
