import React from "react";

import { Row } from "react-bootstrap";
import AccountCard from "./AccountCard";

const AccountList = ({ logo, accounts, authLink }) => {
  const children = accounts.map((account, index) => {
    return (
      <AccountCard
        logo={logo}
        account={account}
        key={index}
        authLink={authLink}
      />
    );
  });
  return <Row>{children}</Row>;
};

export default AccountList;
