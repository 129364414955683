import React, { useEffect, useState, useRef } from "react";
import * as Yup from "yup";
import { Formik, Field } from "formik";
import { Alert, Button, Form, Row, Col } from "react-bootstrap";
import axios from "axios";
import SignatureCanvas from "react-signature-canvas";
import useAuth from "../../hooks/useAuth";
import AmazonStoreNames from "./AmazonStoreNames";

const HOST_URL = process.env.REACT_APP_HOST_URL;

function SignUp() {
  // const [loading, setLoading] = useState();
  const [submitSuccessMessage, setSubmitSuccessMessage] = useState();
  const [storeNameString, setStoreNameString] = useState("");

  // for fields with async validation - this keeps track of whether the
  // field was validated, and what the result was:
  const [validatedField, setValidatedField] = useState({
    vendorName: {
      isValidated: false,
      isUnique: null,
    },
    email: {
      isValidated: false,
      isUnique: null,
    },
  });

  const { signUp } = useAuth();

  const sigCanvas = useRef();

  const formInitialValues = {
    vendorName: "",
    firstName: "",
    lastName: "",
    email: "",
    confirmEmail: "",
    address: "",
    city: "",
    state: "",
    country: "",
    zip: "",
    phone: "",
    routingNumber: "",
    accountNumber: "",
    password: "",
    confirmPassword: "",
    hearAboutUs: "",
    amazonPreference: "noAmazonRestrictions",
    termsAgreement: false,
    note: storeNameString,
    signature: "",
  };

  /* async validation function - calls our API to see if the field already exists.
   * If exists, the function returns false, otherwise returns true
   */
  const checkForExistingField = async (fieldName, fieldValue) => {
    // this if prevents extra calls to the API:
    if (validatedField[fieldName].isValidated === false && fieldValue) {
      const url = `${HOST_URL}/utils/checkForExistingField?${fieldName}=${fieldValue}&isInitialSignUp=true`;
      try {
        const response = await axios.get(url);
        if (!response.data) {
          setValidatedField({
            ...validatedField,
            [fieldName]: { isValidated: true, isUnique: true },
          });
          return true;
        } else {
          setValidatedField({
            ...validatedField,
            [fieldName]: {
              isValidated: true,
              isUnique: !response.data.fieldExists,
            },
          });
          return !response.data.fieldExists;
        }
      } catch (error) {
        console.log(error);
        setValidatedField({
          ...validatedField,
          [fieldName]: { isValidated: true, isUnique: true },
        });
        return true;
      }
    }
    // need this 'else if' and 'else' at the end so that when these fields are validated
    // on submit, the validation isn't flipped (unique fields gets an error, and non-unique field gets no error)
    else if (
      validatedField[fieldName].isValidated === true &&
      validatedField[fieldName].isUnique === false
    )
      return false;
    else return true;
  };

  // for fields with async validation - when field changes, sets all validation state values to default
  const fieldChangedHandler = (fieldName, fieldValue, handleChange) => {
    setValidatedField({
      ...validatedField,
      [fieldName]: { isValidated: false, isUnique: null },
    });
    handleChange(fieldValue);
  };

  return submitSuccessMessage ? (
    <div>{submitSuccessMessage}</div>
  ) : (
    <Formik
      enableReinitialize={false}
      initialValues={formInitialValues}
      validationSchema={Yup.object().shape({
        vendorName: Yup.string()
          .max(255)
          .required("Company Name is required.")
          .test(
            "Unique Company Name",
            "Company Name already in use.",
            async (value) => await checkForExistingField("vendorName", value)
          ),
        firstName: Yup.string().max(255).required("First Name is required."),
        lastName: Yup.string().max(255).required("Last Name is required."),
        email: Yup.string()
          .email("Email Address must be valid.")
          .max(255)
          .required("Email Address is required.")
          .test(
            "Unique Email",
            "Email Address already in use.",
            async (value) => await checkForExistingField("email", value)
          ),
        confirmEmail: Yup.string()
          .email("Email Address must be valid.")
          .oneOf([Yup.ref("email"), null], "Email Addresses must match.")
          .required("Confirm Email Address is required."),
        address: Yup.string().max(255).required("Address is required"),
        city: Yup.string().max(255).required("City is required"),
        state: Yup.string().max(255).required("State is required"),
        country: Yup.string().max(255).required("Country is required"),
        phone: Yup.string().max(255).required("Phone Number is required."),
        zip: Yup.string().max(255).required("ZIP Code is required."),
        termsAgreement: Yup.boolean().isTrue("Terms agreement is required."),
        signature: Yup.string().required("Signature is required."),
        routingNumber: Yup.string()
          .matches(/^[0-9]*$/, "Routing Number must be digits only.")
          .max(255)
          .required("Routing Number is required."),
        accountNumber: Yup.string()
          .matches(/^[0-9]*$/, "Account Number must be digits only.")
          .max(255)
          .required("Account Number is required."),
        password: Yup.string().max(255).required("Password is required."),
        confirmPassword: Yup.string()
          .max(255)
          .oneOf([Yup.ref("password"), null], "Passwords must match.")
          .required("Confirm Password is required."),
      })}
      validateOnChange={false}
      onSubmit={async (
        values,
        { setErrors, setStatus, setSubmitting, resetForm }
      ) => {
        try {
          setSubmitting(true);

          await signUp(values);
          resetForm();
          setSubmitSuccessMessage(
            "Thank you for reaching out! Your request to sign up for an account with Returns Worldwide Portal has been received. We will review it and reply via email."
          );
        } catch (error) {
          window.scrollTo(0, 0);
          resetForm();

          const { code, message } = error.response.data;

          setStatus({ success: false });
          setErrors({
            submit:
              code === "ERR_ALREADY_EXISTS" ? message : "Something went wrong.",
          });

          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setFieldValue,
      }) => (
        <Form onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert className="p-3" variant="danger">
              {errors.submit}
            </Alert>
          )}

          <Row>
            <Col md={6}>
              <div className="border px-3 pt-3 mb-2">
                <div className="mb-3">
                  <h4>Company Details</h4>
                  <h6 className="text-muted">
                    Please provide your company details.
                  </h6>
                </div>
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="vendorName">Company Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="vendorName"
                    id="vendorName"
                    placeholder="Company Name"
                    value={values.vendorName}
                    isInvalid={Boolean(touched.vendorName && errors.vendorName)}
                    onBlur={handleBlur}
                    onChange={(value) => {
                      fieldChangedHandler("vendorName", value, handleChange);
                    }}
                  />
                  {!!touched.vendorName && (
                    <Form.Control.Feedback type="invalid">
                      {errors.vendorName}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label htmlFor="address">Address</Form.Label>
                  <Form.Control
                    type="text"
                    name="address"
                    id="address"
                    placeholder="Address"
                    value={values.address}
                    isInvalid={Boolean(touched.address && errors.address)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {!!touched.address && (
                    <Form.Control.Feedback type="invalid">
                      {errors.address}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>

                <Row>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label htmlFor="address">City</Form.Label>
                      <Form.Control
                        type="text"
                        name="city"
                        id="city"
                        placeholder="City"
                        value={values.city}
                        isInvalid={Boolean(touched.city && errors.city)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {!!touched.city && (
                        <Form.Control.Feedback type="invalid">
                          {errors.city}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label htmlFor="address">State</Form.Label>
                      <Form.Control
                        type="text"
                        name="state"
                        id="state"
                        placeholder="State"
                        value={values.state}
                        isInvalid={Boolean(touched.state && errors.state)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {!!touched.state && (
                        <Form.Control.Feedback type="invalid">
                          {errors.state}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    {" "}
                    <Form.Group className="mb-3">
                      <Form.Label htmlFor="zip">ZIP Code</Form.Label>
                      <Form.Control
                        type="text"
                        name="zip"
                        id="zip"
                        placeholder="ZIP Code"
                        value={values.zip}
                        isInvalid={Boolean(touched.zip && errors.zip)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {!!touched.zip && (
                        <Form.Control.Feedback type="invalid">
                          {errors.zip}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    {" "}
                    <Form.Group className="mb-3">
                      <Form.Label htmlFor="address">Country</Form.Label>
                      <Form.Control
                        type="text"
                        name="country"
                        id="country"
                        placeholder="Country"
                        value={values.country}
                        isInvalid={Boolean(touched.country && errors.country)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {!!touched.country && (
                        <Form.Control.Feedback type="invalid">
                          {errors.country}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                </Row>

                <Form.Group className="mb-3">
                  <Form.Label htmlFor="phone">Phone Number</Form.Label>
                  <Form.Control
                    type="text"
                    name="phone"
                    id="phone"
                    placeholder="Phone Number"
                    value={values.phone}
                    isInvalid={Boolean(touched.phone && errors.phone)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {!!touched.phone && (
                    <Form.Control.Feedback type="invalid">
                      {errors.phone}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </div>

              <div className="border px-3 pt-3 mb-2">
                <div className="mb-3">
                  <h4>Bank Details</h4>
                  <h6 className="text-muted">
                    Please provide your bank details for us to be able to
                    transfer your funds.
                  </h6>
                </div>

                <Form.Group className="mb-3">
                  <Form.Label htmlFor="routingNumber">
                    Bank Routing Number
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="routingNumber"
                    id="routingNumber"
                    placeholder="Routing Number"
                    value={values.routingNumber}
                    isInvalid={Boolean(
                      touched.routingNumber && errors.routingNumber
                    )}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {!!touched.routingNumber && (
                    <Form.Control.Feedback type="invalid">
                      {errors.routingNumber}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="accountNumber">
                    Bank Account Number
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="accountNumber"
                    id="accountNumber"
                    placeholder="Account Number"
                    value={values.accountNumber}
                    isInvalid={Boolean(
                      touched.accountNumber && errors.accountNumber
                    )}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {!!touched.accountNumber && (
                    <Form.Control.Feedback type="invalid">
                      {errors.accountNumber}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </div>
              <div className=" px-3 pt-3 mb-2">
                <div className="mb-3">
                  <h4>How did you hear about us?</h4>
                </div>
                <Form.Group className="mb-3">
                  <Form.Control
                    as="select"
                    name="hearAboutUs"
                    id="hearAboutUs"
                    value={values.hearAboutUs}
                    isInvalid={Boolean(
                      touched.hearAboutUs && errors.hearAboutUs
                    )}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  >
                    <option value="">Select</option>
                    <option value="Amazon Seller Network">
                      Amazon Seller Network
                    </option>
                    <option value="Customer Referral">Customer Referral</option>
                    <option value="Internet Search">Internet Search</option>
                    <option value="Met at Tradeshow">Met at Tradeshow</option>
                    <option value="other">Other</option>
                  </Form.Control>
                </Form.Group>
              </div>
            </Col>

            <Col md={6}>
              <>
                <div className="border px-3 pt-3 mb-2">
                  <div className="mb-3">
                    <h4>Preferences</h4>
                    <h6 className="text-muted">
                      Any restrictions on listing your products on Amazon?
                    </h6>
                  </div>
                  <Form.Group className="mb-3">
                    <div className="d-flex flex-column">
                      <label>
                        <Field
                          type="radio"
                          name="amazonPreference"
                          value="noAmazonRestrictions"
                        />{" "}
                        Sell new as new
                      </label>
                      <label>
                        <Field
                          type="radio"
                          name="amazonPreference"
                          value="restrictAmazonNewListing"
                        />{" "}
                        Sell as used regardless of condition
                      </label>
                      <label>
                        <Field
                          type="radio"
                          name="amazonPreference"
                          value="restrictAmazonAllListings"
                        />{" "}
                        Do not sell on Amazon
                      </label>
                    </div>
                    {!!touched.amazonPreference && (
                      <Form.Control.Feedback type="invalid">
                        {errors.amazonPreference}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </div>
                <div className="border px-3 pt-3 mb-2">
                  <div className="mb-3">
                    <h4>Account Details</h4>
                    <h6 className="text-muted">
                      Please provide your email and a password to set up your
                      account.
                    </h6>
                  </div>
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="firstName">First Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="firstName"
                      id="firstName"
                      placeholder="First Name"
                      value={values.firstName}
                      isInvalid={Boolean(touched.firstName && errors.firstName)}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {!!touched.firstName && (
                      <Form.Control.Feedback type="invalid">
                        {errors.firstName}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="lastName">Last Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="lastName"
                      id="lastName"
                      placeholder="Last Name"
                      value={values.lastName}
                      isInvalid={Boolean(touched.lastName && errors.lastName)}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {!!touched.lastName && (
                      <Form.Control.Feedback type="invalid">
                        {errors.lastName}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="email">Email Address</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      id="email"
                      placeholder="example@example.com"
                      value={values.email}
                      isInvalid={Boolean(touched.email && errors.email)}
                      onBlur={handleBlur}
                      onChange={(value) =>
                        fieldChangedHandler("email", value, handleChange)
                      }
                    />
                    {!!touched.email && (
                      <Form.Control.Feedback type="invalid">
                        {errors.email}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="confirmEmail">
                      Confirm Email Address
                    </Form.Label>
                    <Form.Control
                      type="email"
                      name="confirmEmail"
                      id="confirmEmail"
                      placeholder="example@example.com"
                      value={values.confirmEmail}
                      isInvalid={Boolean(
                        touched.confirmEmail && errors.confirmEmail
                      )}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {!!touched.confirmEmail && (
                      <Form.Control.Feedback type="invalid">
                        {errors.confirmEmail}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="password">Password</Form.Label>
                    <Form.Control
                      type="password"
                      name="password"
                      id="password"
                      placeholder="Password"
                      value={values.password}
                      isInvalid={Boolean(touched.password && errors.password)}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {!!touched.password && (
                      <Form.Control.Feedback type="invalid">
                        {errors.password}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="confirmPassword">
                      Confirm Password
                    </Form.Label>
                    <Form.Control
                      type="password"
                      name="confirmPassword"
                      id="confirmPassword"
                      placeholder="Confirm Password"
                      value={values.confirmPassword}
                      isInvalid={Boolean(
                        touched.confirmPassword && errors.confirmPassword
                      )}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {!!touched.confirmPassword && (
                      <Form.Control.Feedback type="invalid">
                        {errors.confirmPassword}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </div>
                <div className="mb-3">
                  <h4>Amazon Store Name/s</h4>
                  <h6 className="text-muted">
                    Please provide the name of your Amazon store.
                  </h6>
                </div>
                <Form.Group className="mb-3">
                  <AmazonStoreNames
                    storeNameString={storeNameString}
                    setStoreNameString={setStoreNameString}
                    setFieldValue={setFieldValue}
                  />

                  {/* <Form.Control
                    type="text"
                    name="note"
                    id="note"
                    placeholder="Store Name"
                    value={values.note}
                    isInvalid={Boolean(touched.note && errors.note)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {!!touched.note && (
                    <Form.Control.Feedback type="invalid">
                      {errors.note}
                    </Form.Control.Feedback>
                  )} */}
                </Form.Group>
              </>
            </Col>
          </Row>

          <Row>
            <Col className="border"></Col>
          </Row>

          <Form.Group className="mb-3">
            <Form.Label htmlFor="termsAgreement">Terms & Conditions</Form.Label>
            <Form.Control
              as="textarea"
              rows={5}
              readOnly
              value="NON-DISCLOSURE AND BAILMENT AGREEMENT
              This Non-Disclosure and Bailment Agreement (“AGREEMENT”) is entered into as of the submitted date (the “EFFECTIVE DATE”) by and between Customer listed above (“CUSTOMER”) and RETURNS 360 LLC dba RETURNS WORLDWIDE, 200 South Pemberton Road, Pemberton NJ 08068 (“RETURNS 360”), and all its affiliates and related entities.
              1. DEFINITION OF PROPERTY
              For purposes of this Agreement, the term PROPERTY means any item(s) of tangible property that CUSTOMER owns and entrusts to RETURNS 360 under this Agreement.
              2. BAILMENT
              CUSTOMER agrees that RETURNS 360 can possess and use the Property for the purposes of receiving, evaluating, refurbishing, repackaging, processing, repairing, reselling and/or disposing of the PROPERTY.
              3. TERM
              The terms and conditions in this Agreement shall remain in effect for as long as any item of Property is in the possession of RETURNS 360. This Agreement can be terminated in writing by either party upon fifteen (15) days prior written notice. 
              4. OWNERSHIP
              RETURNS 360 acknowledges that title to, and ownership in, the Property remains with the CUSTOMER, and that RETURNS 360's use and possession of the PROPERTY is by virtue of this Agreement.  Customer affirms that product sent to RETURNS 360 are their rightful property, and that product is in compliance of all applicable rules and laws.  Customer affirms that all products are sourced from legitimate sources, and are not counterfeit, stolen, or gotten by any illegal means.
              5A. RETURNS 360 SERVICES
              RETURNS 360 is a full-service reverse logistics provider that offers complete return processing including receiving returns, evaluating the condition of products, refurbishing, processing and reselling products through various distribution channels and liquidating unsaleable product.
              5B. RESELL OF GOODS
              Unless otherwise instructed, RETURNS 360 is authorized to sell customer's products in all outlets including all and any online marketplace and/or retail location, and Customer agrees to provide RETURNS 360 supporting documentation to such if needed.
              6. RISK OF LOSS
              CUSTOMER agrees to bear the risk of loss of the Property except for any loss or damage incurred as a result of RETURNS 360's gross negligence or willful misconduct.
              7. FEE STRUCTURE
              Unless otherwise agreed upon, Returns Worldwide's fee structure is 60% of Net selling price.  All third party incurred fees will be deducted from selling price including shipping, marketplace or storage fees. CUSTOMER hereby agrees and acknowledges that all payments received hereunder from RETURNS 360 shall be negotiated/deposited within Ninety (90) days of issuance. If CUSTOMER requests issuance of a replacement check for any lost or stale check, RETURNS 360 shall charge a “Check Replacement Fee” of $100.00, which fee shall be deducted from CUSTOMER'S funds.   If customers has not sent additional product to RETURNS 360 for 90 days, the fee will revert to 85% of Net selling price.
              8. NON-DISCLOSURE OF CONFIDENTIAL INFORMATION
              A. Definition of Confidential Information. For purposes of this Agreement, the term “Confidential Information” means all information disclosed to RETURNS 360 or to which RETURNS 360 gains access by, through or on behalf of the CUSTOMER either directly or indirectly and, whether in written, oral, visual, electronic or magnetic form. Confidential Information shall include, without limitation: financial statements and other financial data and information, information concerning assets, sales and vendors, marketing plans and strategies, referral sources, advertising information, service and product information, research, information related to historical, actual and potential customers, and any information regarding the foregoing the CUSTOMER discloses to the RETURNS 360, as well as any copies, extracts, summaries or derivatives therefrom. Despite the foregoing, Confidential Information does not include: (1) information which RETURNS 360 can demonstrate is already in possession of RETURNS 360 at the time of the CUSTOMER's disclosure; (2) information that is now or later becomes part of the public domain, unless such information becomes part of the public domain solely as a result of any action or inaction on the part of RETURNS 360; or (3) information received by RETURNS 360 on a non confidential basis from a third party which RETURNS 360 neither knows nor has reason to know is prohibited from disclosing such information by a contractual, legal or fiduciary obligation.
              B. Use of Confidential Information.
              (i) RETURNS 360 shall use the Confidential Information for the sole purpose of evaluating or conducting its business relationships and opportunities with the CUSTOMER and shall not be used in RETURNS 360's business or to the detriment of CUSTOMER or otherwise in any manner. Except as expressly provided in the next paragraph, RETURNS 360 shall not disclose any Confidential Information to any third party excepting employees of RETURNS 360 who are directly involved in the evaluation of the Confidential Information and independent consultants and advisors of RETURNS 360 who have expressly agreed to be bound by the terms of this Agreement.(ii) Except as otherwise permitted hereunder, RETURNS 360 will not make any of the Confidential Information available or disclose any of the Confidential Information to any person. RETURNS 360 will treat the Confidential Information as confidential, will handle the Confidential Information in the same manner as RETURNS 360 handles its own material of similar character, and will institute or maintain reasonable security procedures designed to prevent either the unauthorized disclosure of the Confidential Information to third parties, or the misappropriation of the Confidential Information by RETURNS 360's employees or third parties. (iii) The parties' obligations of non-disclosure, non-use and confidentiality under this Agreement shall survive for a period of five (5) years following the date of execution of this Agreement.
              C. Exceptions. This Agreement will not apply to disclosures of information required by law. 
              If RETURNS 360 is served with a subpoena, discovery request or other process lawfully compelling production of the Confidential Information, RETURNS 360 shall provide the CUSTOMER with notice of such legal process and the CUSTOMER, at its own expense, may undertake any actions it deems necessary to quash the legal process or preserve the confidentiality of the Confidential Information. 
              D. Ownership and Return of Confidential Information. RETURNS 360 acknowledges that 
              RETURNS 360 has no ownership or proprietary rights in the Confidential Information. Confidential Information furnished in tangible form shall not be duplicated by RETURNS 360 except for purposes of this Agreement.
              9. ENTIRE AGREEMENT
              This Agreement is the entire understanding and agreement between the parties as to its subject matter, and completely overrides (supersedes) all previous understandings, agreements, communications and representations, whether written or oral.
              10. GOVERNING LAW
              This Agreement shall be governed by, and construed in accordance with, the laws of the state of New Jersey, without regard to that state's rules concerning conflict of laws.
              11. AMENDMENTS
              No waiver, amendment or modification of any provisions of this Agreement shall be effective unless in writing and signed by the party against whom such waiver, amendment or modification is sought to be enforced. No failure or delay by either party in exercising any right, power or remedy under this Agreement, except as specifically provided in this Agreement, shall operate as a waiver of any such right, power or remedy. 
              THIS AGREEMENT has been signed by the parties or their duly authorized representatives to become effective as of the date electronically signed and submitted."
            />
            <br />
            <Form.Check
              name="termsAgreement"
              id="termsAgreement"
              label="By clicking the submit button, I agree to terms & conditions."
              isInvalid={Boolean(
                touched.termsAgreement && errors.termsAgreement
              )}
              onBlur={handleBlur}
              onChange={handleChange}
              feedback={errors.termsAgreement}
              // feedbackType="invalid"
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label htmlFor="signature">Signature</Form.Label>
            <br />
            <SignatureCanvas
              canvasProps={{
                className: "sigCanvas form-control",
                height: 100,
              }}
              ref={sigCanvas}
              onEnd={() => {
                const URL = sigCanvas.current
                  .getTrimmedCanvas()
                  .toDataURL("image/png");
                //const dataArray = sigCanvas.current.toData();

                setFieldValue("signature", URL);
              }}
            />
            <Button
              className="mt-2"
              variant="secondary"
              onClick={() => {
                sigCanvas.current.clear();
                setFieldValue("signature", "");
              }}
            >
              Clear
            </Button>
            <Form.Control
              type="hidden"
              name="signature"
              id="signature"
              value={values.signature}
              isInvalid={Boolean(errors.signature)}
            />
            {!!errors.signature && (
              <Form.Control.Feedback type="invalid">
                {errors.signature}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <div className="text-center mt-3">
            <Button
              type="submit"
              variant="primary"
              size="lg"
              disabled={
                isSubmitting ||
                (Object.keys(touched).length === 0 &&
                  touched.constructor === Object)
              }
            >
              Sign up
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default SignUp;
