import axios from "axios";
import React, { useState } from "react";
import toast from "react-hot-toast";

import { Button, FormControl } from "react-bootstrap";

import UICard from "../../components/ui/UICard";
const HOST_URL = process.env.REACT_APP_HOST_URL;

export default function LpnForm({
  scannedLpns,
  setScannedLpns,
  setCurrentItem,
}) {
  const [lpnValue, setLpnValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const header = <h4 className="text-center">{"LPNs"}</h4>;

  const getShipmentItem = async () => {
    if (!lpnValue) {
      toast.error("Please enter an LPN");
      return;
    }
    if (lpnValue.toUpperCase() === "W") {
      toast.error("Please re-scan LPN or enter it manually");
      setLpnValue("");
      return;
    }

    try {
      setIsLoading(true);
      const getItemResponse = (
        await axios.get(`${HOST_URL}/warehouse/item?lpn=${lpnValue}`)
      ).data;

      setIsLoading(false);
      setLpnValue("");
      const item = getItemResponse.data;

      if (!item?.lpn) {
        toast.error(`No item found for LPN: ${lpnValue}`);
        setLpnValue("");
        return;
      }

      setCurrentItem(item);

      const newScannedLpns = [...scannedLpns, item];
      setScannedLpns(newScannedLpns);
    } catch (error) {
      setIsLoading(false);
      setLpnValue("");
      setCurrentItem(null);
      const status = error.response.status;
      if (status === 404) {
        toast.error("LPN not found. Please try again.");
      } else {
        toast.error("Something went wrong. Please try again.");
      }
    }
  };

  const body = (
    <>
      <FormControl
        required
        autoFocus
        type={"text"}
        value={lpnValue}
        placeholder={"Enter an LPN"}
        onChange={(e) => {
          e.preventDefault();
          setLpnValue(e.target.value);
        }}
        onKeyUp={(e) => {
          if (e.key === "Enter") {
            getShipmentItem();
          }
        }}
        size={"lg"}
        className="mb-3 w-75 m-auto text-center"
      />

      <Button
        size={"md"}
        variant={"primary"}
        onClick={getShipmentItem}
        disabled={isLoading}
      >
        {!isLoading ? "Submit" : "Loading..."}
      </Button>
    </>
  );
  return (
    <UICard
      className="text-center w-75 m-auto my-3"
      header={header}
      body={body}
    />
  );
}
