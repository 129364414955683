import React, { useEffect, useState } from "react";

import Select from "react-select";

import vendorService from "../../services/vendorService";

function VendorSelect({ vendorId, setFieldValue, allowNoVendor = true }) {
  const [vendorOptions, setVendorOptions] = useState();
  const [initialOption, setInitialOption] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    const getVendors = async () => {
      try {
        const vendors = (await vendorService.getVendors()).vendors;

        let vendorOptions;

        if (allowNoVendor) {
          vendorOptions = [{ value: "", label: "No Vendor" }].concat(
            vendors.map((vendor) => {
              return {
                value: vendor.vendorId,
                label: vendor.name,
              };
            })
          );
        } else {
          vendorOptions = vendors.map((vendor) => {
            return {
              value: vendor.vendorId,
              label: vendor.name,
            };
          });
        }

        const initialOption = vendorOptions.find(
          (option) => option.value === vendorId
        );

        setVendorOptions(vendorOptions);
        setInitialOption(initialOption);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };

    getVendors();
  }, []);

  useEffect(() => {
    if (vendorOptions) {
      const initialOption = vendorOptions.find(
        (option) => option.value === vendorId
      );
      setInitialOption(initialOption);
    }
  }, [vendorId]);

  return (
    <Select
      className="react-select-container"
      classNamePrefix="react-select"
      name="vendorSelect"
      placeholder="Select a Vendor..."
      onChange={(selectedOption) => {
        setFieldValue("vendorId", selectedOption.value);
      }}
      value={initialOption}
      options={vendorOptions}
      isSearchable
      isLoading={isLoading}
    />
  );
}

export default VendorSelect;
