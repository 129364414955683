import * as axios from "axios";
import { useEffect, useState } from "react";
import { Button, Container, Navbar } from "react-bootstrap";
import { PlusSquare } from "react-feather";

import UIDefaultPage from "../../components/ui/UIDefaultPage";
import AccountList from "./AccountList";

import marketplaces from "./marketplaces";

const HOST_URL = process.env.REACT_APP_HOST_URL;

const LinkedAccountsPage = () => {
  const [linkedAccounts, setLinkedAccounts] = useState();

  const user = JSON.parse(localStorage.getItem("user"));
  const userId = user?.userId;
  const vendor = JSON.parse(localStorage.getItem("vendor"));
  const vendorId = vendor?.vendorId || 0; //ADMIN

  const getLinkedAccounts = async () => {
    const data = (await axios.get(`${HOST_URL}/linkedAccounts/${userId}`)).data
      .linkedAccounts;
    setLinkedAccounts(data);
  };

  useEffect(() => {
    userId && getLinkedAccounts();
  }, []);

  const children = marketplaces.map((marketplace) => {
    marketplace.authLink =
      marketplace.marketplaceCode === 0 //amazon
        ? (marketplace.authLink += `&state=${vendorId}`)
        : marketplace.authLink;
    return (
      <div key={marketplace.marketplaceCode}>
        <Navbar expand="false" className="mb-3 py-0">
          <Container fluid>
            <Navbar.Brand>
              <span className="me-3">{marketplace.logo}</span>
              {marketplace.name}
            </Navbar.Brand>
            {marketplace.name === "Amazon" && (
              <div className="card-actions float-end">
                <Button
                  variant="info"
                  onClick={() => (window.location.href = marketplace.authLink)}
                >
                  <span className="mx-2">Add Store</span>
                  <PlusSquare />
                </Button>
              </div>
            )}
          </Container>
        </Navbar>
        <Container fluid className="mb-5">
          {marketplace.name === "Amazon" ? (
            linkedAccounts && (
              <AccountList
                logo={marketplace.logo}
                accounts={linkedAccounts}
                authLink={marketplace.authLink}
              />
            )
          ) : (
            <h5>Coming Soon...</h5>
          )}
        </Container>
      </div>
    );
  });

  return <UIDefaultPage title="Linked Accounts" children={children} />;
};

export default LinkedAccountsPage;
