import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { Trash2, X, Check } from "react-feather";

function NewStoreNameInput({
  storeNameString,
  setStoreNameString,
  storeNames,
  setStoreNames,
}) {
  const [value, setValue] = useState("");

  const handleAddStoreName = () => {
    if (value) {
      setStoreNameString(storeNameString ? value + "," + value : value);
      setStoreNames([...storeNames, value]);
      setValue("");
    }
  };

  return (
    <>
      <Form.Control
        className="mb-2"
        type="text"
        name="note"
        id="note"
        placeholder="Store Name"
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
      <Button variant="info" onClick={handleAddStoreName} disabled={!value}>
        Add Additional Store
      </Button>
    </>
  );
}

function AmazonStoreNamesList({ storeNames, setStoreNames }) {
  const deleteStore = (name) => {
    setStoreNames(storeNames.filter((storeName) => storeName !== name));
  };
  return storeNames.map((name, index) => (
    <div key={index} className="mx-3 mb-3 d-flex justify-content-between">
      {name}
      <Button variant="danger" size="sm" onClick={() => deleteStore(name)}>
        <Trash2 size={14} />
      </Button>
    </div>
  ));
}

function AmazonStoreNames({ storeNameString, setStoreNameString }) {
  const [storeNames, setStoreNames] = useState([]);

  return (
    <>
      {storeNames.length > 0 ? (
        <AmazonStoreNamesList
          storeNames={storeNames}
          setStoreNames={setStoreNames}
        />
      ) : (
        ""
      )}
      <NewStoreNameInput
        storeNameString={storeNameString}
        setStoreNameString={setStoreNameString}
        storeNames={storeNames}
        setStoreNames={setStoreNames}
      />
    </>
  );
}

export default AmazonStoreNames;
