import React, { useMemo, useState } from "react";

import { Button, Card, Col, Modal, Row } from "react-bootstrap";
import { Check, X } from "react-feather";
import toast from "react-hot-toast";

import AccountTable from "./AccountTable";
import UserAccountCard from "./UserAccountCard";
import UserAccountForm from "./UserAccountForm";
import ResetPasswordForm from "../../components/auth/ResetPasswordForm";

import userService from "../../services/userService";

const UserAccounts = ({ users, setUsers }) => {
  const [selectedUser, setSelectedUser] = useState(null);
  const [modal, setModal] = useState({ show: false, type: null });

  const accountRowClickHandler = (row) => {
    setSelectedUser(row);
  };

  const deleteUserHandler = async () => {
    const submittingToast = toast.loading("Deleting user...");

    const response = await userService
      .deleteUser(selectedUser.userId)
      .catch((error) => {
        console.error(error);
        toast.dismiss(submittingToast);
        toast.error(
          error?.response?.data?.message ||
            error?.message ||
            "Something went wrong."
        );
        setModal({ show: false, type: null });
      });

    if (response.success) {
      const users = await userService.getUsers().catch((error) => {
        console.error(error);
      });

      setUsers(users);
    }

    toast.dismiss(submittingToast);
    toast.success("User successfully deleted!");
    setModal({ show: false, type: null });
    setSelectedUser(null);
  };

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Username",
        accessor: "username",
      },
      { Header: "Email Address", accessor: "email" },
      {
        Header: "Administrator",
        accessor: (user) => {
          if (user.isAdmin) {
            return <Check color="#4BBF73" size={24} />;
          } else {
            return <X color="#d9534f" size={24} />;
          }
        },
        Cell: (row) => <div className="text-center">{row.value}</div>,
        sortType: (row) => (row.original.isAdmin ? -1 : 1),
      },
      {
        Header: "Vendor Name",
        accessor: "vendorName",
      },
    ],
    []
  );

  return (
    <>
      <Modal
        size="sm"
        show={modal.show}
        onHide={() => {
          setModal({ show: false, type: null });
        }}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-center w-100">
            {modal.type === "create"
              ? "Create User"
              : modal.type === "delete"
              ? "Delete User"
              : "Reset Password"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modal.type === "create" ? (
            <UserAccountForm isEdit={false} setModal={setModal} />
          ) : modal.type === "delete" ? (
            <>
              <div className="mb-3">
                Are you sure you want to delete{" "}
                <strong>{selectedUser.name}</strong> ?
                <div>
                  <small>This action cannot be undone.</small>
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <Button variant="danger" onClick={deleteUserHandler}>
                  Delete User
                </Button>
                <Button
                  variant="secondary"
                  onClick={() => {
                    setModal({ show: false, type: null });
                  }}
                >
                  Cancel
                </Button>
              </div>
            </>
          ) : modal.type === "resetPassword" ? (
            <ResetPasswordForm email={selectedUser.email} isAdminReset={true} />
          ) : null}
        </Modal.Body>
      </Modal>

      <Row>
        <Col xl={8}>
          <Card>
            <Card.Body>
              <AccountTable
                columns={columns}
                data={users}
                onClickFn={accountRowClickHandler}
                clickableRows={true}
                actionButton={
                  <Button
                    variant="primary"
                    onClick={() => {
                      setModal({ show: true, type: "create" });
                    }}
                    className="mb-2"
                  >
                    Create User
                  </Button>
                }
              />
            </Card.Body>
          </Card>
        </Col>
        <Col xl={4}>
          <UserAccountCard
            selectedUser={selectedUser}
            setSelectedUser={setSelectedUser}
            setModal={setModal}
            isEdit={true}
          />
        </Col>
      </Row>
    </>
  );
};

export default UserAccounts;
