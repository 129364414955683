import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Container, Row, Col, Card } from "react-bootstrap";
import toast from "react-hot-toast";

import AdminFilters from "../../../components/admin/AdminFilters";

import Header from "./Header";
import PieChart from "./PieChart";
import Statistics from "./Statistics";

import Loader from "../../../components/Loader";

import analyticsService from "../../../services/analytics.service";

const AnalyticsPage = () => {
  const [loading, setLoading] = useState(true);
  const [alias, setAlias] = useState();
  const [startDate, setStartDate] = useState(
    new Date(new Date().getFullYear(), 0, 1)
  );
  const [processingMetrics, setProcessingMetrics] = useState();
  const user = JSON.parse(localStorage.getItem("user"));
  let isAdmin = user?.isAdmin;
  const vendorId = JSON.parse(localStorage.getItem("vendor"))?.vendorId;

  const needAlias = isAdmin && !alias;

  const fetchData = async () => {
    setLoading(true);
    if (vendorId || alias?.value) {
      const result = await analyticsService.getProcessingMetrics(
        vendorId ? vendorId : alias?.value,
        startDate.toISOString()
      );

      if (result.success) {
        setProcessingMetrics(result.processingMetrics);
        setLoading(false);
      } else {
        toast.error(result.error.message);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (!needAlias) {
      fetchData();
    }
  }, [alias, startDate]);

  // admins only - clear fetched data so the loader will show when new vendor is selected:
  const clearDataHandler = () => {
    setProcessingMetrics();
  };

  return (
    <React.Fragment>
      <Helmet title="Home" />
      {isAdmin && (
        <>
          <span>Admin Control</span>
          <AdminFilters
            alias={alias}
            setAlias={setAlias}
            clearData={clearDataHandler}
          />
          {!processingMetrics && needAlias && (
            <Col>
              <Card>
                <Card.Body className="text-center">
                  <h3>Please choose a vendor to view data.</h3>
                </Card.Body>
              </Card>
            </Col>
          )}
        </>
      )}
      {!needAlias && (
        <Container fluid className="p-0">
          <Header
            loading={loading}
            setStartDate={setStartDate}
            refreshData={fetchData}
            user={user}
          />
          <Row>
            <Col sm={6} className="d-flex">
              <Statistics
                startDate={startDate}
                processingMetrics={processingMetrics}
                loading={loading}
              />
            </Col>
            {processingMetrics?.maxReturnedASIN && (
              <Col sm={6} className="d-flex">
                <PieChart processingMetrics={processingMetrics} />
              </Col>
            )}
          </Row>
          {/* {processingMetrics && !loading ? (
          <>
            <Row>
              <Col sm={6} className="d-flex">
                <Statistics
                  processingMetrics={processingMetrics}
                  loading={loading}
                />
              </Col>
              {processingMetrics.maxReturnedASIN && (
                <Col sm={6} className="d-flex">
                  <PieChart processingMetrics={processingMetrics} />
                </Col>
              )}
            </Row>
          </>
        ) : (
          loading && (
            <Card>
              <Card.Body>
                <Loader />
              </Card.Body>
            </Card>
          )
        )} */}
        </Container>
      )}
    </React.Fragment>
  );
};

export default AnalyticsPage;
