import React from "react";
import HelpForm from "./HelpForm";

import { Row, Col } from "react-bootstrap";

function HelpPage() {
  return (
    <Row>
      <Col xl="2" />
      <Col xl="8">
        <HelpForm />
      </Col>
      <Col xl="2" />
    </Row>
  );
}

export default HelpPage;
