import React from "react";
import { Button } from "react-bootstrap";

function OrderIdLink({ orderId, alias }) {
  return (
    <Button
      variant="link"
      className="link-info"
      title={"Click to view removal order info"}
      onClick={() =>
        (window.location = `/removalOrders/?orderId=${orderId}${
          alias ? `&aliasType=${alias.type}&aliasValue=${alias.value}` : ""
        }`)
      }
    >
      {orderId}
    </Button>
  );
}

export default OrderIdLink;
