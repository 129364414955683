import React, { useState, useEffect } from "react";
import axios from "axios";

import { Button, Card, Col } from "react-bootstrap";

import UITablePage from "../../components/ui/UITablePage";
import Table from "../../components/table/Table";
import AdminFilters from "../../components/admin/AdminFilters";
import Loader from "../../components/Loader";

import baseColumns from "./tableColumns";
import payoutDetailsColumns from "./payoutDetailsColumns";

const HOST_URL = process.env.REACT_APP_HOST_URL;

const PayoutsPage = () => {
  const [payouts, setPayouts] = useState();
  const [alias, setAlias] = useState();
  const [selectedPayout, setSelectedPayout] = useState();
  const [selectedPayoutDetails, setSelectedPayoutDetails] = useState();
  const { userId, isAdmin } = JSON.parse(localStorage.getItem("user"));
  //get vendorId from local storage
  const vendorId = JSON.parse(localStorage.getItem("vendor"))?.vendorId;

  const needAlias = isAdmin && !alias;

  const fetchData = async () => {
    const data = (
      await axios.get(
        `${HOST_URL}/getData/${userId}/payouts?vendorId=${vendorId}${
          alias ? `&aliasType=${alias.type}&aliasValue=${alias.value}` : ""
        }`
      )
    ).data.data;

    console.log(data);

    setPayouts(data);
  };

  useEffect(() => {
    if (!needAlias) {
      fetchData();
    }
  }, [userId, alias]);

  let columns = baseColumns;

  const clearDataHandler = () => {
    setPayouts(null);
  };

  const handleRowClick = async (row) => {
    console.log(row);
    setSelectedPayout(row);

    const vendorIdParam = !alias ? vendorId : alias.value;

    console.log(alias);

    const payoutResult = await axios.get(
      `${HOST_URL}/payouts/getPayoutDetail/${vendorIdParam}/${encodeURIComponent(
        row.invoiceMonth
      )}`
    );

    setSelectedPayoutDetails(payoutResult.data.payoutDetailsResponse);
  };

  const handleGoBack = () => {
    setSelectedPayout(null);
    setSelectedPayoutDetails(null);
  };

  const PayoutsTable = () => {
    return (
      <>
        {isAdmin && (
          <AdminFilters
            alias={alias}
            setAlias={setAlias}
            clearData={clearDataHandler}
          />
        )}
        <Col>
          <Card>
            <Card.Body>
              {selectedPayout && selectedPayoutDetails ? (
                <>
                  <div className="d-flex flex-col justify-content-center align-items-center">
                    <h3 className="text-center m-2">
                      Payout Details for{" "}
                      {
                        new Date(selectedPayout.invoiceMonth)
                          .toISOString()
                          .split("T")[0]
                      }
                    </h3>
                    <Button
                      className="m-2"
                      variant="info"
                      onClick={handleGoBack}
                    >
                      Go Back to Payouts
                    </Button>
                  </div>
                  <Table
                    columns={payoutDetailsColumns}
                    data={selectedPayoutDetails}
                    pageName="PayoutDetails"
                  />
                </>
              ) : payouts ? (
                <Table
                  columns={columns}
                  data={payouts}
                  pageName="Payouts"
                  clickableRows={true}
                  onClickFn={handleRowClick}
                />
              ) : needAlias ? (
                <h3 className="text-center">
                  Please choose a vendor to view data
                </h3>
              ) : (
                <Loader />
              )}
            </Card.Body>
          </Card>
        </Col>
      </>
    );
  };

  const childTable = <PayoutsTable />;
  return <>{<UITablePage title="Payouts" children={childTable} />}</>;
};

export default PayoutsPage;
