/* This component is used for manual pagination on the FE when using react-table with large datasets that 
are paginated on the BE */

import { useState, useEffect } from "react";
import { Pagination, Row, Col, Form } from "react-bootstrap";

const ManualPagination = ({
  pageData,
  setPageData,
  currentPage,
  setCurrentPage,
}) => {
  // Creating an array with length equal to no.of pages
  const pagesArr = [...Array(pageData.totalPages).keys()];

  // Navigation arrows enable/disable state
  const [canGoBack, setCanGoBack] = useState(false);
  const [canGoNext, setCanGoNext] = useState(true);

  // Onclick handlers for the pagination arrows:
  const onNextPage = () => {
    setCurrentPage(currentPage + 1);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  };
  const onPreviousPage = () => {
    setCurrentPage(currentPage - 1);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  };
  const onPageSelect = (pageNo) => {
    setCurrentPage(pageNo);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  };

  // Disable previous and next buttons in the first and last page
  useEffect(() => {
    if (pageData.totalPages === currentPage) {
      setCanGoNext(false);
    } else {
      setCanGoNext(true);
    }
    if (currentPage === 1) {
      setCanGoBack(false);
    } else {
      setCanGoBack(true);
    }
  }, [pageData.totalPages, currentPage]);

  return (
    <>
      <Row className="mt-4">
        <Col md="9">
          <span className="mx-2">
            Page{" "}
            <strong>
              {currentPage} of {pageData.totalPages}
            </strong>
          </span>
          <span className="ms-3 me-2">Show:</span>
          <Form.Select
            className="d-inline-block w-auto"
            value={pageData.pageSize}
            onChange={(e) => {
              setPageData((prevState) => ({
                ...prevState,
                pageSize: Number(e.target.value),
              }));
            }}
          >
            {[10, 20, 50, 100, 200].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </Form.Select>

          <span className="ms-3 me-2">Go to page:</span>
          <Form.Control
            className="d-inline-block"
            type="number"
            min={1}
            max={pageData.totalPages}
            value={currentPage}
            onChange={(e) => {
              const page =
                !e.target.value || Number(e.target.value) <= 0
                  ? 1
                  : Number(e.target.value) > pageData.totalPages
                  ? pageData.totalPages
                  : Number(e.target.value);
              onPageSelect(page);
            }}
            style={{ width: "75px" }}
            autoComplete="off"
          />
        </Col>
        <Col md="3">
          <Pagination className="float-end">
            <Pagination.First
              onClick={() => onPageSelect(1)}
              disabled={!canGoBack}
            />
            <Pagination.Prev
              onClick={() => onPreviousPage()}
              disabled={!canGoBack}
            />
            <Pagination.Item
              onClick={() => onPageSelect(1)}
              active={1 === currentPage}
            >
              {1}
            </Pagination.Item>
            {pagesArr.map((page, index) => {
              if (
                (page + 1 === currentPage - 3 && page + 1 !== 1) ||
                (page + 1 === currentPage + 3 &&
                  page + 1 !== pageData.totalPages)
              ) {
                return <Pagination.Ellipsis key={index} disabled />;
              }
              if (
                page + 1 >= currentPage - 2 &&
                page + 1 <= currentPage + 2 &&
                page + 1 !== 1 &&
                page + 1 !== pageData.totalPages
              ) {
                return (
                  <Pagination.Item
                    key={index}
                    onClick={() => onPageSelect(page + 1)}
                    active={page + 1 === currentPage}
                  >
                    {page + 1}
                  </Pagination.Item>
                );
              }
              return null;
            })}
            {pageData.totalPages !== 1 && (
              <Pagination.Item
                onClick={() => onPageSelect(pageData.totalPages)}
                active={pageData.totalPages === currentPage}
              >
                {pageData.totalPages}
              </Pagination.Item>
            )}
            <Pagination.Next
              onClick={() => onNextPage()}
              disabled={!canGoNext}
            />
            <Pagination.Last
              onClick={() => onPageSelect(pageData.totalPages)}
              disabled={!canGoNext}
            />
          </Pagination>
        </Col>
      </Row>
    </>
  );
};

export default ManualPagination;

// /* This component is used for manual pagination on the FE when using react-table with large datasets that
// are paginated on the BE */

// import { useState, useEffect } from "react";
// import { Pagination, Row, Col, Form } from "react-bootstrap";

// const DEFAULT_ROWS_PER_PAGE = 100;

// const ManualPagination = ({
//   updatePaginatedData,
//   currentPage,
//   setCurrentPage,
//   totalRows,
//   // rowsPerPage = 100,
//   filters,
//   // setRowsPerPage,
//   pageSizes = [10, 50, 100, 150],
// }) => {
//   const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);
//   // to prevent infinite loop of fetching the data:
//   const [pageChanged, setPageChanged] = useState(false);
//   console.log("pageChanged: ", pageChanged);

//   // Calculating max number of pages
//   const noOfPages = Math.ceil(totalRows / rowsPerPage);

//   // const [currentPage, setCurrentPage] = useState(1);
//   // console.log("currentPage: ", currentPage); // keeps getting set back to 1, bec. component rerenders on each data fetch

//   // Navigation arrows enable/disable state
//   const [canGoBack, setCanGoBack] = useState(false);
//   const [canGoNext, setCanGoNext] = useState(true);

//   // const skip = (currentPage - 1) * rowsPerPage;

//   // Onclick handlers for the pagination arrows:
//   const nextPage = () => {
//     setCurrentPage(currentPage + 1);
//     setPageChanged(true);
//     // updatePaginatedData(rowsPerPage, filters); // currentPage only updates to the next page after this is called - so wrong page of data is fetched
//     window.scrollTo(0, 0);
//   };
//   const previousPage = () => {
//     setCurrentPage(currentPage - 1);
//     setPageChanged(true);
//     // updatePaginatedData(rowsPerPage, filters);
//     window.scrollTo(0, 0);
//   };
//   const goToPage = (pageNo) => {
//     setCurrentPage(pageNo);
//     setPageChanged(true);
//     // updatePaginatedData(rowsPerPage, filters);
//     window.scrollTo(0, 0);
//   };

//   useEffect(() => {
//     console.log("ManualPagination: in useEffect: currentPage: ", currentPage);
//     if (noOfPages === currentPage) {
//       setCanGoNext(false);
//     } else {
//       setCanGoNext(true);
//     }
//     if (currentPage === 1) {
//       setCanGoBack(false);
//     } else {
//       setCanGoBack(true);
//     }

//     // this is not working when currentPage is passed down from AmazonOrdersPage (pageChanged is always false)
//     // should not fetch data on initial render (to prevent infinite loop)
//     if (pageChanged) {
//       // get next page of data
//       // const skip = (currentPage - 1) * rowsPerPage;
//       updatePaginatedData(rowsPerPage, filters);
//     }

//     // get next page of data
//     // const skip = (currentPage - 1) * rowsPerPage;
//     // updatePaginatedData(skip, rowsPerPage, filters); // causing infinite loop of data getting fetched
//   }, []); // currentPage

//   return (
//     <>
//       {noOfPages > 1 ? (
//         <Row>
//           <Col md="9">
//             <span className="mx-2">
//               Page{" "}
//               <strong>
//                 {currentPage} of {noOfPages}
//               </strong>
//             </span>
//             <span className="ms-3 me-2">Show:</span>
//             <Form.Select
//               className="d-inline-block w-auto"
//               value={rowsPerPage}
//               onChange={(e) => {
//                 setRowsPerPage(Number(e.target.value));
//                 setCurrentPage(1);
//                 // setPageChanged(true);
//                 // updatePaginatedData(rowsPerPage, filters);
//               }}
//             >
//               {pageSizes.map((pageSize) => (
//                 <option key={pageSize} value={pageSize}>
//                   {pageSize}
//                 </option>
//               ))}
//             </Form.Select>
//             <span className="ms-3 me-2">Go to page:</span>
//             <Form.Control
//               className="d-inline-block"
//               type="number"
//               defaultValue={currentPage}
//               onChange={(e) => {
//                 const page =
//                   e.target.value && Number(e.target.value) > 0
//                     ? Number(e.target.value)
//                     : 1;
//                 goToPage(page);
//               }}
//               style={{ width: "75px" }}
//               autoComplete="off"
//             />
//           </Col>
//           <Col md="3">
//             <Pagination className="float-end">
//               <Pagination.First
//                 onClick={() => goToPage(1)}
//                 disabled={!canGoBack}
//               />
//               <Pagination.Prev
//                 onClick={() => previousPage()}
//                 disabled={!canGoBack}
//               />
//               <Pagination.Next
//                 onClick={() => nextPage()}
//                 disabled={!canGoNext}
//               />
//               <Pagination.Last
//                 onClick={() => goToPage(noOfPages)}
//                 disabled={!canGoNext}
//               />
//             </Pagination>
//           </Col>
//         </Row>
//       ) : null}
//     </>
//   );
// };

// export default ManualPagination;
