import AmazonASINLink from "../../components/AmazonASINLink";
import {
  DateRangeFilter,
  dateBetweenFilterFn,
} from "../../components/table/DateRangeFilter";
import imageNotFound from "../../assets/img/imageNotFound.png";

const statusColors = {
  Completed: "success",
  Pending: "warning",
  Cancelled: "danger",
};

const orderTableColumns = [
  {
    Header: "Order ID",
    accessor: "orderId",
  },
  {
    Header: "Request Date",
    accessor: ({ requestDate }) => new Date(requestDate),
    id: "requestDate",
    sortType: "datetime",
    Cell: ({ value }) => {
      return <span>{new Date(value).toLocaleDateString()}</span>;
    },
    Filter: DateRangeFilter,
    filter: dateBetweenFilterFn,
  },
  {
    Header: "Last Updated Date",
    id: "lastUpdatedDate",
    sortType: "datetime",
    accessor: ({ lastUpdatedDate }) => new Date(lastUpdatedDate),
    Cell: ({ value }) => {
      return <span>{new Date(value).toLocaleDateString()}</span>;
    },
    Filter: DateRangeFilter,
    filter: dateBetweenFilterFn,
  },
  {
    Header: "Order Type",
    accessor: "orderType",
  },
  {
    Header: "Order Status",
    accessor: "orderStatus",
    Cell: ({ row }) => {
      const { orderStatus } = row.original;
      return (
        <span className={`text-${statusColors[orderStatus]}`}>
          {orderStatus}
        </span>
      );
    },
  },
  {
    Header: "Requested",
    accessor: "totalRequestedQuantity",
  },
  {
    Header: "Cancelled",
    accessor: "totalCancelledQuantity",
  },
  {
    Header: "Disposed",
    accessor: "totalDisposedQuantity",
  },
  {
    Header: "Shipped",
    accessor: "totalShippedQuantity",
  },
  {
    Header: "In Process",
    accessor: "totalInProcessQuantity",
  },
  {
    Header: "SKUs",
    accessor: (row) => {
      return row.items.map((item) => item.sku);
    },
  },
];

const orderItemTableColumns = [
  { Header: "Removal Order ID", accessor: "orderId" },
  {
    id: "image",
    Header: "Product Image",
    accessor: (row) => (
      <a
        href={`https://www.amazon.com/dp/${row.asin}?th=1&psc=1`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          className="img-thumbnail"
          style={{ maxHeight: "75px" }}
          src={`https://ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=${row.asin}&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=SL250`}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = imageNotFound;
          }}
          alt="Item"
        />
      </a>
    ),
  },
  {
    Header: "ASIN",
    accessor: "asin",
    Cell: ({ value }) => <AmazonASINLink ASIN={value} />,
  },
  {
    Header: "SKU",
    accessor: "sku",
  },
  {
    Header: "Disposition",
    accessor: "disposition",
  },
  {
    Header: "Requested",
    accessor: "requestedQuantity",
  },
  {
    Header: "Cancelled",
    accessor: "cancelledQuantity",
  },
  {
    Header: "Disposed",
    accessor: "disposedQuantity",
  },
  {
    Header: "Shipped",
    accessor: "shippedQuantity",
  },
  {
    Header: "In Process",
    accessor: "inProcessQuantity",
  },
];

export { orderTableColumns, orderItemTableColumns };
