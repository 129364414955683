import React, { useState } from "react";
import axios from "axios";
import { Row, Col, Card, Button, Alert } from "react-bootstrap";

const HOST_URL = process.env.REACT_APP_HOST_URL;

function PendingAccountCard({ account, getPendingVendorAccounts, isPending }) {
  const [isProcessing, setIsProcessing] = useState(false);
  const [successMessage, setSuccessMessage] = useState();
  const [errorMessage, setErrorMessage] = useState();

  let pendingAccountId,
    vendorName,
    firstName,
    lastName,
    email,
    phone,
    address,
    zip,
    routingNumber,
    accountNumber,
    city,
    state,
    country,
    hearAboutUs,
    amazonPreference,
    note,
    createdAt,
    status;

  if (account) {
    ({
      pendingAccountId,
      vendorName,
      firstName,
      lastName,
      email,
      phone,
      address,
      zip,
      createdAt,
      status,
      routingNumber,
      accountNumber,
      city,
      state,
      country,
      hearAboutUs,
      amazonPreference,
      note,
    } = account);
    createdAt = new Date(createdAt).toLocaleDateString();
  }

  const handleDecision = async (pendingAccountId, decision) => {
    setIsProcessing(true);
    const url = `${HOST_URL}/auth/${
      decision === "APPROVED" ? "approvePendingAccount" : "denyPendingAccount"
    }`;
    try {
      const response = await axios.post(url, { pendingAccountId });
      showResponse({ success: true, message: response.data.message });
    } catch (error) {
      console.log(error);
      showResponse({
        success: false,
        message: `There was a problem ${
          decision === "APPROVED" ? "approving" : "denying"
        } this account.`,
      });
    }
  };

  const showResponse = ({ success, message }) => {
    setIsProcessing(false);
    if (success) {
      setSuccessMessage(message);
      setErrorMessage("");
    } else {
      setErrorMessage(message);
      setSuccessMessage("");
    }

    setTimeout(() => {
      getPendingVendorAccounts();
    }, 3000);
  };

  return (
    <Card>
      {account ? (
        <>
          <Card.Header>
            <Card.Title tag="h5" className="text-center mb-0">
              Pending Account Information:
            </Card.Title>
          </Card.Header>
          <Card.Body>
            <h3 className="text-center">{vendorName}</h3>
            <p>
              <div>
                Contact Name: {firstName} {} {lastName}
              </div>
              <div>Email Address: {email}</div>
            </p>
            <p>
              <div>Phone Number: {phone}</div>
              <div>Street Address: {address}</div>
              <div>City: {city}</div>
              <div>State: {state}</div>
              <div>ZIP Code: {zip}</div>
              <div>Country: {country}</div>
            </p>
            <p>
              <div>Bank Routing Number: {routingNumber}</div>
              <div>Bank Account Number: {accountNumber}</div>
            </p>
            <div>How did you hear about us: {hearAboutUs}</div>
            <div>Preferences: {amazonPreference}</div>
            <div>Amazon Store Name/s: {note}</div>

            <br></br>
            {isPending ? <div>Pending since: {createdAt}</div> : null}

            {successMessage ? (
              <Alert variant="success" className="text-center p-3">
                <span>{successMessage}</span>
              </Alert>
            ) : null}
            {errorMessage ? (
              <Alert variant="danger" className="text-center p-3">
                <span>{errorMessage}</span>
              </Alert>
            ) : null}
            {isPending ? (
              <div className="d-flex justify-content-between mt-3">
                {isProcessing ? (
                  <Button variant="success" disabled>
                    Processing...
                  </Button>
                ) : (
                  <Button
                    variant="success"
                    onClick={() => handleDecision(pendingAccountId, "APPROVED")}
                  >
                    Approve
                  </Button>
                )}
                <Button
                  variant="danger"
                  onClick={() => handleDecision(pendingAccountId, "DENIED")}
                >
                  Deny
                </Button>
              </div>
            ) : null}
          </Card.Body>
        </>
      ) : (
        <>
          <Card.Header>
            <Card.Title tag="h5" className="text-center mb-0">
              Pending Account Information:
            </Card.Title>
          </Card.Header>
          <Card.Body className="text-center">No Account Selected</Card.Body>
        </>
      )}
    </Card>
  );
}

export default PendingAccountCard;
