import React from "react";
import { Card } from "react-bootstrap";

function UICard({ header, body, footer, className }) {
  return (
    <Card className={className}>
      {header && <Card.Header>{header}</Card.Header>}
      {body && <Card.Body>{body}</Card.Body>}
      {footer && <Card.Footer>{footer}</Card.Footer>}
    </Card>
  );
}

export default UICard;
