import axios from "axios";
import React, { useEffect, useState } from "react";

import { Button, ButtonGroup, Card, Col, Row, Tab } from "react-bootstrap";
import Loader from "../../components/Loader";

import UIDefaultPage from "../../components/ui/UIDefaultPage";
import RemovalOrderItemsTable from "./RemovalOrderItemsTable";
import RemovalOrderTable from "./RemovalOrderTable";
import AdminFilters from "../../components/admin/AdminFilters";

const HOST_URL = process.env.REACT_APP_HOST_URL;

const RemovalOrderPage = () => {
  const [removalOrders, setRemovalOrders] = useState();
  const [removalOrderItems, setRemovalOrderItems] = useState();
  const [lastSyncDate, setLastSyncDate] = useState();
  // const [infoCardData, setInfoCardData] = useState();

  const [activeTab, setActiveTab] = useState("viewOrders");
  const [currentOrder, setCurrentOrder] = useState();
  const [filter, setFilter] = useState();
  const [alias, setAlias] = useState();

  const { userId, isAdmin } = JSON.parse(localStorage.getItem("user"));

  const queryParams = new URLSearchParams(window.location.search);
  const aliasType = queryParams.get("aliasType");
  const aliasValue = queryParams.get("aliasValue");

  const needAlias = isAdmin && !alias;

  const fetchData = async () => {
    let removalOrders, data, syncDate;
    if (activeTab === "viewOrders" && !removalOrders) {
      // retrieves structured data -  each order with its order items

      ({ removalOrders: data, syncDate } = (
        await axios.get(
          `${HOST_URL}/getData/${userId}/removalOrders?viewOrders=true${
            alias ? `&aliasType=${alias.type}&aliasValue=${alias.value}` : ""
          }`
        )
      ).data.data);
      setRemovalOrders(data);
    } else if (activeTab === "viewItems" && !removalOrderItems) {
      // viewItems: // just retrieves all the items for all orders (not structured)
      ({ removalOrders: data, syncDate } = (
        await axios.get(
          `${HOST_URL}/getData/${userId}/removalOrders${
            alias ? `?aliasType=${alias.type}&aliasValue=${alias.value}` : ""
          }`
        )
      ).data.data);
      setRemovalOrderItems(data);
    }
    setLastSyncDate(syncDate);
  };
  useEffect(() => {
    if (!needAlias) {
      fetchData();
    }
  }, [activeTab, alias]);

  useEffect(() => {
    if (queryParams.get("orderId")) {
      setActiveTab("viewItems");
      setFilter({
        id: "orderId",
        value: queryParams.get("orderId"),
      });
    }

    if (aliasType && aliasValue) {
      setAlias({ type: aliasType, value: Number(aliasValue) });
    }
  }, []);

  useEffect(() => {
    if (currentOrder) {
      setActiveTab("viewItems");
      setFilter({
        id: "orderId",
        value: currentOrder.orderId,
      });
    }
  }, [currentOrder]);

  // admins only - clear all fetched data so the loader will show when new vendor is selected:
  const clearDataHandler = () => {
    setRemovalOrders(null);
    setRemovalOrderItems(null);
    setLastSyncDate();
  };

  const MainTable = () => {
    return (
      <>
        {isAdmin && (
          <AdminFilters
            alias={alias}
            setAlias={setAlias}
            clearData={clearDataHandler}
          />
        )}
        <Card>
          {needAlias ? (
            <Card.Body>
              <h3 className="text-center">
                Please choose a vendor to view data
              </h3>
            </Card.Body>
          ) : (
            <Tab.Container id="view-tabs" activeKey={activeTab}>
              <div className="d-flex align-items-center justify-content-center">
                <span className="pt-2 font-weight-bold">
                  Last Synced:{" "}
                  {lastSyncDate &&
                    new Date(lastSyncDate)
                      .toLocaleString("en", {
                        timeStyle: "short",
                        dateStyle: "short",
                      })
                      .replace(",", "")}
                </span>
              </div>
              <Row className="justify-content-center mt-3 mb-2">
                <Col xs="auto">
                  <ButtonGroup className="mb-3">
                    <Button
                      variant="outline-primary"
                      onClick={() => {
                        setActiveTab("viewOrders");
                        setFilter();
                      }}
                      className={activeTab === "viewOrders" ? "active" : ""}
                    >
                      View Orders
                    </Button>
                    <Button
                      variant="outline-primary"
                      onClick={() => {
                        setActiveTab("viewItems");
                        setFilter();
                      }}
                      className={activeTab === "viewItems" ? "active" : ""}
                    >
                      View Items
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>
              <Tab.Content>
                <Tab.Pane eventKey="viewOrders">
                  <Card.Body>
                    {removalOrders ? (
                      <RemovalOrderTable
                        removalOrders={removalOrders}
                        setCurrentOrder={setCurrentOrder}
                        alias={alias}
                      />
                    ) : (
                      <Loader />
                    )}
                  </Card.Body>
                </Tab.Pane>
                <Tab.Pane eventKey="viewItems">
                  <Card.Body>
                    {removalOrderItems ? (
                      <RemovalOrderItemsTable
                        removalOrderItems={removalOrderItems}
                        filter={filter}
                        alias={alias}
                      />
                    ) : (
                      <Loader />
                    )}
                  </Card.Body>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          )}
        </Card>
      </>
    );
  };

  return (
    <UIDefaultPage
      title="Amazon FBA Removal Orders"
      children={
        <Col md={12}>
          <MainTable />
        </Col>
      }
    />
  );
};

export default RemovalOrderPage;
