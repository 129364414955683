import React from "react";
import axios from "axios";
import {
  Button,
  Col,
  Form,
  Pagination,
  Row,
  Table,
  Badge,
} from "react-bootstrap";
const HOST_URL = process.env.REACT_APP_HOST_URL;
function CronJobsTable({ cronJobs, setCurrentCronJob }) {
  console.log("hello");

  const getStatusBadge = (status) => {
    let bg;

    switch (status) {
      case "error":
        bg = "danger";
        break;
      case "completed":
        bg = "success";

        break;
      default:
        bg = "warning";
    }
    return (
      <>
        <td>
          <Badge pill bg={bg} className="mx-2">
            {status || "in progress"}
          </Badge>
        </td>
      </>
    );
  };

  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>Cron Job Type</th>
            <th>Date Time</th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {cronJobs.map((cronJob, i) => {
            return (
              <tr key={i}>
                <td>{cronJob.functionName}</td>
                <td>
                  {new Date(cronJob.startTime)
                    .toLocaleString()
                    .replace(",", "")}
                </td>

                {getStatusBadge(cronJob.outcome)}

                <td>
                  <Button
                    variant="outline-secondary"
                    onClick={() => {
                      setCurrentCronJob(cronJob);
                    }}
                  >
                    History
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
}

export default CronJobsTable;
