import React from "react";
import { Helmet } from "react-helmet-async";

import { Container } from "react-bootstrap";

function UIDefaultPage({ title = "Portal", children }) {
  return (
    <React.Fragment>
      <Helmet title={title} />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">{title}</h1>
        {children}
      </Container>
    </React.Fragment>
  );
}

export default UIDefaultPage;
