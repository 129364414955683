import {
  DateRangeFilter,
  dateBetweenFilterFn,
} from "../../components/table/DateRangeFilter";

const MAX_LENGTH = 50;

const salesActivityColumns = [
  {
    Header: "Channel",
    accessor: "channel",
  },
  {
    Header: "SKU",
    accessor: "sku",
  },
  {
    Header: "Product Name",
    accessor: "productName",
    Cell: ({ value }) => {
      const truncated = value.length > MAX_LENGTH;
      return (
        <span className={truncated ? "cursor-pointer" : ""} title={value}>
          {value.slice(0, MAX_LENGTH)}
          {truncated && "..."}
        </span>
      );
    },
  },
  {
    Header: "Quantity",
    accessor: "quantity",
  },
  {
    Header: "Purchase Date",
    sortType: "datetime",
    Cell: ({ value }) => {
      return <span>{new Date(value).toLocaleDateString()}</span>;
    },
    accessor: ({ purchaseDate }) => new Date(purchaseDate),
    id: "purchaseDate",
    Filter: DateRangeFilter,
    filter: dateBetweenFilterFn,
  },

  {
    Header: "Price",
    accessor: "itemPrice",
  },
  {
    Header: "Marketplace Fees",
    accessor: "marketplaceFees",
  },
  // {
  //   Header: "Commission Paid",
  //   accessor: "commissionPaid",
  // },
  {
    Header: "Commission",
    accessor: "commission",
  },
  {
    Header: "Expected Gross",
    accessor: "expectedGross",
  },
];

export default salesActivityColumns;
