import * as React from "react";
import { Navigate } from "react-router-dom";

import useAuth from "../../hooks/useAuth";

function AdminGuard({ children }) {
  const { isAdmin } = JSON.parse(localStorage.getItem("user"));
  if (!isAdmin) {
    return <Navigate to="/404" />;
  }

  return <React.Fragment>{children}</React.Fragment>;
}

export default AdminGuard;
