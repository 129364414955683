import React from "react";
import Table from "../../components/table/Table";
import ShippingInfoLink from "./ShippingInfoLink";

import { orderTableColumns } from "./tableColumns";
import { needStoreNameColumn } from "../../utils/helper";
import { Button } from "react-bootstrap";

function RemovalOrderTable({ removalOrders, setCurrentOrder, alias }) {
  //create the Cell function
  const orderIdClickFunction = ({ row }) => {
    return (
      <Button
        variant="link"
        className="link-info"
        title={"Click to view items"}
        onClick={() => setCurrentOrder(row.original)}
      >
        {row.original.orderId}
      </Button>
    );
  };
  //add the Cell function the the columns array
  orderTableColumns.find((col) => col.accessor === "orderId").Cell =
    orderIdClickFunction;

  const shippedClickFunction = ({ row }) => {
    const { totalShippedQuantity, orderId } = row.original;
    return totalShippedQuantity > 0 ? (
      <ShippingInfoLink
        value={totalShippedQuantity}
        orderId={orderId}
        alias={alias}
      />
    ) : (
      totalShippedQuantity
    );
  };

  orderTableColumns.find(
    (col) => col.accessor === "totalShippedQuantity"
  ).Cell = shippedClickFunction;

  let columns = orderTableColumns;
  if (needStoreNameColumn(removalOrders)) {
    columns = [{ Header: "Store", accessor: "storeName" }, ...columns];
  }
  return (
    <Table
      columns={columns}
      data={removalOrders}
      initialHiddenColumns={["SKUs"]}
      initialPageSize={50}
      pageName="RemovalOrders"
    />
  );
}

export default RemovalOrderTable;
