import React from "react";
import { Col, Card, Row } from "react-bootstrap";
import { Tag, Truck, Search, Box } from "react-feather";

import StatisticsPlaceholder from "./StatisticsPlaceholder";

const Statistics = ({ startDate, processingMetrics, loading }) => {
  const dateText = startDate.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  return (
    <div className="w-100">
      <Row>
        {loading || !processingMetrics ? (
          <>
            <Col xl={6} className="d-flex">
              <StatisticsPlaceholder />
            </Col>
            <Col xl={6} className="d-flex">
              <StatisticsPlaceholder />
            </Col>
            <Col xl={6} className="d-flex">
              <StatisticsPlaceholder />
            </Col>
          </>
        ) : (
          <>
            <Col xl={6} className="d-flex">
              <Card className="flex-fill">
                <Card.Body>
                  <Row>
                    <Col className="mt-0">
                      <Card.Title>Received</Card.Title>
                    </Col>

                    <Col xs="auto">
                      <div className="stat stat-sm">
                        <Truck className="align-middle" />
                      </div>
                    </Col>
                  </Row>
                  <span className="h1 d-inline-block mt-1 mb-4">
                    {processingMetrics?.totalReceivedItems} Items
                  </span>
                  <div className="mb-0">
                    <span className="text-muted">Since {dateText}</span>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col xl={6} className="d-flex">
              <Card className="flex-fill">
                <Card.Body>
                  <Row>
                    <Col className="mt-0">
                      <Card.Title>Inspected</Card.Title>
                    </Col>

                    <Col xs="auto">
                      <div className="stat stat-sm">
                        <Search className="align-middle" />
                      </div>
                    </Col>
                  </Row>
                  <span className="h1 d-inline-block mt-1 mb-4">
                    {processingMetrics?.totalInspectedItems} Items
                  </span>
                  <div className="mb-0">
                    <span className="text-muted">Since {dateText}</span>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            {/* <Col xl={6} className="d-flex">
              <Card className="flex-fill">
                <Card.Body>
                  <Row>
                    <Col className="mt-0">
                      <Card.Title>Sold</Card.Title>
                    </Col>

                    <Col xs="auto">
                      <div className="stat stat-sm">
                        <Tag className="align-middle" />
                      </div>
                    </Col>
                  </Row>
                  <span className="h1 d-inline-block mt-1 mb-4">
                    {processingMetrics?.totalSoldItems} Items
                  </span>
                  <div className="mb-0 mt-4">
                    <span className="text-muted">Since {dateText}</span>
                  </div>
                </Card.Body>
              </Card>
            </Col> */}
            {/* processingMetrics?.maxReturnedASIN && (
              <Col xl={6} className="d-flex">
                <Card className="flex-fill">
                  <Card.Body>
                    <Row>
                      <Col className="mt-0">
                        <Card.Title>Most Returned Item</Card.Title>
                      </Col>

                      <Col xs="auto">
                        <div className="stat stat-sm">
                          <Box className="align-middle" />
                        </div>
                      </Col>
                    </Row>
                    <span className="h1 d-inline-block mt-1 mb-4">
                      <a
                        href={`https://www.amazon.com/dp/${processingMetrics?.maxReturnedASIN.asin}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {processingMetrics?.maxReturnedASIN.asin}
                      </a>
                    </span>
                    <div className="mb-0">
                      <span className="h4 text-muted">
                        {processingMetrics?.maxReturnedASIN.occurrences} Returns
                      </span>
                    </div>
                    <div className="mb-0">
                      <span className="text-muted">Since {dateText}</span>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ) */}
          </>
        )}
      </Row>
    </div>
  );
};

export default Statistics;
