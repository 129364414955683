import React, { useState, useEffect } from "react";
import { Card, Button, Row, Col } from "react-bootstrap";
import { Copy, Edit } from "react-feather";

import PayoutDetailForm from "./PayoutDetailForm";

const PayoutDetails = () => {
  const {
    routingNumber: routingNumberFromLS,
    accountNumber: accountNumberFromLS,
    amazonPreference: amazonPreferenceFromLS,
  } = JSON.parse(localStorage.getItem("vendor"));
  const [editing, setEditing] = useState(false);
  const [routingNumber, setRoutingNumber] = useState(routingNumberFromLS);
  const [accountNumber, setAccountNumber] = useState(accountNumberFromLS);
  const [amazonPreference, setAmazonPreference] = useState(
    amazonPreferenceFromLS
  );
  const amazonPreferenceOptions = {
    noAmazonRestrictions: "Sell new as new",
    restrictAmazonNewListing: "Sell as used regardless of condition",
    restrictAmazonAllListings: "Do not sell on Amazon",
  };
  console.log(amazonPreference);
  return (
    <Card>
      <Card.Header>
        <Card.Title tag="h5" className="mb-0">
          Account Details
          <Button
            className="ms-3"
            variant="info"
            title="Edit Payout Details"
            onClick={() => setEditing(!editing)}
          >
            <Edit size={16} />
          </Button>
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col>
            <h5>
              Payout Details
              <br />
              <br />
            </h5>
          </Col>
          <Col>
            <h5>
              Preferences
              <br />
              <br />
            </h5>
          </Col>
        </Row>
        <Row>
          {!editing ? (
            <>
              <Col>
                <Card.Subtitle>Bank Routing Number</Card.Subtitle>
                <Card.Text>
                  {routingNumber ? routingNumber : "No routing number"}
                </Card.Text>
                <Card.Subtitle>Bank Account Number</Card.Subtitle>
                <Card.Text>
                  {accountNumber ? accountNumber : "No account number"}
                </Card.Text>
              </Col>
              <Col>
                <Card.Subtitle>Amazon Listing Restrictions</Card.Subtitle>
                <Card.Text>
                  {amazonPreference
                    ? amazonPreferenceOptions[amazonPreference]
                    : "No Amazon listing preference"}
                </Card.Text>
              </Col>
            </>
          ) : (
            <PayoutDetailForm
              routingNumber={routingNumber}
              accountNumber={accountNumber}
              amazonPreference={amazonPreference}
              setEditing={setEditing}
            />
          )}
        </Row>
      </Card.Body>
    </Card>
  );
};

export default PayoutDetails;
