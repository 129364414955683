import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { Alert, Button, Form } from "react-bootstrap";
import toast from "react-hot-toast";

import useAuth from "../../hooks/useAuth";

const DEFAULT_RESET_PASSWORD = "Change3e!";

function ResetPasswordForm({ token, isAdminReset = false, email }) {
  const { resetPassword, initiateResetPassword } = useAuth();

  const [resetPasswordToken, setResetPasswordToken] = useState(token);

  useEffect(() => {
    const getResetPasswordToken = async () => {
      const response = await initiateResetPassword({
        email,
        isAdminReset: true,
      });
      if (response.success) {
        setResetPasswordToken(response.resetPasswordToken);
      }
    };
    if (!resetPasswordToken && isAdminReset && email) {
      getResetPasswordToken();
    }
  }, [resetPasswordToken, isAdminReset, initiateResetPassword, email]);

  return resetPasswordToken ? (
    <Formik
      initialValues={{
        newPassword: isAdminReset ? DEFAULT_RESET_PASSWORD : "",
        confirmNewPassword: isAdminReset ? DEFAULT_RESET_PASSWORD : "",
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        newPassword: Yup.string().max(255).required("Required"),
        confirmNewPassword: Yup.string()
          .max(255)
          .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
      })}
      onSubmit={async (
        values,
        { setErrors, setStatus, setSubmitting, resetForm }
      ) => {
        try {
          await resetPassword({
            token: resetPasswordToken,
            password: values.newPassword,
            isAdminReset,
          });
          resetForm();
          toast.success("Password reset successful!");
          setTimeout(() => {
            window.location = isAdminReset ? "/admin/accounts" : "/";
          }, 1500);
        } catch (error) {
          const message = error.message || "Something went wrong";
          resetForm();
          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <Form onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert className="my-3" variant="danger">
              <div className="alert-message">{errors.submit}</div>
            </Alert>
          )}
          {isAdminReset && (
            <Alert className="my-3" variant="warning">
              <div className="alert-message">
                {`The password will be defaulted to ${'"Change3e!"'}, but can be
                changed below.`}
              </div>
            </Alert>
          )}
          <Form.Group className="mb-3">
            <Form.Label>New Password</Form.Label>
            <Form.Control
              type={isAdminReset ? "text" : "password"}
              name="newPassword"
              value={values.newPassword}
              isInvalid={Boolean(touched.newPassword && errors.newPassword)}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {!!touched.newPassword && (
              <Form.Control.Feedback type="invalid">
                {errors.newPassword}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Confirm New Password</Form.Label>
            <Form.Control
              type={isAdminReset ? "text" : "password"}
              name="confirmNewPassword"
              value={values.confirmNewPassword}
              isInvalid={Boolean(
                touched.confirmNewPassword && errors.confirmNewPassword
              )}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {!!touched.confirmNewPassword && (
              <Form.Control.Feedback type="invalid">
                {errors.confirmNewPassword}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <div className="text-center mt-3">
            <Button
              type="submit"
              variant="primary"
              size="lg"
              disabled={isSubmitting}
            >
              Reset password
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  ) : null;
}

export default ResetPasswordForm;
