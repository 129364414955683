import React, { useState, useMemo } from "react";

import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { X } from "react-feather";

import { Button, Modal, ButtonGroup } from "react-bootstrap";

export const DateRangeFilter = ({
  column: {
    filterValue = [],
    preFilteredRows,
    setFilter,
    id,
    Header,
    toggleSortBy,
    manualPagination,
    minDate,
  },
}) => {
  const [min, max] = useMemo(() => {
    if (manualPagination) {
      let min = minDate;
      let max = new Date();

      return [min, max];
    } else {
      let min = preFilteredRows.length
        ? new Date(preFilteredRows[0].values[id])
        : new Date(0);
      let max = preFilteredRows.length
        ? new Date(preFilteredRows[0].values[id])
        : new Date(0);

      preFilteredRows.forEach((row) => {
        const rowDate = new Date(row.values[id]);

        min = rowDate <= min ? rowDate : min;
        max = rowDate >= max ? rowDate : max;
      });

      return [min, max];
    }
  }, [id, preFilteredRows]);

  const [dateRange, setDateRange] = useState([min, max]);
  const [toggle, setToggle] = useState(false);

  const handleChange = (values) => {
    if (values) {
      setDateRange(values);
      setFilter(values);
      toggleSortBy(false);
    } else {
      clear();
    }
  };

  const clear = () => {
    setDateRange([min, max]);
    toggleSortBy(true);
    setFilter(undefined);
  };

  // To match the Bootstrap Form.Control style of default column filters
  const buttonStyle = {
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: "#ced4da",
    borderRadius: "0.1rem",
  };

  return (
    <>
      <ButtonGroup className="w-100 overflow-auto mb-3">
        <Button
          variant="outline-secondary"
          style={buttonStyle}
          onClick={() => setToggle(!toggle)}
        >
          Choose...
        </Button>
        <Button
          style={{ maxWidth: "40px" }}
          variant={filterValue.length ? "danger" : "outline-secondary"}
          disabled={!filterValue.length}
          onClick={clear}
        >
          <X size={16} />
        </Button>
      </ButtonGroup>
      {toggle ? (
        <Modal size="sm" show={toggle} onHide={() => setToggle(!toggle)}>
          <Modal.Header closeButton>
            <Modal.Title>Filter {Header}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="d-flex justify-content-center">
            <DateRangePicker
              minDate={min}
              maxDate={max}
              onChange={handleChange}
              value={dateRange}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setToggle(!toggle)}>
              Done
            </Button>
          </Modal.Footer>
        </Modal>
      ) : null}
    </>
  );
};

export const dateBetweenFilterFn = (rows, id, filterValues) => {
  const sd = filterValues[0] ? new Date(filterValues[0]) : undefined;
  const ed = filterValues[1] ? new Date(filterValues[1]) : undefined;

  if (ed || sd) {
    return rows.filter((r) => {
      const cellDate = new Date(r.values[id]);

      if (ed && sd) {
        return cellDate >= sd && cellDate <= ed;
      } else if (sd) {
        return cellDate >= sd;
      } else if (ed) {
        return cellDate <= ed;
      }
    });
  } else {
    return rows;
  }
};
