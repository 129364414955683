import React, { useState } from "react";

import { Button, Col, Dropdown, Row } from "react-bootstrap";

import { Calendar, Filter, RefreshCw } from "react-feather";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRedo } from "@fortawesome/free-solid-svg-icons";

const Header = ({ loading, setStartDate, refreshData, user }) => {
  const [dateLabel, setDateLabel] = useState("Year To Date");

  const changeStartDate = (interval) => {
    const date = new Date();

    if (interval === 0) {
      setStartDate(new Date(date.getFullYear(), 0, 1));
    } else {
      date.setDate(date.getDate() - interval);
      setStartDate(date);
    }
  };

  return (
    <Row className="mb-2 mb-xl-3">
      <Col xs="auto" className="d-none d-sm-block">
        <h1 className="my-4">
          {user?.name ? `Welcome, ${user.name}!` : "Welcome!"}
        </h1>
      </Col>

      <Col xs="auto" className="ms-auto text-end mt-n1">
        <Dropdown className="d-inline me-2">
          <Dropdown.Toggle variant="light" className="bg-white shadow-sm">
            <Calendar className="feather align-middle me-1" />
            {dateLabel}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              onClick={() => {
                changeStartDate(0);
                setDateLabel("Year To Date");
              }}
            >
              Year To Date
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                changeStartDate(180);
                setDateLabel("Past 180 Days");
              }}
            >
              Past 180 Days
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                changeStartDate(90);
                setDateLabel("Past 90 Days");
              }}
            >
              Past 90 Days
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                changeStartDate(30);
                setDateLabel("Past 30 Days");
              }}
            >
              Past 30 Days
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        {/* <Button variant="primary" className="shadow-sm me-1">
          <Filter className="feather" />
        </Button> */}
        <Button variant="primary" className="shadow-sm" onClick={refreshData}>
          <FontAwesomeIcon icon={faRedo} spin={loading} />
        </Button>
      </Col>
    </Row>
  );
};

export default Header;
