import React from "react";
import UIDefaultPage from "../../components/ui/UIDefaultPage";
import Accounts from "./Accounts";

function AccountsPage() {
  const children = <Accounts />;
  return <UIDefaultPage title="Accounts" children={children} />;
}

export default AccountsPage;
