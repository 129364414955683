import React from "react";
import Table from "../../components/table/Table";
import ShippingInfoLink from "./ShippingInfoLink";
import { needStoreNameColumn } from "../../utils/helper";

import { orderItemTableColumns } from "./tableColumns";

const addShippingInfoColumnLink = (columnsArray, alias) => {
  const shippedClickFunction = ({ row }) => {
    let { shippedQuantity, orderId } = row.original;

    return shippedQuantity > 0 ? (
      <ShippingInfoLink
        value={shippedQuantity}
        orderId={orderId}
        alias={alias}
      />
    ) : !shippedQuantity ? (
      0
    ) : (
      shippedQuantity
    );
  };
  for (const columns of columnsArray) {
    columns.find((col) => col.accessor === "shippedQuantity").Cell =
      shippedClickFunction;
  }
};

function RemovalOrderItemsTable({ removalOrderItems, filter, alias }) {
  addShippingInfoColumnLink([orderItemTableColumns], alias);

  let columns = orderItemTableColumns;
  if (needStoreNameColumn(removalOrderItems)) {
    columns = [{ Header: "Store", accessor: "storeName" }, ...columns];
  }

  return (
    <Table
      columns={columns}
      data={removalOrderItems}
      initialPageSize={50}
      pageName="RemovalOrders"
      initialFilterValues={filter && { filters: [filter] }}
    />
  );
}

export default RemovalOrderItemsTable;
