import React from "react";
import axios from "axios";
import {
  Button,
  Col,
  Form,
  Pagination,
  Row,
  Table,
  Badge,
} from "react-bootstrap";
const HOST_URL = process.env.REACT_APP_HOST_URL;
function AmazonReportsTable({
  amazonReports,
  setCurrentReport,
  fetchData,
  storeId,
  setNewReportData,
  myRef,
}) {
  const updateReports = async (reportId, reportType) => {
    const reportResponse = await axios.get(
      `${HOST_URL}/spApi/getSellerReports?storeId=${storeId}&reportId=${reportId}&reportType=${reportType}`
    );
    setNewReportData(reportResponse.data[0]);
    await fetchData();
    myRef?.current?.scrollIntoView();
  };

  const viewReport = async (reportId) => {
    const reportResponse = await axios.get(
      `${HOST_URL}/spApi/getSingleReport?storeId=${storeId}&reportId=${reportId}`
    );
    console.log(reportResponse.data);
    setNewReportData(reportResponse.data);
    myRef?.current?.scrollIntoView();
  };

  const CheckButton = ({ reportId, reportType }) => {
    return (
      <Button
        onClick={() => {
          updateReports(reportId, reportType);
        }}
        className="btn-info"
      >
        Check Report
      </Button>
    );
  };

  const ViewButton = ({ reportId }) => {
    return (
      <Button
        onClick={() => {
          viewReport(reportId);
        }}
        variant="outline-primary"
      >
        View Report
      </Button>
    );
  };

  const getStatusBadge = (status, reportId, reportType) => {
    let bg;
    let reportButton = "";
    switch (status) {
      case "FAILED":
      case "FATAL":
      case "CANCELLED":
        bg = "danger";
        break;
      case "INSERTED":
      case "DONE":
        bg = "success";
        reportButton = <ViewButton reportId={reportId} />;
        break;
      default:
        bg = "warning";
        reportButton = (
          <CheckButton reportId={reportId} reportType={reportType} />
        );
    }
    return (
      <>
        <td>
          <Badge pill bg={bg} className="mx-2">
            {status}
          </Badge>
        </td>
        <td>{reportButton}</td>
      </>
    );
  };

  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>Report Type</th>
            <th>Last Updated</th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {amazonReports.map((report, i) => {
            return (
              <tr key={i}>
                <td>{report.reportType}</td>
                <td>
                  {new Date(report.updatedAt).toLocaleString().replace(",", "")}
                </td>

                {getStatusBadge(
                  report.status,
                  report.reportId,
                  report.reportType
                )}

                <td>
                  <Button
                    variant="outline-secondary"
                    onClick={() => {
                      setCurrentReport(report.reportType);
                    }}
                  >
                    History
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
}

export default AmazonReportsTable;
