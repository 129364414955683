import React, { useState, useContext } from "react";

import { Formik } from "formik";
import { Alert, Button, Form } from "react-bootstrap";
import { AlertTriangle } from "react-feather";
import toast from "react-hot-toast";
import * as Yup from "yup";

import storeService from "../../services/storeService";
import VendorSelect from "./VendorSelect";

import { AccountsContext } from "./Accounts";

const StoresForm = ({
  selectedStore,
  setSelectedStore,
  setModal,
  isEdit,
  vendorId,
}) => {
  const [validatedField, setValidatedField] = useState({
    sellingPartnerId: {
      isValidated: false,
      isUnique: null,
    },
  });

  const { setStores } = useContext(AccountsContext);

  //Taken and modified from SignUp
  /* async validation function - calls our API to see if the field already exists.
   * If exists, the function returns false, otherwise returns true
   */
  const checkForExistingField = async (fieldName, fieldValue) => {
    // this if prevents extra calls to the API:
    if (validatedField[fieldName].isValidated === false && fieldValue) {
      try {
        const response = await storeService.validateField(
          fieldName,
          fieldValue
        );
        if (!response) {
          setValidatedField({
            ...validatedField,
            [fieldName]: { isValidated: true, isUnique: true },
          });
          return true;
        } else {
          setValidatedField({
            ...validatedField,
            [fieldName]: {
              isValidated: true,
              isUnique: !response.data.fieldExists,
            },
          });
          return !response.data.fieldExists;
        }
      } catch (error) {
        console.log(error);
        setValidatedField({
          ...validatedField,
          [fieldName]: { isValidated: true, isUnique: true },
        });
        return true;
      }
    }
    // need this 'else if' and 'else' at the end so that when these fields are validated
    // on submit, the validation isn't flipped (unique fields gets an error, and non-unique field gets no error)
    else if (
      validatedField[fieldName].isValidated === true &&
      validatedField[fieldName].isUnique === false
    )
      return false;
    else return true;
  };

  // for fields with async validation - when field changes, sets all validation state values to default
  const fieldChangedHandler = (fieldName, fieldValue, handleChange) => {
    setValidatedField({
      ...validatedField,
      [fieldName]: { isValidated: false, isUnique: null },
    });
    handleChange(fieldValue);
  };

  const initialValues = isEdit
    ? {
        storeId: selectedStore.storeId,
        storeName: selectedStore.storeName,
        companyName: selectedStore.companyName || "",
        sellingPartnerId: selectedStore.sellingPartnerId || "",
        vendorId: selectedStore.vendorId,
        isActive: selectedStore.isActive,
      }
    : {
        storeName: "",
        companyName: "",
        sellingPartnerId: "",
        vendorId: vendorId || "",
        isActive: true,
      };

  const validationSchema = isEdit
    ? Yup.object().shape({
        storeName: Yup.string()
          .max(255, "Store Name must be under 255 characters.")
          .required("Store Name is required."),
        vendorId: Yup.string().required("Vendor Name is required."),
        sellingPartnerId: Yup.string().max(
          255,
          "Selling Partner Id must be under 255 characters."
        ),
      })
    : Yup.object().shape({
        storeName: Yup.string()
          .max(255, "Store Name must be under 255 characters.")
          .required("Store Name is required."),
        vendorId: Yup.string().required("Vendor Name is required."),
        sellingPartnerId: Yup.string()
          .max(255, "Selling Partner Id must be under 255 characters.")
          .test(
            "Unique Selling Partner Id",
            "Selling Partner Id is already in use",
            async (value) =>
              await checkForExistingField("sellingPartnerId", value)
          ),
      });

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, { setErrors, setSubmitting }) => {
        setSubmitting(true);

        if (isEdit) {
          const submittingToast = toast.loading("Saving changes...");

          const result = await storeService
            .updateStore(selectedStore, values)
            .catch((error) => {
              console.error(error);
              setErrors({
                submit:
                  error?.response?.data?.message ||
                  error?.message ||
                  "Something went wrong.",
              });
              toast.dismiss(submittingToast);
              toast.error(
                error?.response?.data?.message ||
                  error?.message ||
                  "Something went wrong."
              );
              setSubmitting(false);
            });

          if (result.success) {
            toast.dismiss(submittingToast);
            toast.success("Store successfully updated!");

            const response = await storeService.getStores().catch((error) => {
              console.error(error);
              toast.dismiss(submittingToast);
              toast.error(
                error?.response?.data?.message ||
                  error?.message ||
                  "Something went wrong."
              );
              setSubmitting(false);
            });

            setStores(response);
          } else {
            toast.dismiss(submittingToast);
            toast.error("Store unsuccessfully updated!");
          }

          setSubmitting(false);
          setModal({ show: false, type: null });
          setSelectedStore(null);
        } else {
          const submittingToast = toast.loading("Creating store...");

          const result = await storeService
            .createStore(values)
            .catch((error) => {
              console.error(error);
              setErrors({
                submit:
                  error?.response?.data?.message ||
                  error?.message ||
                  "Something went wrong.",
              });
              toast.dismiss(submittingToast);
              toast.error(
                error?.response?.data?.message ||
                  error?.message ||
                  "Something went wrong."
              );
              setSubmitting(false);
            });

          if (result.success) {
            toast.dismiss(submittingToast);
            toast.success("Store successfully created!");

            const response = await storeService.getStores().catch((error) => {
              console.error(error);
              toast.dismiss(submittingToast);
              toast.error(
                error?.response?.data?.message ||
                  error?.message ||
                  "Something went wrong."
              );
              setSubmitting(false);
            });

            console.log(response);

            setStores(response);
          } else {
            toast.dismiss(submittingToast);
            toast.success("Store unsuccessfully created!");
          }

          setSubmitting(false);
          setModal({ show: false, type: null });
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        touched,
        values,
      }) => (
        <Form onSubmit={handleSubmit}>
          {errors?.submit && (
            <Alert className="my-3" variant="danger">
              <div className="alert-icon d-flex align-items-center">
                <AlertTriangle />
              </div>
              <div className="alert-message">{errors.submit}</div>
            </Alert>
          )}
          <Form.Group className="mb-3">
            <Form.Label className="mb-0">Store Name</Form.Label>
            <Form.Control
              type="text"
              name="storeName"
              placeholder="Enter store name..."
              value={values.storeName}
              isInvalid={Boolean(touched.storeName && errors.storeName)}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {!!touched.storeName && (
              <Form.Control.Feedback type="invalid">
                {errors.storeName}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="mb-0">Company Name</Form.Label>
            <Form.Control
              type="text"
              name="companyName"
              placeholder="Enter company name..."
              value={values.companyName}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="mb-0">Selling Partner Id</Form.Label>
            <Form.Control
              type="text"
              name="sellingPartnerId"
              placeholder="Enter selling partner id..."
              value={values.sellingPartnerId}
              isInvalid={Boolean(
                touched.sellingPartnerId && errors.sellingPartnerId
              )}
              onBlur={handleBlur}
              onChange={(value) =>
                fieldChangedHandler("sellingPartnerId", value, handleChange)
              }
            />
            {!!touched.sellingPartnerId && (
              <Form.Control.Feedback type="invalid">
                {errors.sellingPartnerId}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          {!vendorId && (
            <Form.Group className="mb-3">
              <Form.Label className="mb-0">Vendor</Form.Label>
              <VendorSelect
                vendorId={values.vendorId}
                setFieldValue={setFieldValue}
                allowNoVendor={false}
              />
            </Form.Group>
          )}
          <Form.Group className="mb-3">
            <Form.Label className="mb-0">Active</Form.Label>
            <Form.Check
              id="isActive"
              type="checkbox"
              name="isActive"
              label={
                <span htmlFor="isActive" className="cursor-pointer">
                  Check this if this store is Active.
                </span>
              }
              checked={values.isActive}
              onChange={handleChange}
            />
          </Form.Group>
          {isEdit ? (
            <div className="d-flex justify-content-between">
              <Button type="submit" variant="success" disabled={isSubmitting}>
                Save Changes
              </Button>
              {!vendorId ? (
                <Button
                  variant="danger"
                  onClick={() => {
                    setModal({ show: true, type: "delete" });
                  }}
                  disabled={isSubmitting}
                >
                  Delete Store
                </Button>
              ) : (
                <Button
                  variant="secondary"
                  onClick={() => {
                    setModal({ show: false, type: null });
                  }}
                  disabled={isSubmitting}
                >
                  Cancel
                </Button>
              )}
            </div>
          ) : (
            <div className="d-flex justify-content-between">
              <Button type="submit" variant="success" disabled={isSubmitting}>
                Create Store
              </Button>
              <Button
                variant="secondary"
                onClick={() => {
                  setModal({ show: false, type: null });
                }}
                disabled={isSubmitting}
              >
                Cancel
              </Button>
            </div>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default StoresForm;
