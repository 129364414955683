import React from "react";
import Table from "../../components/table/Table";
import { itemTableColumns, shipmentItemTableColumns } from "./tableColumns";

function AcceptanceDataItemTable({
  acceptanceDataItems,
  filteredByShipment,
  currentTrackingNumber,
}) {
  return (
    <Table
      columns={filteredByShipment ? shipmentItemTableColumns : itemTableColumns}
      data={acceptanceDataItems}
      initialPageSize={50}
      pageName="Acceptance-Processing"
      filteredBy={currentTrackingNumber}
    />
  );
}

export default AcceptanceDataItemTable;
