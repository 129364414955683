import axios from "axios";

const HOST_URL = process.env.REACT_APP_HOST_URL;

const getStores = async () => {
  const response = (await axios.get(`${HOST_URL}/stores/getStores`)).data;

  const stores = response.stores;

  return stores;
};

const getStore = async (storeId, sellingPartnerId) => {
  const storeRef = storeId ? storeId : sellingPartnerId;

  const response = (await axios.get(`${HOST_URL}/stores/getStore/${storeRef}`))
    .data;

  return response;
};

const createStore = async (formValues) => {
  const newStore = {
    storeName: formValues.storeName,
    companyName: formValues.companyName,
    sellingPartnerId: formValues.sellingPartnerId,
    vendorId: formValues.vendorId,
    isActive: formValues.isActive,
  };

  // Call the API endpoint for creating a new user.
  const response = (
    await axios.post(`${HOST_URL}/stores/createStore`, newStore)
  ).data;

  return response;
};

const updateStore = async (selectedStore, formValues) => {
  const updatedStore = Object.assign(selectedStore, formValues);
  console.log(updatedStore);

  const response = (
    await axios.post(`${HOST_URL}/stores/updateStore`, updatedStore)
  ).data;

  return response;
};

const deleteStore = async (storeId) => {
  const response = (
    await axios.delete(`${HOST_URL}/stores/deleteStore?storeId=${storeId}`)
  ).data;

  return response;
};

const validateField = async (fieldName, fieldValue) => {
  const response = await axios.get(
    `${HOST_URL}/utils/checkForExistingStoreField?${fieldName}=${fieldValue}`
  );

  return response;
};

const service = {
  getStore,
  getStores,
  createStore,
  updateStore,
  deleteStore,
  validateField,
};

export default service;
