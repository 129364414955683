import React from "react";

function LpnListItem({ scannedItem }) {
  const { lpn, itemName, asin, condition } = scannedItem;
  return (
    <>
      <td>{lpn}</td>
      <td>{itemName}</td>
      <td>{asin}</td>
      <td>{condition}</td>
    </>
  );
}

export default LpnListItem;
