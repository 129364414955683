import React from "react";
import { Card, Row, Col } from "react-bootstrap";
import VendorSelect from "./VendorSelect";
import UserSelect from "./UserSelect";

function AdminFilters({ alias, setAlias, clearData }) {
  return (
    <Row className="mb-3">
      <Col xl={2} md={4}>
        <VendorSelect alias={alias} setAlias={setAlias} clearData={clearData} />
      </Col>
      {/* <Col  xl={2} md={4}>
        <UserSelect alias={alias} setAlias={setAlias} />
      </Col> */}
    </Row>
  );
}

export default AdminFilters;
