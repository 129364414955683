import axios from "axios";
import React, { useEffect, useState } from "react";

import {
  Alert,
  Button,
  Card,
  Spinner,
  Tab,
  Col,
  Row,
  ButtonGroup,
  Table as SelectedOrderTable,
} from "react-bootstrap";
import { AlertCircle } from "react-feather";

import Table from "../../components/table/Table";
import UIDefaultPage from "../../components/ui/UIDefaultPage";

import { columns, columnsItems } from "./tableColumns";

const HOST_URL = process.env.REACT_APP_HOST_URL;
const DEFAULT_ROWS_PER_PAGE = 100;

const AmazonOrdersPage = () => {
  const [pageData, setPageData] = useState({
    isLoading: false,
    rowData: [],
    totalRows: 0,
    pageSize: DEFAULT_ROWS_PER_PAGE,
    totalPages: 0,
    filters: {},
    minDate: "",
  });
  const [pageItemsData, setPageItemsData] = useState({
    isLoading: false,
    rowData: [],
    totalRows: 0,
    pageSize: DEFAULT_ROWS_PER_PAGE,
    totalPages: 0,
    filters: {},
    minDate: "",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [currentItemsPage, setCurrentItemsPage] = useState(1);
  const [lastUpdated, setLastUpdated] = useState();
  const [loadingUpdates, setLoadingUpdates] = useState(false);
  const [error, setError] = useState();
  const [activeTab, setActiveTab] = useState();
  const [currentOrder, setCurrentOrder] = useState({});
  const skip = (currentPage - 1) * pageData.pageSize;
  const itemsSkip = (currentItemsPage - 1) * pageItemsData.pageSize;

  const getOrders = async (requestUpdate = false) => {
    setPageData((prevState) => ({
      ...prevState,
      isLoading: true,
    }));
    try {
      const data = (
        await axios.post(
          `${HOST_URL}/spApi/getAmazonOrders?${
            requestUpdate ? "requestUpdate=true&" : ""
          }skip=${skip}&take=${pageData.pageSize}`,
          {
            filters: pageData.filters,
          }
        )
      ).data;

      let totalPages, totalRows, minDate;
      if (data.orders.length > 0) {
        totalPages = Math.ceil(
          data.orders[0].totalFilteredRowCount / pageData.pageSize
        );
        totalRows = data.orders[0].totalFilteredRowCount;
        minDate = new Date(data.orders[0].minLatestShipDate);
        setLastUpdated(new Date(data.lastUpdated).toLocaleDateString());
      } else {
        totalPages = 0;
        totalRows = 0;
        minDate = new Date(0);
      }

      setPageData((prevState) => ({
        ...prevState,
        isLoading: false,
        rowData: data.orders,
        totalPages,
        totalRows,
        minDate,
      }));

      setError();
      requestUpdate && (await getOrderItems());
    } catch (error) {
      console.error(error);
      setError(error.message);
      setPageData((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
    }
  };

  const getOrderItems = async () => {
    setPageItemsData((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    try {
      const data = (
        await axios.post(
          `${HOST_URL}/spApi/getAmazonOrderItems?skip=${itemsSkip}&take=${pageItemsData.pageSize}`,
          {
            filters: pageItemsData.filters,
          }
        )
      ).data;

      let totalPages, totalRows, minDate;
      if (data.orderItems.length > 0) {
        totalPages = Math.ceil(
          data.orderItems[0].totalFilteredRowCount / pageItemsData.pageSize
        );
        totalRows = data.orderItems[0].totalFilteredRowCount;
        minDate = new Date(data.orderItems[0].minLatestShipDate);
        setLastUpdated(new Date(data.lastUpdated).toLocaleDateString());
      } else {
        totalPages = 0;
        totalRows = 0;
        minDate = new Date(0);
      }
      setPageItemsData((prevState) => ({
        ...prevState,
        isLoading: false,
        rowData: data.orderItems,
        totalPages,
        totalRows,
        minDate,
      }));
      setError();
    } catch (error) {
      console.error(error);
      setError(error.message);
      setPageItemsData((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
    }
  };

  const showOrderItems = async (row) => {
    console.log(pageItemsData.filters);
    setCurrentOrder({ row });
    setActiveTab("viewItems");
    setPageItemsData((prevState) => ({
      ...prevState,
      filters: {
        amazonOrderId: row.original.amazonOrderId,
        globalFilter: undefined,
      },
    }));
  };

  const showOrder = async (orderId) => {
    setCurrentOrder({ id: orderId });
    setActiveTab();
    setPageItemsData((prevState) => ({
      ...prevState,
      filters: {
        amazonOrderId: orderId,
        globalFilter: undefined,
      },
    }));
    setPageData((prevState) => ({
      ...prevState,
      filters: {
        amazonOrderId: orderId,
        globalFilter: undefined,
      },
    }));
  };

  const orderIdClickFunction = ({ row }) => {
    return (
      <Button
        variant="link"
        className="link-info"
        title={"Click to view items"}
        onClick={() => showOrderItems(row)}
      >
        {row.original.amazonOrderId}
      </Button>
    );
  };
  const itemOrderIdClickFunction = ({ row }) => {
    return (
      <Button
        variant="link"
        className="link-info"
        title={"Click to view order"}
        onClick={() => showOrder(row.original.amazonOrderId)}
      >
        {row.original.amazonOrderId}
      </Button>
    );
  };
  //add the Cell function the the columns array
  columns.find((col) => col.accessor === "amazonOrderId").Cell =
    orderIdClickFunction;
  columnsItems.find((col) => col.accessor === "amazonOrderId").Cell =
    itemOrderIdClickFunction;

  useEffect(() => {
    if (activeTab === "viewOrders") {
      getOrders();
    } else if (activeTab === "viewItems") {
      getOrderItems();
    } else {
      getOrders();
      getOrderItems();
      setActiveTab("viewOrders");
    }
  }, [
    currentPage,
    pageData.pageSize,
    pageData.filters,
    currentItemsPage,
    pageItemsData.pageSize,
    pageItemsData.filters,
  ]);

  // will call the BE to fetch updated data from Amazon sp-api:
  const fetchUpdatedData = async () => {
    setLoadingUpdates(true);

    await getOrders("requestUpdate");

    setLoadingUpdates(false);
  };

  const CurrentOrderCard = () => {
    return (
      <Card bg="light" className="p-4 text-center">
        <Row className="justify-content-end">
          {currentOrder.id && (
            <Col sm={4}>
              <Card.Title className="text-center">
                Amazon Order ID: {currentOrder.id}
              </Card.Title>
            </Col>
          )}
          <Col sm={4} style={{ textAlign: "right" }}>
            <Button
              className="mb-3 ml-auto"
              variant="warning"
              title="Show all orders"
              onClick={() => {
                setActiveTab();
                setPageItemsData((prevState) => ({
                  ...prevState,
                  filters: {},
                }));
                if (currentOrder.id) {
                  setPageData((prevState) => ({
                    ...prevState,
                    filters: {},
                  }));
                }

                setCurrentOrder({});
              }}
            >
              Clear
            </Button>
          </Col>
        </Row>
        {currentOrder.row && (
          <SelectedOrderTable size="sm" style={{ tableLayout: "fixed" }}>
            <thead>
              <tr>
                {columns.map((column, index) => {
                  return (
                    <React.Fragment key={index}>
                      <th>
                        <div>{column.Header}</div>
                      </th>
                    </React.Fragment>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              <tr>
                {currentOrder.row.cells.map((cell) => (
                  <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                ))}
              </tr>
            </tbody>
          </SelectedOrderTable>
        )}
      </Card>
    );
  };

  const child = (
    <Card>
      <Card.Header>
        {error && (
          <Alert variant="danger" className="flex-fill">
            <div className="alert-icon">
              <AlertCircle
                className="position-relative top-50 start-50 translate-middle"
                size={20}
              />
            </div>
            <div className="alert-message">{error}</div>
          </Alert>
        )}
        <div className="d-flex justify-content-between">
          <div>
            <Button
              title="Request updated order data from Amazon"
              onClick={fetchUpdatedData}
              disabled={loadingUpdates}
              variant="primary"
            >
              {loadingUpdates ? (
                <>
                  <Spinner
                    className="me-2"
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  Loading...
                </>
              ) : (
                "Refresh"
              )}
            </Button>
            <span className="p-2 font-weight-bold">
              Last Requested from Amazon: {lastUpdated}
            </span>
          </div>
        </div>
      </Card.Header>

      <Card.Body>
        <Tab.Container id="view-tabs" activeKey={activeTab}>
          <Row className="justify-content-center mt-3 mb-2">
            <Col xs="auto">
              <ButtonGroup className="mb-3">
                <Button
                  variant="outline-primary"
                  onClick={() => setActiveTab("viewOrders")}
                  className={activeTab === "viewOrders" ? "active" : ""}
                >
                  View Orders
                </Button>
                <Button
                  variant="outline-primary"
                  onClick={() => setActiveTab("viewItems")}
                  className={activeTab === "viewItems" ? "active" : ""}
                >
                  View Items
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          {(currentOrder.id || currentOrder.row) && <CurrentOrderCard />}
          <Tab.Content>
            <Tab.Pane eventKey="viewOrders">
              <Card.Body className="text-center">
                <>
                  {pageData.rowData.length > 0 && (
                    <span>
                      Showing {skip + 1 < 0 ? 0 : skip + 1}-
                      {skip + pageData.pageSize > pageData.totalRows
                        ? pageData.totalRows
                        : skip + pageData.pageSize}{" "}
                      of {pageData.totalRows} Orders
                    </span>
                  )}
                  <Table
                    columns={columns}
                    data={pageData.rowData}
                    manualPagination={true}
                    pageData={pageData}
                    setPageData={setPageData}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                  />
                </>
              </Card.Body>
            </Tab.Pane>
            <Tab.Pane eventKey="viewItems">
              <Card.Body className="text-center">
                <>
                  {pageItemsData.rowData.length > 0 && (
                    <span>
                      Showing {itemsSkip + 1 < 0 ? 0 : itemsSkip + 1}-
                      {itemsSkip + pageItemsData.pageSize >
                      pageItemsData.totalRows
                        ? pageItemsData.totalRows
                        : itemsSkip + pageItemsData.pageSize}{" "}
                      of {pageItemsData.totalRows} Order Items
                    </span>
                  )}
                  <Table
                    columns={columnsItems}
                    data={pageItemsData.rowData}
                    manualPagination={true}
                    pageData={pageItemsData}
                    setPageData={setPageItemsData}
                    currentPage={currentItemsPage}
                    setCurrentPage={setCurrentItemsPage}
                  />
                </>
              </Card.Body>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </Card.Body>
    </Card>
  );

  return <UIDefaultPage title="Amazon Orders" children={child} />;
};

export default AmazonOrdersPage;
