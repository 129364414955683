import React from "react";
import UICard from "../../components/ui/UICard";

function LpnItem({ currentItem }) {
  const { lpn, itemName, asin, condition } = currentItem;
  const body = (
    <>
      <h3 className="font-weight-bold">{lpn}</h3>
      <div className="text-center m-3">
        <span className="font-weight-bold">Name:</span> {itemName}
      </div>
      <div className="text-center m-3">
        <span className="font-weight-bold">ASIN:</span> {asin}
      </div>
      <div className="text-center m-3">
        <span className="font-weight-bold">Condition:</span> {condition}
      </div>
    </>
  );
  return <UICard className="text-center w-75 m-auto my-3" body={body} />;
}

export default LpnItem;
