import axios from "axios";
import { handleError } from "../utils/helper";

const HOST_URL = process.env.REACT_APP_HOST_URL;

// For testing
const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const getProcessingMetrics = async (vendorId, startDate) => {
  try {
    return (
      await axios.get(
        `${HOST_URL}/analytics/processing/${vendorId}?startDate=${startDate}`
      )
    ).data;
  } catch (error) {
    handleError(error);
    return error;
  }
};

const getReturnMetrics = async () => {};

const getResaleMetrics = async () => {};

const getInventoryMetrics = async () => {};

const getFinancialMetrics = async () => {};

const getCustomerSatisfactionMetrics = async () => {};

const getTrendsAndPatterns = async () => {};

const service = {
  getProcessingMetrics,
  getReturnMetrics,
  getResaleMetrics,
  getInventoryMetrics,
  getFinancialMetrics,
  getCustomerSatisfactionMetrics,
  getTrendsAndPatterns,
};

export default service;
