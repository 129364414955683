import React from "react";
import { Alert, Button, Card, Form } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import toast from "react-hot-toast";

const HelpForm = () => {
  const HOST_URL = process.env.REACT_APP_HOST_URL;

  const vendor = JSON.parse(localStorage.getItem("vendor"));

  return (
    <Card>
      <Card.Header>
        <Card.Title tag="h5">How can we help you?</Card.Title>
      </Card.Header>
      <Card.Body>
        <Formik
          initialValues={{
            email: "",
            comments: "",
            submit: false,
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email("Email must be valid.")
              .max(255)
              .required("Email is required"),
            comments: Yup.string().required("Comments is required"),
          })}
          onSubmit={async (
            values,
            { setErrors, setStatus, setSubmitting, resetForm }
          ) => {
            try {
              setSubmitting(true);
              await axios.post(`${HOST_URL}/management/sendEmailToSupport`, {
                email: values.email,
                comments: values.comments,
                vendorId: vendor?.vendorId || "",
                name: vendor?.name || "",
              });
              resetForm();
              toast.success("Help request submitted!");
              setTimeout(() => {
                window.location = "/";
              }, 1500);
              setSubmitting(false);
            } catch (error) {
              const message = error.message || "Something went wrong";
              resetForm();
              setStatus({ success: false });
              setErrors({ submit: message });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <Form onSubmit={handleSubmit}>
              {errors.submit && (
                <Alert className="my-3" variant="danger">
                  <div className="alert-message">{errors.submit}</div>
                </Alert>
              )}
              <Form.Group className="mb-3">
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  placeholder="Enter email"
                  value={values.email}
                  isInvalid={Boolean(touched.email && errors.email)}
                  onChange={handleChange}
                />
                {!!touched.email && (
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                )}
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Comments</Form.Label>
                <Form.Control
                  as="textarea"
                  name="comments"
                  placeholder="Please let us know how we can help you."
                  rows="3"
                  value={values.comments}
                  isInvalid={Boolean(touched.comments && errors.comments)}
                  onChange={handleChange}
                />
                <Form.Text className="text-muted">
                  We will get back to you as soon as possible.
                </Form.Text>
                {!!touched.comments && (
                  <Form.Control.Feedback type="invalid">
                    {errors.comments}
                  </Form.Control.Feedback>
                )}
              </Form.Group>

              <div className="text-center">
                <Button
                  type="submit"
                  variant="primary"
                  size="lg"
                  disabled={isSubmitting}
                >
                  Submit
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Card.Body>
    </Card>
  );
};

export default HelpForm;
