import AmazonASINLink from "../../components/AmazonASINLink";
import {
  DateRangeFilter,
  dateBetweenFilterFn,
} from "../../components/table/DateRangeFilter";

import imageNotFound from "../../assets/img/imageNotFound.png";

const MAX_LENGTH = 50;

const fbaCustomerReturnsColumns = [
  {
    Header: "Order ID",
    accessor: "orderId",
  },
  {
    id: "image",
    Header: "Product Image",
    accessor: (row) => (
      <a
        href={`https://www.amazon.com/dp/${row.asin}?th=1&psc=1`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          className="img-thumbnail"
          style={{ maxHeight: "75px" }}
          src={`https://ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=${row.asin}&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=SL250`}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = imageNotFound;
          }}
          alt="Item"
        />
      </a>
    ),
  },
  {
    Header: "ASIN",
    accessor: "asin",
    Cell: ({ value }) => <AmazonASINLink ASIN={value} />,
  },
  {
    Header: "Product Name",
    accessor: "productName",
    Cell: ({ value }) => {
      const truncated = value.length > MAX_LENGTH;
      return (
        <span className={truncated ? "cursor-pointer" : ""} title={value}>
          {value.slice(0, MAX_LENGTH)}
          {truncated && "..."}
        </span>
      );
    },
  },
  {
    Header: "SKU",
    accessor: "sku",
  },
  {
    Header: "Disposition",
    accessor: "detailedDisposition",
  },
  {
    Header: "Customer Comments",
    accessor: "customerComments",
    Cell: ({ value }) => {
      const truncated = value.length > MAX_LENGTH;
      return (
        <span className={truncated ? "cursor-pointer" : ""} title={value}>
          {value.slice(0, MAX_LENGTH)}
          {truncated && "..."}
        </span>
      );
    },
  },
  {
    Header: "Reason",
    accessor: "reason",
  },
  {
    Header: "Return Date",
    id: "returnDate",
    sortType: "datetime",
    Cell: ({ value }) => {
      return <span>{new Date(value).toLocaleDateString()}</span>;
    },
    accessor: ({ returnDate }) => new Date(returnDate),
    Filter: DateRangeFilter,
    filter: dateBetweenFilterFn,
  },
];

export default fbaCustomerReturnsColumns;
