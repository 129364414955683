import React, { useMemo, useState } from "react";

import { Button, Card, Col, Modal, Row } from "react-bootstrap";
import { Check, X } from "react-feather";
import toast from "react-hot-toast";

import AccountTable from "./AccountTable";
import StoresCard from "./StoresCard";
import StoresForm from "./StoresForm";

import storeService from "../../services/storeService";

const Stores = ({ stores, setStores }) => {
  const [selectedStore, setSelectedStore] = useState(null);
  const [modal, setModal] = useState({ show: false, type: null });

  const accountRowClickHandler = (row) => {
    setSelectedStore(row);
  };

  const deleteStoreHandler = async () => {
    const submittingToast = toast.loading("Deleting store...");

    const response = await storeService
      .deleteStore(selectedStore.storeId)
      .catch((error) => {
        console.error(error);
        toast.dismiss(submittingToast);
        toast.error(
          error?.response?.data?.message ||
            error?.message ||
            "Something went wrong."
        );
        setModal({ show: false, type: null });
      });

    if (response.success) {
      const stores = await storeService.getStores().catch((error) => {
        console.error(error);
      });

      setStores(stores);
    }

    toast.dismiss(submittingToast);
    toast.success("Store successfully deleted!");
    setModal({ show: false, type: null });
    setSelectedStore(null);
  };

  const columns = useMemo(
    () => [
      {
        Header: "Store Name",
        accessor: "storeName",
        Cell: ({ row }) => {
          return (
            <a
              href={`https://www.amazon.com/s?me=${row.original.sellingPartnerId}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {row.original.storeName}
            </a>
          );
        },
      },
      {
        Header: "Company Name",
        accessor: "companyName",
      },
      { Header: "Vendor Name", accessor: "vendorName" },
      {
        Header: "Selling Partner Id",
        accessor: "sellingPartnerId",
        Cell: ({ row }) => {
          return (
            <a
              href={`https://www.amazon.com/sp?seller=${row.original.sellingPartnerId}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {row.original.sellingPartnerId}
            </a>
          );
        },
      },
      {
        Header: "Linked",
        accessor: (store) => {
          if (store.refreshToken && store.accessToken) {
            return <Check color="#4BBF73" size={24} />;
          } else {
            return <X color="#d9534f" size={24} />;
          }
        },
        sortType: (row) =>
          row.original.refreshToken && row.original.accessToken ? -1 : 1,
      },
      {
        Header: "Active",
        accessor: (store) => {
          if (store.isActive) {
            return <Check color="#4BBF73" size={24} />;
          } else {
            return <X color="#d9534f" size={24} />;
          }
        },
        sortType: (row) => (row.original.isActive ? -1 : 1),
      },
      {
        Header: "Action",
        accessor: (store) => {
          return (
            <Button
              variant="primary"
              title={"Click to go to the reports page"}
              onClick={() =>
                (window.location = `/admin/amazonReports?storeId=${store.storeId}&storeName=${store.storeName}`)
              }
            >
              Reports
            </Button>
          );
        },
        sortType: (row) =>
          row.original.refreshToken && row.original.accessToken ? -1 : 1,
      },
    ],
    []
  );

  return (
    <>
      <Modal
        size="sm"
        show={modal.show}
        onHide={() => {
          setModal({ show: false, type: null });
        }}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-center w-100">
            {modal.type === "create" ? "Create Store" : "Delete Store"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modal.type === "create" ? (
            <StoresForm isEdit={false} setModal={setModal} />
          ) : modal.type === "delete" ? (
            <>
              <div className="mb-3">
                Are you sure you want to delete{" "}
                <strong>{selectedStore.storeName}</strong> ?
                <div>
                  <small>This action cannot be undone.</small>
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <Button variant="danger" onClick={deleteStoreHandler}>
                  Delete Store
                </Button>
                <Button
                  variant="secondary"
                  onClick={() => {
                    setModal({ show: false, type: null });
                  }}
                >
                  Cancel
                </Button>
              </div>
            </>
          ) : null}
        </Modal.Body>
      </Modal>

      <Row>
        <Col xl={8}>
          <Card>
            <Card.Body>
              <AccountTable
                columns={columns}
                data={stores}
                onClickFn={accountRowClickHandler}
                clickableRows={true}
                actionButton={
                  <Button
                    variant="primary"
                    onClick={() => {
                      setModal({ show: true, type: "create" });
                      setSelectedStore(null);
                    }}
                    className="mb-2"
                  >
                    Create Store
                  </Button>
                }
              />
            </Card.Body>
          </Card>
        </Col>
        <Col xl={4}>
          <StoresCard
            selectedStore={selectedStore}
            setSelectedStore={setSelectedStore}
            setModal={setModal}
            isEdit={true}
            setStores={setStores}
          />
        </Col>
      </Row>
    </>
  );
};

export default Stores;
