import React, { useState } from "react";
import { Col, Card, Button, Alert } from "react-bootstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import toast from "react-hot-toast";

function AccountCard({ logo, account, authLink }) {
  const [hovered, setHovered] = useState(false);
  const toggleHover = () => setHovered(!hovered);

  const { storeName, sellingPartnerId, hasCredentials } = account;

  return (
    <Col lg={4}>
      <Card
        className={`${hovered ? "shadow-lg" : ""}`}
        onMouseEnter={toggleHover}
        onMouseLeave={toggleHover}
      >
        <Card.Body>
          <Card.Title className="mb-3">
            <span className="me-3">{logo}</span>
            {storeName}
          </Card.Title>
          {!hasCredentials && (
            <Alert className="px-3 py-1" variant="warning">
              <strong>Please update the credentials for this store.</strong>
            </Alert>
          )}
          <Button
            onClick={() =>
              window.open(
                `https://www.amazon.com/s?i=merchant-items&me=${sellingPartnerId}`,
                "_blank"
              )
            }
          >
            Visit Store
          </Button>
          {!hasCredentials && (
            <Button
              className="ms-2"
              variant="warning"
              onClick={() => (window.location.href = authLink)}
            >
              Update Credentials
            </Button>
          )}
          <CopyToClipboard
            text={`RWW/${storeName} 200 South Pemberton Road, Pemberton, NJ 08068 7323130855`}
            onCopy={() => toast.success("Copied to clipboard")}
          >
            <Button
              className="float-end"
              variant="info"
              title={`RWW/${storeName} 200 South Pemberton Road, Pemberton, NJ 08068 7323130855`}
            >
              Copy Removal Address
            </Button>
          </CopyToClipboard>
        </Card.Body>
      </Card>
    </Col>
  );
}

export default AccountCard;
