const salesActivityColumns = [
  {
    Header: "Transaction Type",
    accessor: "transactionType",
  },
  {
    Header: "Description",
    accessor: "transactionDescription",
  },
  {
    Header: "SKU",
    accessor: "transactionSku",
  },
  {
    Header: "Quantity",
    accessor: "transactionQty",
  },
  {
    Header: "Amount",
    accessor: "transactionAmount",
  },
  {
    Header: "Fees",
    accessor: "transactionFees",
  },
  {
    Header: "Total",
    accessor: "transactionTotal",
  },
  {
    Header: "Payout",
    accessor: "payout",
  },
];

export default salesActivityColumns;
