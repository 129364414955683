import React from "react";
import { Button } from "react-bootstrap";
import Table from "../../components/table/Table";
import { needStoreNameColumn } from "../../utils/helper";
import OrderIdLink from "./OrderIdLink";
import { shipmentColumns } from "./tableColumns";

function RemovalOrderShipmentsTable({
  removalOrderShipments,
  setCurrentShipment,
  filter,
  alias,
}) {
  const trackingNumberClickFunction = ({ row }) => {
    const { trackingNumber } = row.original;
    return (
      <Button
        variant="link"
        className="link-info"
        title={"Click to view items"}
        onClick={() => {
          setCurrentShipment(row.original);
        }}
      >
        {trackingNumber}
      </Button>
    );
  };

  //add the Cell function the the columns array
  shipmentColumns.find((col) => col.accessor === "trackingNumber").Cell =
    trackingNumberClickFunction;

  const orderIdClickFunction = ({ row }) => {
    const { orderId } = row.original;
    return <OrderIdLink orderId={orderId} alias={alias} />;
  };

  shipmentColumns.find((col) => col.accessor === "orderId").Cell =
    orderIdClickFunction;

  let columns = shipmentColumns;

  if (needStoreNameColumn(removalOrderShipments)) {
    columns = [{ Header: "Store", accessor: "storeName" }, ...columns];
  }

  return (
    <Table
      columns={columns}
      data={removalOrderShipments}
      initialPageSize={50}
      pageName="RemovalShipments" // For CSV Download
      initialFilterValues={filter && { filters: [filter] }}
    />
  );
}

export default RemovalOrderShipmentsTable;
