import React from "react";

import useAuth from "../../hooks/useAuth";

import { Dropdown } from "react-bootstrap";

import { PieChart, Settings, User } from "react-feather";

const NavbarUserLoggedOut = () => {
  const useSavedUser = JSON.parse(
    localStorage.getItem("portal.returnsWorldwide.rememberMe")
  );
  const user = useSavedUser ? JSON.parse(localStorage.getItem("user")) : null;

  return (
    <Dropdown className="nav-item" align="end">
      <span className="d-inline-block d-sm-none">
        <Dropdown.Toggle as="a" className="nav-link">
          <Settings size={18} className="align-middle" />
        </Dropdown.Toggle>
      </span>
      <span className="d-none d-sm-inline-block">
        <Dropdown.Toggle as="a" className="nav-link">
          {/* <img
            src={avatar}
            className="avatar img-fluid rounded-circle me-1"
            alt="Chris Wood"
          /> */}
          <span className="text-dark">
            {user?.name ? user.name : "Welcome "}
          </span>
        </Dropdown.Toggle>
      </span>
      <Dropdown.Menu drop="end">
        {/* <Dropdown.Item>
          <User size={18} className="align-middle me-2" />
          Profile
        </Dropdown.Item>
        <Dropdown.Item>
          <PieChart size={18} className="align-middle me-2" />
          Analytics
        </Dropdown.Item>
        <Dropdown.Divider /> 
         <Dropdown.Item onClick={() => (window.location = "/profile")}>
          Settings
        </Dropdown.Item> 
        <Dropdown.Item>Help</Dropdown.Item>*/}
        <Dropdown.Item onClick={() => (window.location = "/logged-out")}>
          Sign In
        </Dropdown.Item>
        <Dropdown.Item onClick={() => (window.location = "/sign-up")}>
          Sign Up
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default NavbarUserLoggedOut;
