import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

import {
  Col,
  Row,
  Card,
  Button,
  Tab,
  ButtonGroup,
  Modal,
} from "react-bootstrap";

import UIDefaultPage from "../../components/ui/UIDefaultPage";
import CronJobsTable from "./CronJobsTable";
import CronJobCard from "./CronJobLogCard";
const HOST_URL = process.env.REACT_APP_HOST_URL;

const CronJobsPage = () => {
  const [cronJobs, setCronJobs] = useState();
  const [currentCronJob, setCurrentCronJob] = useState();
  const myRef = useRef();

  useEffect(() => {
    const fetchData = async () => {
      const data = await axios.get(`${HOST_URL}/utils/cronJobStatuses`);
      const cronJobsList = data.data;
      setCronJobs(cronJobsList);
    };

    fetchData();
  }, []);

  const MainTable = () => {
    return (
      <>
        <Card>
          <Card.Body>
            {cronJobs ? (
              <>
                <CronJobsTable
                  cronJobs={cronJobs}
                  setCurrentCronJob={setCurrentCronJob}
                  myRef={myRef}
                />
              </>
            ) : (
              <div className="text-center" role="status">
                <span className="">Loading...</span>
              </div>
            )}
          </Card.Body>
        </Card>
      </>
    );
  };

  const SideCard = () => {
    return <CronJobCard cronJobType={currentCronJob} />;
  };

  const children = (
    <>
      <Row>
        <Col xl={8}>
          <MainTable />
        </Col>

        <Col xl={4}>
          <SideCard />
        </Col>
      </Row>
    </>
  );

  return (
    <UIDefaultPage
      title="Cron Jobs"
      //this will need work when implemented
      // childCardBodies={infoCards}
      children={children}
    />
  );
};

export default CronJobsPage;
