import axios from "axios";
import React, { useEffect, useState, createContext, useMemo } from "react";

import {
  Badge,
  Button,
  Card,
  Col,
  Fade,
  ListGroup,
  Row,
} from "react-bootstrap";
import { ArrowUp } from "react-feather";

import ActiveVendorAccounts from "./ActiveVendorAccounts";
import PendingVendorAccounts from "./PendingVendorAccounts";
import UserAccounts from "./UserAccounts";
import Stores from "./Stores";

const HOST_URL = process.env.REACT_APP_HOST_URL;

export const AccountsContext = createContext({
  setUsers: () => {},
  setStores: () => {},
});

const Navigation = ({ activeTab, setActiveTab, pendingVendorAccounts }) => {
  return (
    <Card>
      <ListGroup horizontal>
        <ListGroup.Item
          tag="a"
          onClick={() => setActiveTab("activeVendorAccounts")}
          action
          active={activeTab === "activeVendorAccounts"}
        >
          Active Vendor Accounts
        </ListGroup.Item>
        <ListGroup.Item
          tag="a"
          onClick={() => setActiveTab("pendingVendorAccounts")}
          active={activeTab === "pendingVendorAccounts"}
          action
        >
          <div className="d-flex align-items-center justify-content-between">
            <span>Pending Vendor Accounts</span>
            <Badge bg="warning">
              {
                pendingVendorAccounts?.filter(
                  (account) => account.status === "PENDING"
                ).length
              }
            </Badge>
          </div>
        </ListGroup.Item>
        <ListGroup.Item
          tag="a"
          onClick={() => setActiveTab("userAccounts")}
          action
          active={activeTab === "userAccounts"}
        >
          User Accounts
        </ListGroup.Item>
        <ListGroup.Item
          tag="a"
          onClick={() => setActiveTab("stores")}
          action
          active={activeTab === "stores"}
        >
          Stores
        </ListGroup.Item>
      </ListGroup>
    </Card>
  );
};

const Accounts = () => {
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [activeTab, setActiveTab] = useState("activeVendorAccounts");
  const [activeVendors, setActiveVendors] = useState([]);
  const [pendingVendors, setPendingVendors] = useState([]);
  const [users, setUsers] = useState([]);
  const [stores, setStores] = useState([]);

  const accountsContext = useMemo(
    () => ({
      setUsers,
      setStores,
    }),
    [setUsers, setStores]
  );

  useEffect(() => {
    const getAllAccounts = async () => {
      const response = (await axios.get(`${HOST_URL}/utils/allAccounts`)).data
        .data;

      setActiveVendors(response.activeVendorAccounts);
      setPendingVendors(response.pendingVendorAccounts);
      setUsers(response.userAccounts);
      setStores(response.stores);
    };

    getAllAccounts();
  }, []);

  const toggleScrollButton = () => {
    const scrolled = document.documentElement.scrollTop;
    scrolled > 0 ? setShowScrollButton(true) : setShowScrollButton(false);
  };
  window.addEventListener("scroll", toggleScrollButton);

  const getPendingVendors = async () => {
    const response = (await axios.get(`${HOST_URL}/auth/getPendingAccounts`))
      .data.pendingAccounts;

    setPendingVendors(response);
  };

  const renderActiveTab = () => {
    switch (activeTab) {
      case "activeVendorAccounts":
        return (
          <ActiveVendorAccounts
            activeVendorAccounts={activeVendors}
            setActiveVendors={setActiveVendors}
            stores={stores}
            users={users}
          />
        );
      case "pendingVendorAccounts":
        return (
          <PendingVendorAccounts
            pendingVendorAccounts={pendingVendors}
            getPendingVendorAccounts={getPendingVendors}
          />
        );
      case "userAccounts":
        return <UserAccounts users={users} setUsers={setUsers} />;
      case "stores":
        return <Stores stores={stores} setStores={setStores} />;
      default:
        return null;
    }
  };

  return (
    <AccountsContext.Provider value={accountsContext}>
      <Row>
        <Col xxl={8}>
          <Navigation
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            pendingVendorAccounts={pendingVendors}
          />
        </Col>
      </Row>
      {renderActiveTab()}
      <div className="d-flex justify-content-center sticky-bottom">
        <Fade in={showScrollButton}>
          <Button
            className="d-flex justify-content-end"
            style={{ display: showScrollButton ? "inline" : "none" }}
            size="sm"
            variant="light"
            title="Back to Top"
            onClick={() => window.scrollTo(0, 0)}
          >
            <ArrowUp size={20} />
          </Button>
        </Fade>
      </div>
    </AccountsContext.Provider>
  );
};

export default Accounts;
