import AmazonASINLink from "../../components/AmazonASINLink";
import {
  DateRangeFilter,
  dateBetweenFilterFn,
} from "../../components/table/DateRangeFilter";
import imageNotFound from "../../assets/img/imageNotFound.png";

const shipmentColumns = [
  {
    Header: "Removal Order ID",
    accessor: "orderId",
  },
  {
    Header: "Tracking Number",
    accessor: "trackingNumber",
  },
  {
    Header: "Request Date",
    id: "requestDate",
    sortType: "datetime",
    Cell: ({ value }) => {
      return <span>{new Date(value).toLocaleDateString()}</span>;
    },
    accessor: ({ requestDate }) => new Date(requestDate),
    Filter: DateRangeFilter,
    filter: dateBetweenFilterFn,
  },

  {
    Header: "Shipment Date",
    id: "shipmentDate",
    sortType: "datetime",
    Cell: ({ value }) => {
      return <span>{new Date(value).toLocaleDateString()}</span>;
    },
    accessor: ({ shipmentDate }) => new Date(shipmentDate),
    Filter: DateRangeFilter,
    filter: dateBetweenFilterFn,
  },
  {
    Header: "Quantity",
    accessor: "totalShippedQuantity",
  },
];

const shipmentItemColumns = [
  {
    Header: "Tracking Number",
    accessor: "trackingNumber",
  },
  {
    Header: "Request Date",
    id: "requestDate",
    sortType: "datetime",
    Cell: ({ value }) => {
      return <span>{new Date(value).toLocaleDateString()}</span>;
    },
    accessor: ({ requestDate }) => new Date(requestDate),
    Filter: DateRangeFilter,
    filter: dateBetweenFilterFn,
  },

  {
    Header: "Shipment Date",
    id: "shipmentDate",
    sortType: "datetime",
    Cell: ({ value }) => {
      return <span>{new Date(value).toLocaleDateString()}</span>;
    },
    accessor: ({ shipmentDate }) => new Date(shipmentDate),
    Filter: DateRangeFilter,
    filter: dateBetweenFilterFn,
  },
  {
    Header: "Removal Order ID",
    accessor: "orderId",
  },
  {
    id: "image",
    Header: "Product Image",
    accessor: (row) => (
      <a
        href={`https://www.amazon.com/dp/${row.asin}?th=1&psc=1`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          className="img-thumbnail"
          style={{ maxHeight: "75px" }}
          src={`https://ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=${row.asin}&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=SL250`}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = imageNotFound;
          }}
          alt="Item"
        />
      </a>
    ),
  },
  {
    Header: "ASIN",
    accessor: "asin",
    Cell: ({ value }) => <AmazonASINLink ASIN={value} />,
  },
  {
    Header: "SKU",
    accessor: "sku",
  },
  {
    Header: "Disposition",
    accessor: "disposition",
  },

  {
    Header: "Quantity",
    accessor: "shippedQuantity",
  },
];

export { shipmentColumns, shipmentItemColumns };
