import { useState, useEffect } from "react";
import axios from "axios";
import Select from "react-select";
import { Card, Form, Button } from "react-bootstrap";
import { Copy } from "react-feather";
import { CopyToClipboard } from "react-copy-to-clipboard";
import toast from "react-hot-toast";
import UIDefaultPage from "../../components/ui/UIDefaultPage";

const HOST_URL = process.env.REACT_APP_HOST_URL;

const AmazonLinkGeneratorPage = () => {
  const AmazonLinkGenerator = () => {
    const [vendors, setVendors] = useState([]);
    const [vendorOptions, setVendorOptions] = useState([]);
    const [selectedVendor, setSelectedVendor] = useState(null);
    const [email, setEmail] = useState();
    const [generatedLink, setGeneratedLink] = useState();
    const [isCopied, setIsCopied] = useState(false);

    useEffect(() => {
      const getVendors = async () => {
        try {
          const vendorDataResponse = (
            await axios.get(`${HOST_URL}/utils/vendors`)
          ).data.data;

          const vendorOptions = vendorDataResponse.map((vendor) => {
            return {
              value: vendor.vendorId,
              label: vendor.name,
            };
          });

          setVendors(vendorDataResponse);
          setVendorOptions(vendorOptions);
        } catch (error) {
          console.log(error);
        }
      };
      getVendors();
    }, []);

    const handleVendorSelect = (selectedValue) => {
      setSelectedVendor(selectedValue);

      // default the email field to selected vendor's email, if exists:
      const selectedVendorEmail = vendors.find((vendor) => {
        return vendor.vendorId === selectedValue.value;
      })?.email;

      setEmail(selectedVendorEmail || "");
    };

    const validateEmailAddress = (email) => {
      return email.match(/^\S+@\S+$/);
    };

    const handleSubmit = async () => {
      // first make sure email is valid:
      const validEmail = validateEmailAddress(email);
      if (!validEmail) {
        toast.error("Email address is invalid. ");
        return;
      }

      try {
        // call BE route with vendorId and email to generate link and send email
        const generatedLinkResponse = (
          await axios.post(`${HOST_URL}/utils/createAmazonLinkRequest`, {
            vendorId: selectedVendor.value,
            email,
          })
        ).data.amazonRedirectPageLink;

        // set the link state value so user can view it and copy it:
        setGeneratedLink(generatedLinkResponse);

        toast.success("Link generated and email sent!");
      } catch (error) {
        console.log(error);
        toast.error("Unable to send email.");
      }
    };

    const handleCopy = () => {
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 1000);
    };

    return (
      <Card>
        {!generatedLink ? (
          <Card.Body className="w-25 m-auto">
            <Select
              className="react-select-container mt-2 mb-3"
              classNamePrefix="react-select"
              placeholder="Select a Vendor"
              onChange={handleVendorSelect}
              options={vendorOptions}
              isSearchable
              value={selectedVendor}
            />
            {selectedVendor && (
              <>
                <Form.Label>
                  Enter the email address for the link to be sent to:
                </Form.Label>
                <Form.Control
                  className="mb-3"
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </>
            )}
            {email && (
              <div className="text-center mt-4 mb-2">
                <Button onClick={handleSubmit}>
                  Generate Link and Send Email
                </Button>
              </div>
            )}
          </Card.Body>
        ) : (
          <Card.Body className="m-auto">
            <div>
              <p className="mb-2 font-weight-bold">Amazon Redirect Link:</p>
              <span className="bg-light p-2">{generatedLink}</span>
              <CopyToClipboard text={generatedLink} onCopy={handleCopy}>
                <Button className="ms-3" title="Copy to clipboard">
                  <span>{isCopied ? "Copied!" : <Copy size={14} />}</span>
                </Button>
              </CopyToClipboard>
              <div className="d-flex mt-3 mb-2">
                <p className="font-weight-bold me-2">
                  An email with this link was sent to:
                </p>
                <p>{email}</p>
              </div>
            </div>
          </Card.Body>
        )}
      </Card>
    );
  };

  const children = <AmazonLinkGenerator />;
  return <UIDefaultPage title="Amazon Link Generator" children={children} />;
};

export default AmazonLinkGeneratorPage;
