import {
  DateRangeFilter,
  dateBetweenFilterFn,
} from "../../components/table/DateRangeFilter";

const salesActivityColumns = [
  {
    Header: "Month",
    Cell: ({ value }) => {
      console.log(value);
      return <span>{new Date(value).toISOString().split("T")[0]}</span>;
    },
    accessor: ({ invoiceMonth }) => {
      return new Date(invoiceMonth).toISOString().split("T")[0];
    },
    id: "invoiceMonth",
    Filter: DateRangeFilter,
    filter: dateBetweenFilterFn,
  },
  {
    Header: "Invoice Number",
    accessor: "invoiceId",
  },
  {
    Header: "Payout",
    accessor: "payout",
  },
];
export default salesActivityColumns;
