import React, { useState, useMemo } from "react";

import { Row, Col, Card, Badge } from "react-bootstrap";
import AccountTable from "./AccountTable";
import PendingAccountCard from "./PendingVendorAccountCard";
import { scrollHelper } from "../../utils/helper";

function PendingAccounts({ pendingVendorAccounts, getPendingVendorAccounts }) {
  const [selectedAccount, setSelectedAccount] = useState(null);

  const getStatusBadge = (status) => {
    switch (status) {
      case "DENIED":
        return <Badge bg="danger">Denied</Badge>;
      case "APPROVED":
        return <Badge bg="success">Approved</Badge>;
      default:
        return <Badge bg="warning">Pending</Badge>;
    }
  };

  const columns = useMemo(() => [
    {
      Header: "Vendor Name",
      accessor: "vendorName",
    },
    {
      Header: "Name",
      accessor: (row) => `${row.firstName} ${row.lastName}`,
    },
    {
      Header: "Phone Number",
      accessor: "phone",
    },
    {
      Header: "Email Address",
      accessor: "email",
    },
    {
      Header: "Pending Since",
      accessor: (row) => new Date(row.createdAt).toLocaleDateString(),
    },
    {
      Header: "Status",
      accessor: (row) => getStatusBadge(row.status),
    },
  ]);

  const accountRowClickHandler = (row) => {
    setSelectedAccount(row);

    //window.innderWidth should match the bootstrap breakpoint
    scrollHelper("xl");
  };

  return (
    <Row>
      <Col xl={8}>
        <Card>
          <Card.Body>
            <AccountTable
              columns={columns}
              data={pendingVendorAccounts}
              onClickFn={accountRowClickHandler}
              clickableRows={true}
            />
          </Card.Body>
        </Card>
      </Col>
      <Col xl={4}>
        <PendingAccountCard
          account={selectedAccount}
          getPendingVendorAccounts={getPendingVendorAccounts}
          isPending={selectedAccount?.status === "PENDING"}
        />
      </Col>
    </Row>
  );
}

export default PendingAccounts;
