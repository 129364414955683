import React from "react";
import { Helmet } from "react-helmet-async";
import { Container, Row, Col } from "react-bootstrap";

const Default = () => (
  <React.Fragment>
    <Helmet title="Home Page" />
    <Container fluid className="p-0">
      Hello
    </Container>
  </React.Fragment>
);

export default Default;
