import AmazonASINLink from "../../components/AmazonASINLink";
import {
  DateRangeFilter,
  dateBetweenFilterFn,
} from "../../components/table/DateRangeFilter";
import imageNotFound from "../../assets/img/imageNotFound.png";

const shipmentTableColumns = [
  {
    Header: "Tracking Number",
    accessor: "trackingNumber",
  },
  {
    Header: "Received Date:",
    id: "receivedDate",
    sortType: "datetime",
    Cell: ({ value }) => {
      return <span>{new Date(value).toLocaleDateString()}</span>;
    },
    accessor: ({ receivedDate }) => new Date(receivedDate),
    Filter: DateRangeFilter,
    filter: dateBetweenFilterFn,
  },
];

const shipmentItemTableColumns = [
  {
    id: "image",
    Header: "Product Image",
    accessor: (row) => (
      <a
        href={`https://www.amazon.com/dp/${row.asin}?th=1&psc=1`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          className="img-thumbnail"
          style={{ maxHeight: "75px" }}
          src={`https://ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=${row.asin}&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=SL250`}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = imageNotFound;
          }}
          alt="Item"
        />
      </a>
    ),
  },
  {
    Header: "ASIN",
    accessor: "asin",
    Cell: ({ value }) => <AmazonASINLink ASIN={value} />,
  },
  {
    Header: "Title",
    accessor: "itemName",
  },
  {
    Header: "Latest Processed Stage",
    accessor: "latestProcessingStage",
  },
  {
    Header: "Latest Processed Date",
    id: "latestProcessingDate",
    sortType: "datetime",
    Cell: ({ value }) => {
      return <span>{new Date(value).toLocaleDateString()}</span>;
    },
    accessor: ({ latestProcessingDate }) => new Date(latestProcessingDate),
    Filter: DateRangeFilter,
    filter: dateBetweenFilterFn,
  },
  {
    Header: "Condition",
    accessor: "condition",
  },
  {
    Header: "Listing Store",
    accessor: "listingStore",
  },
];

const itemTableColumns = [
  {
    id: "image",
    Header: "Product Image",
    accessor: (row) => (
      <a
        href={`https://www.amazon.com/dp/${row.asin}?th=1&psc=1`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          className="img-thumbnail"
          style={{ maxHeight: "75px" }}
          src={`https://ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=${row.asin}&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=SL250`}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = imageNotFound;
          }}
          alt="Item"
        />
      </a>
    ),
  },
  {
    Header: "ASIN",
    accessor: "asin",
    Cell: ({ value }) => <AmazonASINLink ASIN={value} />,
  },
  {
    Header: "Title",
    accessor: "itemName",
  },
  {
    Header: "Tracking Number",
    accessor: "trackingNumber",
  },
  {
    Header: "Received Date",
    id: "receivedDate",
    sortType: "datetime",
    Cell: ({ value }) => {
      return <span>{new Date(value).toLocaleDateString()}</span>;
    },
    accessor: ({ receivedDate }) => new Date(receivedDate),
    Filter: DateRangeFilter,
    filter: dateBetweenFilterFn,
  },
  {
    Header: "Latest Processed Stage",
    accessor: "latestProcessingStage",
  },
  {
    Header: "Latest Processed Date",
    id: "latestProcessingDate",
    sortType: "datetime",
    Cell: ({ value }) => {
      return <span>{new Date(value).toLocaleDateString()}</span>;
    },
    accessor: ({ latestProcessingDate }) => new Date(latestProcessingDate),
    Filter: DateRangeFilter,
    filter: dateBetweenFilterFn,
  },
  {
    Header: "Condition",
    accessor: "condition",
  },
  {
    Header: "Listing Store",
    accessor: "listingStore",
  },
];

export { shipmentTableColumns, shipmentItemTableColumns, itemTableColumns };
