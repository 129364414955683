import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";

const HOST_URL = process.env.REACT_APP_HOST_URL;
const SELLING_PARTNER_APP_REDIRECT_URI =
  process.env.REACT_APP_SELLING_PARTNER_APP_REDIRECT_URI;

const AmazonRedirect = () => {
  const { requestId } = useParams();
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    if (!requestId) {
      window.location = "/";
    }
  }, [requestId]);

  const validateRequestIdAndRedirect = async () => {
    try {
      // check requestId exists in the BE:
      const response = (
        await axios.get(`${HOST_URL}/utils/checkRequestIdExists/${requestId}`)
      ).data;

      const REDIRECT_URL = `https://sellercentral.amazon.com/apps/authorize/consent?application_id=amzn1.sellerapps.app.28bffaaa-5875-48b5-baf2-6495578338b9&redirect_uri=${SELLING_PARTNER_APP_REDIRECT_URI}&state=${response.vendorId}`;
      window.location.replace(REDIRECT_URL);
    } catch (error) {
      if (error.response.status === 400) {
        setErrorMessage("Request Id does not exist.");
      }
      setShowError(true);
      setTimeout(() => {
        window.location = "/";
      }, 3000);
    }
  };

  validateRequestIdAndRedirect();

  return (
    <>{showError && <h3>Unable to redirect to Amazon. {errorMessage}</h3>}</>
  );
};

export default AmazonRedirect;
