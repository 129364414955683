import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

import {
  Col,
  Row,
  Card,
  Button,
  Tab,
  ButtonGroup,
  Modal,
} from "react-bootstrap";

import UIDefaultPage from "../../components/ui/UIDefaultPage";
import AmazonReportsTable from "./AmazonReportsTable";
import ReportCard from "./ReportLogCard";
import NewReportCard from "./NewReportCard";
import ReportForm from "./ReportForm";
import StoreSelect from "../../components/admin/StoreSelect";
const HOST_URL = process.env.REACT_APP_HOST_URL;

const AmazonReportsPage = () => {
  const [selectedStore, setSelectedStore] = useState();
  const [amazonReports, setAmazonReports] = useState();
  const [currentReport, setCurrentReport] = useState("");
  const [newReportData, setNewReportData] = useState({});
  const [modal, setModal] = useState({ show: false });
  const myRef = useRef();
  const needStore = !selectedStore;

  const fetchData = async () => {
    const data = await axios.get(
      `${HOST_URL}/reports/getReportsStatuses?storeId=${selectedStore.value}`
    );
    const recentReports = data.data;
    setAmazonReports(recentReports);
    setCurrentReport("");
  };
  useEffect(() => {
    if (needStore) {
      // look for storeId and storeName in the page URL:
      const storeId = new URLSearchParams(window.location.search).get(
        "storeId"
      );
      const storeName = new URLSearchParams(window.location.search).get(
        "storeName"
      );
      if (storeId && storeName) {
        setSelectedStore({ value: storeId, label: storeName });
      }
    }

    if (!needStore) {
      fetchData();
    }
  }, [selectedStore]);

  const createReport = async (reportType, dateRange) => {
    const result = await axios.post(`${HOST_URL}/reports/createAmazonReports`, {
      storeIds: [selectedStore.value],
      reportNames: [reportType],
      dateRange,
    });

    fetchData();
    return result;
  };

  // clear all fetched data so the loader will show when new store is selected:
  const clearDataHandler = () => {
    setAmazonReports(null);
    setNewReportData();
  };

  const SelectStore = () => {
    return (
      <Row className="mb-3">
        <Col xl={2} md={4}>
          <StoreSelect
            selectedStore={selectedStore}
            setSelectedStore={setSelectedStore}
            clearData={clearDataHandler}
          />
        </Col>
      </Row>
    );
  };

  const CreateReportModal = () => {
    return (
      <>
        <Button
          variant="primary"
          onClick={() => {
            setModal({ show: true });
          }}
          className="mb-2"
        >
          Create Report
        </Button>
        <Modal
          size="lg"
          show={modal.show}
          onHide={() => {
            setModal({ show: false });
          }}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title className="text-center w-100">
              Create Report
              <br />
              for {selectedStore.label}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="w-50 m-auto">
            <ReportForm
              store={selectedStore}
              setModal={setModal}
              createReport={createReport}
            />
          </Modal.Body>
        </Modal>
      </>
    );
  };

  const MainTable = () => {
    return (
      <>
        <Card>
          {needStore ? (
            <Card.Body className="text-center">
              <h3>Please choose a store to view data</h3>
            </Card.Body>
          ) : (
            <Card.Body>
              {amazonReports ? (
                <>
                  <div className="d-flex justify-content-end">
                    <CreateReportModal />
                  </div>

                  <AmazonReportsTable
                    amazonReports={amazonReports}
                    setCurrentReport={setCurrentReport}
                    fetchData={fetchData}
                    storeId={selectedStore.value}
                    setNewReportData={setNewReportData}
                    myRef={myRef}
                  />
                </>
              ) : (
                <div className="text-center" role="status">
                  <span className="">Loading...</span>
                </div>
              )}
            </Card.Body>
          )}
        </Card>
      </>
    );
  };

  const SideCard = () => {
    return (
      <ReportCard store={selectedStore || ""} reportType={currentReport} />
    );
  };

  const NewReport = () => {
    return <NewReportCard myRef={myRef} newReportData={newReportData} />;
  };

  const children = (
    <>
      <SelectStore />
      <Row>
        <Col xl={8}>
          <MainTable />
        </Col>

        <Col xl={4}>
          <SideCard />
        </Col>
      </Row>
      <Row>{newReportData?.downloadedReport && <NewReport />}</Row>
    </>
  );

  return (
    <UIDefaultPage
      title="Amazon Reports"
      //this will need work when implemented
      // childCardBodies={infoCards}
      children={children}
    />
  );
};

export default AmazonReportsPage;
