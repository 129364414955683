import React, { useState, useEffect } from "react";
import axios from "axios";

import { Card, Col } from "react-bootstrap";

import UITablePage from "../../components/ui/UITablePage";
import Table from "../../components/table/Table";
import AdminFilters from "../../components/admin/AdminFilters";

import Loader from "../../components/Loader";

import baseColumns from "./tableColumns";
import { needStoreNameColumn } from "../../utils/helper";

const HOST_URL = process.env.REACT_APP_HOST_URL;

const ListingsPage = () => {
  const [listings, setListings] = useState();
  const [alias, setAlias] = useState();
  const { userId, isAdmin } = JSON.parse(localStorage.getItem("user"));
  //get vendorId from local storage
  const vendorId = JSON.parse(localStorage.getItem("vendor"))?.vendorId;

  const needAlias = isAdmin && !alias;

  const fetchData = async () => {
    const data = (
      await axios.get(
        `${HOST_URL}/getData/${userId}/listings?vendorId=${vendorId}${
          alias ? `&aliasType=${alias.type}&aliasValue=${alias.value}` : ""
        }`
      )
    ).data.data;

    setListings(data);
  };

  useEffect(() => {
    if (!needAlias) {
      fetchData();
    }
  }, [userId, alias]);

  let columns = baseColumns;
  // if (listings && needStoreNameColumn(listings)) {
  //   columns = [{ Header: "Store", accessor: "storeName" }, ...baseColumns];
  // }

  // admins only - clear fetched data so the loader will show when new vendor is selected:
  const clearDataHandler = () => {
    setListings(null);
  };

  const ListingsTable = () => {
    return (
      <>
        {isAdmin && (
          <AdminFilters
            alias={alias}
            setAlias={setAlias}
            clearData={clearDataHandler}
          />
        )}
        <Col>
          <Card>
            <Card.Body>
              {listings ? (
                <Table columns={columns} data={listings} pageName="Listings" />
              ) : needAlias ? (
                <h3 className="text-center">
                  Please choose a vendor to view data
                </h3>
              ) : (
                <Loader />
              )}
            </Card.Body>
          </Card>
        </Col>
      </>
    );
  };

  const childTable = <ListingsTable />;
  return (
    <>{<UITablePage title="Inventory/Listings" children={childTable} />}</>
  );
};

export default ListingsPage;
