import { faFileCsv } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { CSVLink } from "react-csv";

const DownloadCSV = ({ headers, data, pageName, filteredBy }) => {
  const vendorName = JSON.parse(localStorage.getItem("vendor"))?.name;

  //remove any commas from the data
  const cleanData = data.map((row) => {
    const newRow = {};
    Object.keys(row).forEach((key) => {
      if (typeof row[key] === "string") {
        newRow[key] = row[key].replace(/[,"]/g, "");
      } else {
        newRow[key] = row[key];
      }
    });
    return newRow;
  });

  return (
    <CSVLink
      title="Download CSV"
      headers={headers}
      data={cleanData}
      filename={`${
        vendorName ? vendorName.replaceAll(" ", "") + "_" : ""
      }${pageName}${filteredBy ? "_" + filteredBy : ""}_${moment().format(
        "YYYYMMDD"
      )}.csv`}
    >
      <FontAwesomeIcon icon={faFileCsv} size="3x" />
    </CSVLink>
  );
};
export default DownloadCSV;
