function getURLSearchParams(urlQuery, keys) {
  const searchParams = new URLSearchParams(urlQuery);
  if (!Array.isArray(keys)) {
    return searchParams.get(keys);
  }
  const params = [];
  keys.forEach((key) => {
    params.push(searchParams.get(key));
  });
  return params;
}

function updateLocalStorage(key, value) {
  localStorage.setItem(key, JSON.stringify(value));
}

function needStoreNameColumn(data) {
  //check if there is more than one storeId
  const storeIds = data.map((item) => item.storeId);
  const uniqueStoreIds = [...new Set(storeIds)];

  if (uniqueStoreIds.length > 1) {
    return true;
  }
  return false;
}

/**
 * Returns a randomly generated string using digits, special characters,
 * uppercase and lowercase letters.
 *
 * @param {number} [passwordLength = 12] - The length of the password
 * (defaults to 12).
 *
 * @returns {string} password - A random string.
 */
const generatePassword = (passwordLength = 12) => {
  const chars =
    "0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ";

  let password = "";

  for (let i = 0; i < passwordLength; i++) {
    password += chars.charAt(Math.floor(Math.random() * chars.length));
  }

  return password;
};

/**
 * Scrolls the window to the bottom if the window's inner width
 * is smaller than the boostrap breakpoint.
 *
 * @param {string} breakpoint - The bootstrap breakpoint.
 */
const scrollHelper = (breakpoint) => {
  switch (breakpoint) {
    case "sm":
      breakpoint = 576;
      break;
    case "md":
      breakpoint = 768;
      break;
    case "lg":
      breakpoint = 992;
      break;
    case "xl":
      breakpoint = 1200;
      break;
    case "xxl":
      breakpoint = 1400;
      break;
    default:
      breakpoint = 576;
  }

  if (window.innerWidth <= breakpoint) {
    window.scrollTo(0, document.getElementsByTagName("body")[0].scrollHeight);
  } else {
    window.scrollTo(0, 0);
  }
};

export const handleError = (error) => {
  if (error.response) {
    // The request was made and the server responded with a status code that falls out of the range of 2xx
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);
  } else if (error.request) {
    // The request was made but no response was received
    console.log(error.request);
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log("Error", error.message);
  }
  console.log(error.config);
};

export {
  getURLSearchParams,
  updateLocalStorage,
  needStoreNameColumn,
  generatePassword,
  scrollHelper,
};
