import React, { useState } from "react";

import { Alert, Button, Card, Col, Form, Row } from "react-bootstrap";

import * as Yup from "yup";
import { Formik } from "formik";
import axios from "axios";
import { updateLocalStorage } from "../../utils/helper";

import InputMask from "react-input-mask";
import toast from "react-hot-toast";

const HOST_URL = process.env.REACT_APP_HOST_URL;

// import Select from "react-select";
// import usaStates from "../../utils/usaStates";

// Style for the react-select to match bootstrap
// const customStyles = {
//   control: (provided, state) => ({
//     ...provided,
//     minHeight: "32px",
//     height: "32px",
//     boxShadow: state.isFocused ? null : null,
//   }),

//   valueContainer: (provided, state) => ({
//     ...provided,
//     height: "32px",
//     padding: "0 6px",
//   }),

//   input: (provided, state) => ({
//     ...provided,
//     margin: "0px",
//   }),

//   indicatorSeparator: (state) => ({
//     display: "none",
//   }),

//   indicatorsContainer: (provided, state) => ({
//     ...provided,
//     height: "32px",
//   }),
// };

const CompanyDetails = () => {
  const vendor = JSON.parse(localStorage.getItem("vendor"));

  // const initialState = {
  //   vendorName: vendor.name || "",
  //   phoneNumber: vendor.phone || "",
  //   email: vendor.email || "",
  //   address: vendor.address || "",
  //   address2: vendor.address2 || "",
  //   city: vendor.city || "",
  //   state: vendor.state || "",
  //   zip: vendor.zip || "",
  // };

  // const [state, setState] = useState(initialState);

  /*
  This is for the USA States Select
  const [usState, setUsState] = useState({
    value: vendor.state,
    label: vendor.state,
  });
  */

  // const handleChange = (event) => {
  //   const value = event.target.value;

  //   setState({
  //     ...state,
  //     [event.target.name]: value,
  //   });
  // };

  return (
    <Card>
      <Card.Header>
        <Card.Title tag="h5" className="mb-0">
          <h3>{vendor.name}</h3>
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Formik
          initialValues={{
            vendorName: vendor.name || "",
            phone: vendor.phone || "",
            email: vendor.email || "",
            address: vendor.address || "",
            // address2: vendor.address2 || "",
            city: vendor.city || "",
            state: vendor.state || "",
            zip: vendor.zip || "",
            country: vendor.country || "",
            vendorId: vendor.vendorId,
            submit: false,
          }}
          validationSchema={Yup.object().shape({
            vendorName: Yup.string()
              .max(255)
              .required("Company Name is required."),
            phone: Yup.string().max(255).required("Phone Number is required."),
            email: Yup.string()
              .email("Email Address must be valid.")
              .max(100)
              .required("Email Address is required."),
            address: Yup.string().max(255).required("Address is required."),
            zip: Yup.string().max(255).required("ZIP Code is required."),
          })}
          onSubmit={async (
            values,
            { setErrors, setStatus, setSubmitting, resetForm }
          ) => {
            try {
              const updatedVendor = (
                await axios.post(
                  `${HOST_URL}/management/updateCompanyDetails`,
                  values
                )
              ).data.updatedVendor;
              updateLocalStorage("vendor", updatedVendor);
              toast.success("Company details updated!");
            } catch (error) {
              resetForm();
              console.log(error.response.data);
              const message =
                error.response?.data?.message || "Something went wrong";

              setStatus({ success: false });
              setErrors({ submit: message });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              {errors.submit && (
                <Alert className="my-3" variant="danger">
                  <div className="alert-message">{errors.submit}</div>
                </Alert>
              )}
              {/* <Form.Group className="mb-3">
                <Form.Label htmlFor="vendorName">Company Name</Form.Label>
                <Form.Control
                  type="text"
                  name="vendorName"
                  id="vendorName"
                  placeholder="Company Name"
                  value={values.vendorName}
                  isInvalid={Boolean(touched.vendorName && errors.vendorName)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {!!touched.name && (
                  <Form.Control.Feedback type="invalid">
                    {errors.vendorName}
                  </Form.Control.Feedback>
                )}
              </Form.Group> */}
              <Row>
                <Col md={2}>
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="phone">Phone Number</Form.Label>
                    <Form.Control
                      type="text"
                      name="phone"
                      id="phone"
                      placeholder="Phone Number"
                      value={values.phone}
                      isInvalid={Boolean(touched.phone && errors.phone)}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {!!touched.phone && (
                      <Form.Control.Feedback type="invalid">
                        {errors.phone}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="email">Email Address</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      id="email"
                      placeholder="example@example.com"
                      value={values.email}
                      isInvalid={Boolean(touched.email && errors.email)}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {!!touched.email && (
                      <Form.Control.Feedback type="invalid">
                        {errors.email}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="address">Address</Form.Label>
                    <Form.Control
                      type="text"
                      name="address"
                      id="address"
                      placeholder="1234 Main St"
                      value={values.address}
                      isInvalid={Boolean(touched.address && errors.address)}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {!!touched.address && (
                      <Form.Control.Feedback type="invalid">
                        {errors.address}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="city">City</Form.Label>
                    <Form.Control
                      type="text"
                      name="city"
                      id="city"
                      value={values.city}
                      placeholder="City"
                      isInvalid={Boolean(touched.city && errors.city)}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {!!touched.city && (
                      <Form.Control.Feedback type="invalid">
                        {errors.city}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Col>
              </Row>

              {/* Everything beyond this is for the full Address */}
              {/* <Form.Group className="mb-3">
                <Form.Label htmlFor="address2">Address 2</Form.Label>
                <Form.Control
                  type="text"
                  name="address2"
                  id="address2"
                  value={values.address2}
                  placeholder="Apartment, studio, or floor"
                  onChange={handleChange}
                />
              </Form.Group> */}
              <Row>
                <Col md={4}>
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="state">State</Form.Label>
                    <Form.Control
                      type="text"
                      name="state"
                      id="state"
                      placeholder="State"
                      value={values.state}
                      isInvalid={Boolean(touched.state && errors.state)}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {!!touched.state && (
                      <Form.Control.Feedback type="invalid">
                        {errors.state}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Col>
                <Col md={2}>
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="zip">ZIP Code</Form.Label>
                    <Form.Control
                      type="text"
                      name="zip"
                      id="zip"
                      placeholder="ZIP Code"
                      value={values.zip}
                      isInvalid={Boolean(touched.zip && errors.zip)}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {!!touched.zip && (
                      <Form.Control.Feedback type="invalid">
                        {errors.zip}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Col>
                {/* <Col md={2}>
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="zipcode">Zip</Form.Label>
                    <Form.Control
                      type="text"
                      name="zip"
                      id="zipcode"
                      value={values.zip}
                      placeholder="XXXXX"
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col> */}
                <Col md={4}>
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="address">Country</Form.Label>
                    <Form.Control
                      type="text"
                      name="country"
                      id="country"
                      placeholder="Country"
                      value={values.country}
                      isInvalid={Boolean(touched.country && errors.country)}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {!!touched.country && (
                      <Form.Control.Feedback type="invalid">
                        {errors.country}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Col>
              </Row>

              <Button variant="success" type="submit" disabled={isSubmitting}>
                Save Changes
              </Button>
            </Form>
          )}
        </Formik>
      </Card.Body>
    </Card>
  );
};

export default CompanyDetails;
