import React, { useState, useEffect } from "react";
import axios from "axios";

import LpnForm from "./LpnForm";
import LpnList from "./LpnList";
import LpnItem from "./LpnItem";

const HOST_URL = process.env.REACT_APP_HOST_URL;

function ExtLpnScan() {
  const [scannedLpns, setScannedLpns] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentItem, setCurrentItem] = useState();

  const fetchScannedLpns = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${HOST_URL}/warehouse/item`);
      setIsLoading(false);
      setScannedLpns(response.data.data);
    } catch (error) {
      setIsLoading(false);
      setError(error);
      console.log(error);
    }
  };

  useEffect(() => {
    if (!scannedLpns) {
      fetchScannedLpns();
    }
  }, [scannedLpns]);
  return (
    <div className="d-flex flex-column">
      <LpnForm
        scannedLpns={scannedLpns}
        setScannedLpns={setScannedLpns}
        setCurrentItem={setCurrentItem}
      />
      {currentItem && <LpnItem currentItem={currentItem} />}
      <LpnList scannedLpns={scannedLpns} isLoading={isLoading} error={error} />
    </div>
  );
}

export default ExtLpnScan;
