import React, { useState, useMemo, useEffect } from "react";

import { Row, Col, Card, Button, Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import AccountTable from "./AccountTable";
import ActiveVendorAccountForm from "./ActiveVendorAccountForm";
import ActiveVendorAccountCard from "./ActiveVendorAccountCard";

import vendorService from "../../services/vendorService";

const ActiveVendorAccounts = ({
  activeVendorAccounts,
  setActiveVendors,
  stores,
  users,
}) => {
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [vendorUsers, setVendorUsers] = useState();
  const [vendorStores, setVendorStores] = useState();
  const [modal, setModal] = useState({ show: false, type: null });

  const columns = useMemo(
    () => [
      {
        Header: "Vendor Name",
        accessor: "name",
      },
      { Header: "Email Address", accessor: "email" },
      {
        Header: "Phone Number",
        accessor: "phone",
      },
      { Header: "Street Address", accessor: "address" },
      { Header: "ZIP Code", accessor: "zip" },
    ],
    []
  );

  const accountRowClickHandler = (row) => {
    setSelectedAccount(row);
  };

  const deleteVendorHandler = async () => {
    const submittingToast = toast.loading("Deleting vendor...");

    const response = await vendorService
      .deleteVendor(selectedAccount.vendorId)
      .catch((error) => {
        console.error(error);
        toast.dismiss(submittingToast);
        toast.error(
          error?.response?.data?.message ||
            error?.message ||
            "Something went wrong."
        );
        setModal({ show: false, type: null });
      });

    if (response.success) {
      toast.dismiss(submittingToast);
      toast.success("Vendor successfully deleted!");

      const response2 = await vendorService.getVendors().catch((error) => {
        console.error(error);
        toast.dismiss(submittingToast);
        toast.error(
          error?.response?.data?.message ||
            error?.message ||
            "Something went wrong."
        );
      });

      setActiveVendors(response2.vendors);
    } else {
      toast.dismiss(submittingToast);
      toast.error("Vendor unsuccessfully deleted!");
    }

    setModal({ show: false, type: null });
    setSelectedAccount(null);
  };

  // When a vendor is selected, filter out the associated users
  // and stores from all users and stores.
  useEffect(() => {
    if (selectedAccount) {
      const filteredUsers = users.filter(
        (user) => user.vendorId === selectedAccount.vendorId
      );

      const filteredStores = stores.filter(
        (store) => store.vendorId === selectedAccount.vendorId
      );

      setVendorUsers(filteredUsers);
      setVendorStores(filteredStores);
    }
  }, [selectedAccount, users, stores]);

  return (
    <>
      <Modal
        size="sm"
        show={modal.show}
        onHide={() => {
          setModal({ show: false, type: null });
        }}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-center w-100">
            {modal.type === "create" ? "Create Vendor" : "Delete Vendor"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modal.type === "create" ? (
            <ActiveVendorAccountForm
              isEdit={false}
              setModal={setModal}
              setActiveVendors={setActiveVendors}
            />
          ) : modal.type === "delete" ? (
            <>
              <div className="mb-3">
                Are you sure you want to delete{" "}
                <strong>{selectedAccount.name}</strong> ?
                <div>
                  <small>This action cannot be undone.</small>
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <Button variant="danger" onClick={deleteVendorHandler}>
                  Delete Vendor
                </Button>
                <Button
                  variant="secondary"
                  onClick={() => {
                    setModal({ show: false, type: null });
                  }}
                >
                  Cancel
                </Button>
              </div>
            </>
          ) : null}
        </Modal.Body>
      </Modal>
      <Row>
        <Col xl={8}>
          <Card>
            <Card.Body>
              <AccountTable
                columns={columns}
                data={activeVendorAccounts}
                onClickFn={accountRowClickHandler}
                clickableRows={true}
                actionButton={
                  <Button
                    variant="primary"
                    onClick={() => {
                      setModal({ show: true, type: "create" });
                    }}
                    className="mb-2"
                  >
                    Create Vendor
                  </Button>
                }
              />
            </Card.Body>
          </Card>
        </Col>
        <Col xl={4}>
          <ActiveVendorAccountCard
            selectedAccount={selectedAccount}
            setSelectedAccount={setSelectedAccount}
            setModal={setModal}
            setActiveVendors={setActiveVendors}
            vendorUsers={vendorUsers}
            vendorStores={vendorStores}
          />
        </Col>
      </Row>
    </>
  );
};

export default ActiveVendorAccounts;
