import axios from "axios";

const HOST_URL = process.env.REACT_APP_HOST_URL;

const getVendors = async () => {
  const response = (await axios.get(`${HOST_URL}/vendors/getVendors`)).data;

  return response;
};

const createVendor = async (formValues) => {
  const newVendor = {
    vendorName: formValues.vendorName,
    email: formValues.email,
    phone: formValues.phone,
    address: formValues.address,
    city: formValues.city,
    state: formValues.state,
    country: formValues.country,
    zip: formValues.zip,
    routingNumber: formValues.routingNumber,
    accountNumber: formValues.accountNumber,
    hearAboutUs: formValues.hearAboutUs,
    amazonPreference: formValues.amazonPreference,
    note: formValues.note,
  };

  // Call the API endpoint for creating a new vendor.
  const response = (
    await axios.post(`${HOST_URL}/vendors/createVendor`, newVendor)
  ).data;

  return response;
};

const updateVendor = async (selectedVendor, formValues) => {
  const updatedVendor = Object.assign(selectedVendor, formValues);

  const response = (
    await axios.post(`${HOST_URL}/vendors/updateVendor`, updatedVendor)
  ).data;

  return response;
};

const deleteVendor = async (vendorId) => {
  const response = (
    await axios.delete(`${HOST_URL}/vendors/deleteVendor?vendorId=${vendorId}`)
  ).data;

  return response;
};

const service = {
  getVendors,
  createVendor,
  updateVendor,
  deleteVendor,
};

export default service;
