import React from "react";

// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLoggedIn from "./layouts/DashboardLoggedIn";
import DashboardLoggedOut from "./layouts/DashboardLoggedOut";

// Guards
import AuthGuard from "./components/guards/AuthGuard";

// Dashboards
import Home from "./pages/dashboards/Home";
import AnalyticsPage from "./pages/dashboards/analytics/AnalyticsPage";

// Pages
import FbaCustomerReturnsPage from "./pages/fbaCustomerReturns/FbaCustomerReturnsPage";
import RemovalOrdersPage from "./pages/removalOrders/RemovalOrderPage";
import RemovalOrderShipmentsPage from "./pages/removalOrderShipments/RemovalOrderShipmentsPage";
import AcceptancePage from "./pages/acceptance/AcceptancePage";
import LinkedAccountsPage from "./pages/linkedAccounts/LinkedAccountsPage";
import ProfilePage from "./pages/profile/ProfilePage";
import SalesActivityPage from "./pages/sales/SalesActivityPage";
import PayoutsPage from "./pages/payouts/PayoutsPage";
import HelpPage from "./pages/help/HelpPage";
import ListingsPage from "./pages/listings/ListingsPage";
import AccountsPage from "./pages/accounts/AccountsPage";
import AmazonReportsPage from "./pages/amazonReports/AmazonReportsPage";
import CronJobsPage from "./pages/cronJobs/CronJobsPage";

// Auth
import Page500 from "./pages/auth/Page500";
import Page404 from "./pages/auth/Page404";
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";
import ResetPassword from "./pages/auth/ResetPassword";

// Protected routes
import ProtectedPage from "./pages/protected/ProtectedPage";

//New Routes
import OAuthLanding from "./pages/welcome/OAuthLanding";
import AmazonOrdersPage from "./pages/amazonOrders/AmazonOrdersPage";
import AdminGuard from "./components/guards/AdminGuard";
import AmazonRedirect from "./utils/AmazonRedirect";
import AmazonLinkGenerator from "./pages/vendorAccounts/AmazonLinkGenerator";
import ExtLpnScan from "./pages/extLpnScan/ExtLpnScan";

const routes = [
  {
    path: "/extLpnScanner",
    element: (
      <AuthGuard>
        <ExtLpnScan />
      </AuthGuard>
    ),
  },
  {
    path: "/",
    element: (
      <AuthGuard>
        <DashboardLoggedIn />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <AnalyticsPage />,
      },
      {
        path: "home",
        element: <AnalyticsPage />,
      },
      {
        path: "fbaCustomerReturns",
        element: <FbaCustomerReturnsPage />,
      },
      {
        path: "removalOrders",
        element: <RemovalOrdersPage />,
      },
      {
        path: "removalOrders/:storeId/:orderId",
        element: <RemovalOrdersPage />,
      },
      {
        path: "removalShipments",
        element: <RemovalOrderShipmentsPage />,
      },
      {
        path: "removalShipments/:storeId/:orderId",
        element: <RemovalOrderShipmentsPage />,
      },
      {
        path: "acceptance",
        element: <AcceptancePage />,
      },
      {
        path: "listings",
        element: <ListingsPage />,
      },
      {
        path: "sales",
        element: <SalesActivityPage />,
      },
      {
        path: "payouts",
        element: <PayoutsPage />,
      },
      {
        path: "linkedAccounts",
        element: <LinkedAccountsPage />,
      },
      {
        path: "profile",
        element: <ProfilePage />,
      },
      {
        path: "oAuthLanding",
        element: <OAuthLanding />,
      },
      {
        path: "help",
        element: <HelpPage />,
      },
    ],
  },

  {
    path: "admin",
    element: (
      <AuthGuard>
        <AdminGuard>
          <DashboardLoggedIn />
        </AdminGuard>
      </AuthGuard>
    ),
    children: [
      {
        path: "accounts",
        element: <AccountsPage />,
      },
      {
        path: "amazonLinkGenerator",
        element: <AmazonLinkGenerator />,
      },
      {
        path: "amazonOrders",
        element: <AmazonOrdersPage />,
      },
      {
        path: "amazonReports",
        element: <AmazonReportsPage />,
      },
      {
        path: "amazonReports?storeId&storeName",
        element: <AmazonReportsPage />,
      },

      {
        path: "cronJobs",
        element: <CronJobsPage />,
      },
    ],
  },
  {
    path: "logged-out",
    element: <DashboardLoggedOut />,

    children: [
      {
        path: "",
        element: <SignIn />,
      },
    ],
  },
  {
    path: "sign-up",
    element: <DashboardLoggedOut />,
    children: [
      {
        path: "",
        element: <SignUp />,
      },
      {
        path: ":pendingAccountId",
        element: <SignUp />,
      },
    ],
  },
  {
    path: "resetPassword",
    element: <DashboardLoggedOut />,
    children: [
      {
        path: ":token",
        element: <ResetPassword />,
      },
      {
        path: "",
        element: <ResetPassword />,
      },
    ],
  },
  {
    path: "amazonRedirect",
    element: <DashboardLoggedOut />,
    children: [
      {
        path: "",
        element: <AmazonRedirect />,
      },
      {
        path: ":requestId",
        element: <AmazonRedirect />,
      },
    ],
  },
  {
    path: "auth",
    element: <AuthLayout />,
    children: [
      {
        path: "404",
        element: <Page404 />,
      },
      {
        path: "500",
        element: <Page500 />,
      },
    ],
  },

  {
    path: "private",
    element: (
      <AuthGuard>
        <DashboardLoggedIn />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <ProtectedPage />,
      },
    ],
  },
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
