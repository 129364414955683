import React from "react";
import Table from "../../components/table/Table";
import { needStoreNameColumn } from "../../utils/helper";
import OrderIdLink from "./OrderIdLink";
import { shipmentItemColumns } from "./tableColumns";

function RemovalOrderShipmentItemsTable({
  removalOrderShipmentItems,
  filter,
  alias,
}) {
  const orderIdClickFunction = ({ row }) => {
    const { orderId } = row.original;
    return <OrderIdLink orderId={orderId} alias={alias} />;
  };

  shipmentItemColumns.find((col) => col.accessor === "orderId").Cell =
    orderIdClickFunction;

  let columns = shipmentItemColumns;
  if (needStoreNameColumn(removalOrderShipmentItems)) {
    columns = [{ Header: "Store", accessor: "storeName" }, ...columns];
  }

  return (
    <Table
      columns={columns}
      data={removalOrderShipmentItems}
      initialPageSize={50}
      pageName="RemovalShipments" // For CSV Download
      initialFilterValues={filter && { filters: [filter] }}
    />
  );
}

export default RemovalOrderShipmentItemsTable;
