import React from "react";

import { Card } from "react-bootstrap";

import StoresForm from "./StoresForm";

const StoresCard = ({ selectedStore, setSelectedStore, setModal }) => {
  return (
    <Card className="sticky-top" style={{ top: 20 }}>
      {selectedStore ? (
        <>
          <Card.Header>
            <Card.Title tag="h5" className="text-center mb-0">
              Store Information:
            </Card.Title>
          </Card.Header>
          <Card.Body>
            <StoresForm
              isEdit={true}
              setModal={setModal}
              selectedStore={selectedStore}
              setSelectedStore={setSelectedStore}
            />
          </Card.Body>
        </>
      ) : (
        <>
          <Card.Header>
            <Card.Title tag="h5" className="text-center mb-0">
              Store Information:
            </Card.Title>
          </Card.Header>
          <Card.Body className="text-center">No Account Selected</Card.Body>
        </>
      )}
    </Card>
  );
};

export default StoresCard;
