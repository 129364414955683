import React from "react";
import { Alert, Button, Form } from "react-bootstrap";
import { AlertTriangle } from "react-feather";

import vendorService from "../../services/vendorService";

import { Formik, Field } from "formik";
import toast from "react-hot-toast";
import * as Yup from "yup";

const ActiveAccountForm = ({
  isEdit,
  setModal,
  selectedAccount,
  setSelectedAccount,
  setActiveVendors,
}) => {
  const initialValues = isEdit
    ? {
        vendorName: selectedAccount.name || "",
        email: selectedAccount.email || "",
        phone: selectedAccount.phone || "",
        address: selectedAccount.address || "",
        city: selectedAccount.city || "",
        state: selectedAccount.state || "",
        zip: selectedAccount.zip || "",
        country: selectedAccount.country || "",
        routingNumber: selectedAccount.routingNumber || "",
        accountNumber: selectedAccount.accountNumber || "",
        hearAboutUs: selectedAccount.hearAboutUs || "",
        amazonPreference: selectedAccount.amazonPreference || "",
        note: selectedAccount.note || "",
        vendorId: selectedAccount.vendorId,
      }
    : {
        vendorName: "",
        email: "",
        phone: "",
        address: "",
        city: "",
        state: "",
        zip: "",
        country: "",
        routingNumber: "",
        accountNumber: "",
        hearAboutUs: "",
        amazonPreference: "",
        note: "",
      };

  const validationSchema = Yup.object().shape({
    vendorName: Yup.string()
      .max(150, "Vendor Name must be less than 150 characters.")
      .required("Vendor Name is required."),
  });

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, { setErrors, setSubmitting }) => {
        setSubmitting(true);

        if (isEdit) {
          const submittingToast = toast.loading("Saving changes...");

          const response = await vendorService
            .updateVendor(values)
            .catch((error) => {
              console.error(error);
              setErrors({
                submit:
                  error?.response?.data?.message ||
                  error?.message ||
                  "Something went wrong.",
              });
              toast.dismiss(submittingToast);
              toast.error(
                error?.response?.data?.message ||
                  error?.message ||
                  "Something went wrong."
              );
              setSubmitting(false);
            });

          if (response.success) {
            toast.dismiss(submittingToast);
            toast.success("Vendor successfully updated!");

            const response2 = await vendorService
              .getVendors()
              .catch((error) => {
                console.error(error);
                toast.dismiss(submittingToast);
                toast.error(
                  error?.response?.data?.message ||
                    error?.message ||
                    "Something went wrong."
                );
              });

            setActiveVendors(response2.vendors);
          } else {
            toast.dismiss(submittingToast);
            toast.error("Vendor unsuccessfully updated!");
          }

          setSubmitting(false);
          setModal({ show: false, type: null });
          setSelectedAccount(null);
        } else {
          const submittingToast = toast.loading("Creating vendor...");

          const response = await vendorService
            .createVendor(values)
            .catch((error) => {
              console.error(error);
              setErrors({
                submit:
                  error?.response?.data?.message ||
                  error?.message ||
                  "Something went wrong.",
              });
              toast.dismiss(submittingToast);
              toast.error(
                error?.response?.data?.message ||
                  error?.message ||
                  "Something went wrong."
              );
              setSubmitting(false);
            });

          if (response.success) {
            toast.dismiss(submittingToast);
            toast.success("Vendor successfully created!");

            const response2 = await vendorService
              .getVendors()
              .catch((error) => {
                console.error(error);
                toast.dismiss(submittingToast);
                toast.error(
                  error?.response?.data?.message ||
                    error?.message ||
                    "Something went wrong."
                );
              });

            setActiveVendors(response2.vendors);
          } else {
            toast.dismiss(submittingToast);
            toast.error("Vendor unsuccessfully created!");
          }

          setSubmitting(false);
          setModal({ show: false, type: null });
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        touched,
        values,
      }) => (
        <Form onSubmit={handleSubmit}>
          {errors?.submit && (
            <Alert className="my-3" variant="danger">
              <div className="alert-icon d-flex align-items-center">
                <AlertTriangle />
              </div>
              <div className="alert-message">{errors.submit}</div>
            </Alert>
          )}
          <Form.Group className="mb-3">
            <Form.Label className="mb-0">Vendor Name</Form.Label>
            <Form.Control
              type="text"
              name="vendorName"
              placeholder="Enter vendor name..."
              value={values.vendorName}
              isInvalid={Boolean(touched.vendorName && errors.vendorName)}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {!!touched.vendorName && (
              <Form.Control.Feedback type="invalid">
                {errors.vendorName}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label className="mb-0">Email Address</Form.Label>
            <Form.Control
              type="email"
              name="email"
              placeholder="Enter email address..."
              value={values.email}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label className="mb-0">Phone Number</Form.Label>
            <Form.Control
              type="text"
              name="phone"
              placeholder="Enter phone number..."
              value={values.phone}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label className="mb-0">Street Address</Form.Label>
            <Form.Control
              type="text"
              name="address"
              placeholder="Enter street address..."
              value={values.address}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label className="mb-0">City</Form.Label>
            <Form.Control
              type="text"
              name="city"
              placeholder="Enter city..."
              value={values.city}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label className="mb-0">State</Form.Label>
            <Form.Control
              type="text"
              name="state"
              placeholder="Enter state..."
              value={values.state}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label className="mb-0">ZIP Code</Form.Label>
            <Form.Control
              type="text"
              name="zip"
              placeholder="Enter ZIP code..."
              value={values.zip}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label className="mb-0">Country</Form.Label>
            <Form.Control
              type="text"
              name="country"
              placeholder="Enter country..."
              value={values.country}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label className="mb-0">Bank Routing Number</Form.Label>
            <Form.Control
              type="text"
              name="routingNumber"
              placeholder="Enter bank routing number..."
              value={values.routingNumber}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label className="mb-0">Bank Account Number</Form.Label>
            <Form.Control
              type="text"
              name="accountNumber"
              placeholder="Enter bank account number..."
              value={values.accountNumber}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label className="mb-0">How did you hear about us?</Form.Label>
            <Form.Control
              as="select"
              name="hearAboutUs"
              id="hearAboutUs"
              value={values.hearAboutUs}
              onBlur={handleBlur}
              onChange={handleChange}
            >
              <option value="">Select</option>
              <option value="Amazon Seller Network">
                Amazon Seller Network
              </option>
              <option value="Customer Referral">Customer Referral</option>
              <option value="Internet Search">Internet Search</option>
              <option value="Met at Tradeshow">Met at Tradeshow</option>
              <option value="other">Other</option>
            </Form.Control>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label className="mb-0">Preferences</Form.Label>
            <div className="mb-3">
              Any restrictions on listing your products on Amazon?
            </div>
            <div className="d-flex flex-column">
              <label>
                <Field
                  type="radio"
                  name="amazonPreference"
                  value="noAmazonRestrictions"
                />{" "}
                Sell new as new
              </label>
              <label>
                <Field
                  type="radio"
                  name="amazonPreference"
                  value="restrictAmazonNewListing"
                />{" "}
                Sell as used regardless of condition
              </label>
              <label>
                <Field
                  type="radio"
                  name="amazonPreference"
                  value="restrictAmazonAllListings"
                />{" "}
                Do not sell on Amazon
              </label>
            </div>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label className="mb-0">Amazon Store Name/s</Form.Label>
            <Form.Control
              type="text"
              name="note"
              placeholder="Enter Store Names..."
              value={values.note}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </Form.Group>

          {isEdit ? (
            <div className="d-flex justify-content-between">
              <Button type="submit" variant="success" disabled={isSubmitting}>
                Save Changes
              </Button>
              <Button
                variant="danger"
                onClick={() => {
                  setModal({ show: true, type: "delete" });
                }}
                disabled={isSubmitting}
              >
                Delete Vendor
              </Button>
            </div>
          ) : (
            <div className="d-flex justify-content-between">
              <Button type="submit" variant="success" disabled={isSubmitting}>
                Create Vendor
              </Button>
              <Button
                variant="secondary"
                onClick={() => {
                  setModal({ show: false, type: null });
                }}
                disabled={isSubmitting}
              >
                Cancel
              </Button>
            </div>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default ActiveAccountForm;
