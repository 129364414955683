import React, { useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { getURLSearchParams } from "../../utils/helper";

const HOST_URL = process.env.REACT_APP_HOST_URL;

function OAuthLanding() {
  const urlQuery = window.location.search;
  const spapi_oauth_code = getURLSearchParams(urlQuery, "spapi_oauth_code");
  const sellingPartnerId = getURLSearchParams(urlQuery, "selling_partner_id");
  const vendorId = getURLSearchParams(urlQuery, "state");
  const [code] = useState(spapi_oauth_code);

  const user = JSON.parse(localStorage.getItem("user"));
  //only getting userId if user is logged in
  const vendor = JSON.parse(localStorage.getItem("vendor"));
  const userId = vendor && user?.userId ? user.userId : null;

  async function exchangeAuthCode(code) {
    try {
      const result = axios.post(
        `${HOST_URL}/spApi/exchangeAuthCodeForSpApiCredentials?sellingPartnerId=${sellingPartnerId}&authorizationCode=${code}&vendorId=${vendorId}&userId=${userId}`
      ).data;
      return result;
    } catch (error) {
      console.log("error", error);
    }
  }

  useEffect(() => {
    if (code && vendorId && exchangeAuthCode(code)) {
      toast.success("Successfully linked account");
      setTimeout(() => {
        window.location.href = "/";
      }, 1500);
    } else {
      toast.error("Unable to authenticate. Please try again.");
      setTimeout(() => {
        window.location.href = "/linkedAccounts";
      }, 1500);
    }
  }, [code]);

  return <div>Thank you! Your Amazon account is being authenticated.</div>;
}

export default OAuthLanding;
