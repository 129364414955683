import React, { useState, useEffect } from "react";

import Select from "react-select";

import axios from "axios";
const HOST_URL = process.env.REACT_APP_HOST_URL;

function VendorSelect({
  alias: vendorAlias,
  setAlias: setVendorAlias,
  clearData,
}) {
  const [vendors, setVendors] = useState();
  const [vendorOptions, setVendorOptions] = useState();

  function getAliasValue(vendorAlias) {
    const vendor = vendors.find((obj) => obj.vendorId === vendorAlias.value);
    const value = vendor ? vendor.vendorId : null;
    const label = vendor ? vendor.name : null;
    return {
      value,
      label,
    };
  }

  let aliasValue;
  if (vendors && vendorAlias && vendorAlias.type === "vendor") {
    aliasValue = getAliasValue(vendorAlias);
  } else {
    aliasValue = null;
  }

  useEffect(() => {
    const getVendors = async () => {
      try {
        const vendorDataResponse = (
          await axios.get(`${HOST_URL}/utils/vendors`)
        ).data.data;

        const vendorOptions = vendorDataResponse.map((vendor) => {
          return {
            value: vendor.vendorId,
            label: vendor.name,
          };
        });

        setVendors(vendorDataResponse);
        setVendorOptions(vendorOptions);
      } catch (error) {
        console.log(error);
      }
    };
    getVendors();
  }, []);

  return (
    <Select
      styles={{
        // Fixes the overlapping problem of the component
        menu: (provided) => ({ ...provided, zIndex: 9999 }),
      }}
      className="react-select-container m-auto"
      classNamePrefix="react-select"
      placeholder="Select a Vendor"
      onChange={(selectedValue) => {
        // first clear the fetched data so the loader will show when vendor changes:
        clearData();
        setVendorAlias({ type: "vendor", value: selectedValue.value });
      }}
      options={vendorOptions}
      isSearchable
      value={aliasValue}
    />
  );
}

export default VendorSelect;
