import React, { useEffect, useState } from "react";

import {
  useAsyncDebounce,
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

import {
  faSort,
  faSortDown,
  faSortUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Filter, X } from "react-feather";

import {
  Button,
  Col,
  Form,
  InputGroup,
  Pagination,
  Row,
  Table,
} from "react-bootstrap";
import ManualPagination from "../../components/table/ManualPagination";
import Loader from "../../components/Loader";
import DownloadCSV from "./DownloadCSV";

const GlobalFilter = ({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  data,
  pageData,
  setPageData,
  manualPagination,
}) => {
  const count = manualPagination
    ? pageData.totalRows
    : preGlobalFilteredRows.length;
  const [value, setValue] = useState(globalFilter);

  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  // Sets the input value to "" every time data is updated.
  useEffect(() => {
    !manualPagination && setValue("");
  }, [data]);

  return (
    <span>
      <InputGroup>
        <Form.Control
          placeholder={`Search ${count} records...`}
          value={value || ""}
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
        />
        <Button
          variant={value ? "danger" : "outline-secondary"}
          disabled={!value}
          onClick={() => {
            setValue(undefined);
            onChange(undefined);
          }}
        >
          <X size={16} />
        </Button>
      </InputGroup>
    </span>
  );
};

const UITable = ({
  columns,
  data,
  clickableRows = false,
  onClickFn,
  pageSizes = [10, 20, 50, 100, 200],
  initialHiddenColumns,
  initialPageSize = 10,
  pageName,
  filteredBy,
  initialFilterValues,
  manualPagination = false,
  currentPage,
  setCurrentPage,
  pageData,
  setPageData,
}) => {
  const [showFilters, setShowFilters] = useState(
    initialFilterValues ? true : false
  );

  const DefaultColumnFilter = ({ column }) => {
    const [value, setValue] = useState();
    const { filterValue, setFilter, Header: header } = column;

    // onChange for manualPagination. Uses asyncDebounce to reduce the
    // amount of times the BE is called when the input changes.
    const onChange = useAsyncDebounce((value) => {
      setFilter(value || undefined);
    }, 200);

    // When all filters are cleared, update the input value to reflect.
    useEffect(() => {
      manualPagination && setValue(filterValue);
    }, [filterValue]);

    return (
      <InputGroup className="mb-3">
        <Form.Control
          value={manualPagination ? value || "" : filterValue || ""}
          onChange={(e) => {
            if (manualPagination) {
              onChange(e.target.value);
              setValue(e.target.value || undefined);
            } else {
              setFilter(e.target.value || undefined);
            }
          }}
          placeholder={`Search ${header}...`}
        />
        <Button
          variant={filterValue ? "danger" : "outline-secondary"}
          disabled={!filterValue}
          onClick={() => {
            setValue(undefined);
            setFilter(undefined);
          }}
        >
          <X size={16} />
        </Button>
      </InputGroup>
    );
  };

  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, globalFilter, filters },
    rows,
    initialRows,
    preGlobalFilteredRows,
    setGlobalFilter,
    setAllFilters,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        pageIndex: 0,
        pageSize: manualPagination ? pageData.pageSize : initialPageSize,
        hiddenColumns: initialHiddenColumns ? [...initialHiddenColumns] : [],
        filters: initialFilterValues ? initialFilterValues.filters : [],
      },
      manualFilters: manualPagination,
      manualGlobalFilter: manualPagination,
      autoResetFilters: !manualPagination,
      autoResetGlobalFilter: !manualPagination,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const hasFilters = filters.length > 0;

  // If the filters state changes, update the pageData filters state.
  useEffect(() => {
    if (manualPagination) {
      setCurrentPage(1);
      const combinedFilters = filters.reduce(
        (acc, curr) => ((acc[curr.id] = curr.value), acc),
        {}
      );
      combinedFilters.globalFilter = globalFilter;
      setPageData((prevState) => ({
        ...prevState,
        filters: combinedFilters,
      }));
    }
  }, [filters, globalFilter]);

  return manualPagination ? (
    <>
      <Row className="mb-3">
        <Col sm={11} className="d-flex align-items-center">
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            data={data}
            manualPagination={manualPagination}
            pageData={pageData}
            setPageData={setPageData}
          />
          <Button
            className="ms-3"
            variant={hasFilters ? "outline-warning" : "outline-secondary"}
            onClick={() => {
              setShowFilters(!showFilters);
            }}
          >
            <Filter size={16} /> Filters
          </Button>
          {hasFilters && (
            <Button
              variant="danger"
              className="ms-3"
              onClick={() => setAllFilters([])}
            >
              <Filter size={16} /> Clear All
            </Button>
          )}
        </Col>
        <Col sm={1} className="text-end">
          <DownloadCSV
            headers={
              /* this function filters out the initialHiddenColumns 
                     and maps the columns to the structure required by the DownloadCSV component */
              columns.reduce((accumulator, currentValue) => {
                if (
                  !initialHiddenColumns ||
                  !initialHiddenColumns.includes(currentValue.Header)
                ) {
                  return [
                    ...accumulator,
                    {
                      label: currentValue.Header,
                      key: currentValue.id || currentValue.accessor,
                    },
                  ];
                }
                return accumulator;
              }, [])
            }
            data={rows.map((row) => row.values)}
            pageName={pageName}
            filteredBy={filteredBy}
          />
        </Col>
      </Row>
      <Table
        size="sm"
        striped
        hover
        style={{ tableLayout: "fixed" }}
        {...getTableProps()}
      >
        <thead>
          {headerGroups.map((headerGroup, index) => (
            <tr key={index} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, index) => {
                // Add manualPagination and min date to columns for DateRangeFilter
                if (manualPagination) {
                  column.manualPagination = manualPagination;
                  column.minDate = pageData.minDate;
                }
                return (
                  <React.Fragment key={index}>
                    <th>
                      <div
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        {column.render("Header")}
                        {!column.disableSortBy && (
                          <span>
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <FontAwesomeIcon
                                  icon={faSortUp}
                                  className="ms-2"
                                />
                              ) : (
                                <FontAwesomeIcon
                                  icon={faSortDown}
                                  className="ms-2"
                                />
                              )
                            ) : (
                              <FontAwesomeIcon icon={faSort} className="ms-2" />
                            )}
                          </span>
                        )}
                      </div>
                      {column.canFilter && showFilters
                        ? column.render("Filter")
                        : null}
                    </th>
                  </React.Fragment>
                );
              })}
            </tr>
          ))}
        </thead>
        {pageData.rowData.length > 0 && !pageData.isLoading && (
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  onClick={clickableRows ? () => onClickFn(row.original) : null}
                  className={clickableRows ? "cursor-pointer" : null}
                >
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        )}
      </Table>
      {pageData.rowData.length > 0 ? (
        pageData.isLoading ? (
          <>
            <Loader />
            <ManualPagination
              pageData={pageData}
              setPageData={setPageData}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </>
        ) : (
          <ManualPagination
            pageData={pageData}
            setPageData={setPageData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        )
      ) : pageData.isLoading ? (
        <Loader />
      ) : (
        <h3 className="text-center">No Data To Display</h3>
      )}
    </>
  ) : data.length > 0 ? (
    <>
      <Row className="mb-3">
        <Col sm={11} className="d-flex align-items-center">
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            data={data}
            manualPagination={manualPagination}
            pageData={pageData}
            setPageData={setPageData}
          />
          <Button
            className="ms-3"
            variant={hasFilters ? "outline-warning" : "outline-secondary"}
            onClick={() => {
              setShowFilters(!showFilters);
            }}
          >
            <Filter size={16} /> Filters
          </Button>
          {hasFilters && (
            <Button
              variant="danger"
              className="ms-3"
              onClick={() => setAllFilters([])}
            >
              <Filter size={16} /> Clear All
            </Button>
          )}
          {rows.length !== initialRows.length ? (
            <span className="font-weight-bold ms-3">
              {`Showing ${rows.length} results filtered from ${initialRows.length}`}
            </span>
          ) : (
            ""
          )}
        </Col>
        <Col sm={1} className="text-end">
          <DownloadCSV
            headers={
              /* this function filters out the initialHiddenColumns 
                   and maps the columns to the structure required by the DownloadCSV component */
              columns.reduce((accumulator, currentValue) => {
                if (
                  !initialHiddenColumns ||
                  !initialHiddenColumns.includes(currentValue.Header)
                ) {
                  return [
                    ...accumulator,
                    {
                      label: currentValue.Header,
                      key: currentValue.id || currentValue.accessor,
                    },
                  ];
                }
                return accumulator;
              }, [])
            }
            data={rows.map((row) => row.values)}
            pageName={pageName}
            filteredBy={filteredBy}
          />
        </Col>
      </Row>
      <Table
        size="sm"
        striped
        hover
        {...getTableProps()}
        style={{ tableLayout: "fixed" }}
        className="text-center"
      >
        <thead>
          {headerGroups.map((headerGroup, index) => (
            <tr key={index} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, index) => {
                return (
                  <React.Fragment key={index}>
                    <th>
                      <div
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        {column.render("Header")}
                        <span>
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <FontAwesomeIcon
                                icon={faSortUp}
                                className="ms-2"
                              />
                            ) : (
                              <FontAwesomeIcon
                                icon={faSortDown}
                                className="ms-2"
                              />
                            )
                          ) : (
                            <FontAwesomeIcon icon={faSort} className="ms-2" />
                          )}
                        </span>
                      </div>
                      {column.canFilter && showFilters
                        ? column.render("Filter")
                        : null}
                    </th>
                  </React.Fragment>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                onClick={clickableRows ? () => onClickFn(row.original) : null}
                className={clickableRows ? "cursor-pointer" : null}
              >
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
      {data.length > 10 && (
        <Row>
          <Col md={9}>
            <span className="mx-2">
              Page{" "}
              <strong>
                {pageIndex + 1} of {pageCount}
              </strong>
            </span>
            <span className="ms-3 me-2">Show:</span>
            <Form.Select
              className="d-inline-block w-auto"
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
            >
              {pageSizes.map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </Form.Select>

            <span className="ms-3 me-2">Go to page:</span>
            <Form.Control
              className="d-inline-block"
              type="number"
              min={1}
              max={pageCount}
              value={pageIndex + 1}
              onChange={(e) => {
                const page =
                  !e.target.value || Number(e.target.value) <= 0
                    ? 1
                    : Number(e.target.value) > pageCount
                    ? pageCount
                    : Number(e.target.value);
                gotoPage(page - 1);
              }}
              style={{ width: "75px" }}
              autoComplete="off"
            />
          </Col>
          <Col md={3}>
            <Pagination className="float-end mb-0">
              <Pagination.First
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              />
              <Pagination.Prev
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              />
              <Pagination.Item
                onClick={() => gotoPage(0)}
                active={1 === pageIndex + 1}
              >
                {1}
              </Pagination.Item>
              {pageOptions.map((page, index) => {
                if (
                  (page + 1 === pageIndex + 1 - 3 && page + 1 !== 1) ||
                  (page + 1 === pageIndex + 1 + 3 && page + 1 !== pageCount)
                ) {
                  return <Pagination.Ellipsis key={index} disabled />;
                }
                if (
                  page + 1 >= pageIndex + 1 - 2 &&
                  page + 1 <= pageIndex + 1 + 2 &&
                  page + 1 !== 1 &&
                  page + 1 !== pageCount
                ) {
                  return (
                    <Pagination.Item
                      key={index}
                      onClick={() => gotoPage(page)}
                      active={page === pageIndex}
                    >
                      {page + 1}
                    </Pagination.Item>
                  );
                }
                return null;
              })}
              {pageCount !== 1 && (
                <Pagination.Item
                  onClick={() => gotoPage(pageCount - 1)}
                  active={pageCount === pageIndex + 1}
                >
                  {pageCount}
                </Pagination.Item>
              )}
              <Pagination.Next
                onClick={() => nextPage()}
                disabled={!canNextPage}
              />
              <Pagination.Last
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              />
            </Pagination>
          </Col>
        </Row>
      )}
    </>
  ) : (
    <h3 className="text-center">No Data To Display</h3>
  );
};

export default UITable;
