import React from "react";
import Table from "../../components/table/Table";
import { shipmentTableColumns } from "./tableColumns";

function AcceptanceDataShipmentTable({
  acceptanceDataShipments,
  setCurrentShipment,
}) {
  const shipmentRowClickHandler = (row) => {
    setCurrentShipment(row);
    window.scrollTo(0, 0);
  };

  return (
    <Table
      columns={shipmentTableColumns}
      data={acceptanceDataShipments}
      initialPageSize={50}
      clickableRows={true}
      onClickFn={shipmentRowClickHandler}
      pageName="Acceptance-Processing"
    />
  );
}

export default AcceptanceDataShipmentTable;
