import React, { useState, useContext } from "react";

import { Formik } from "formik";
import { Alert, Button, Form } from "react-bootstrap";
import { AlertTriangle } from "react-feather";
import toast from "react-hot-toast";
import * as Yup from "yup";
import useAuth from "../../hooks/useAuth";
import userService from "../../services/userService";
import { updateLocalStorage } from "../../utils/helper";
import VendorSelect from "./VendorSelect";

import { AccountsContext } from "./Accounts";

const loggedInUser = JSON.parse(localStorage.getItem("user"));

const UserAccountForm = ({
  selectedUser,
  setSelectedUser,
  setModal,
  isEdit,
  vendorId,
}) => {
  const [validatedField, setValidatedField] = useState({
    email: {
      isValidated: false,
      isUnique: null,
    },
    username: {
      isValidated: false,
      isUnique: null,
    },
  });

  const { setUsers } = useContext(AccountsContext);
  const { signIn } = useAuth();

  //Taken and modified from SignIn
  const logInAsUser = async () => {
    const submittingToast = toast.loading("Logging in...");
    try {
      await userService.signInAsUser(
        signIn,
        selectedUser.username,
        loggedInUser
      );
      window.location = "/";
      updateLocalStorage("portal.returnsWorldwide.rememberMe", false);
    } catch (error) {
      const message = error.response?.data?.message || "Something went wrong";
      toast.dismiss(submittingToast);
      toast.error(message);
      //setStatus({ success: false });
      //setErrors({ submit: message });
      //setSubmitting(false);
    }
  };
  //Taken and modified from SignUp
  /* async validation function - calls our API to see if the field already exists.
   * If exists, the function returns false, otherwise returns true
   */
  const checkForExistingField = async (fieldName, fieldValue) => {
    // this if prevents extra calls to the API:
    if (validatedField[fieldName].isValidated === false && fieldValue) {
      try {
        const response = await userService.validateField(fieldName, fieldValue);
        if (!response) {
          setValidatedField({
            ...validatedField,
            [fieldName]: { isValidated: true, isUnique: true },
          });
          return true;
        } else {
          setValidatedField({
            ...validatedField,
            [fieldName]: {
              isValidated: true,
              isUnique: !response.data.fieldExists,
            },
          });
          return !response.data.fieldExists;
        }
      } catch (error) {
        console.log(error);
        setValidatedField({
          ...validatedField,
          [fieldName]: { isValidated: true, isUnique: true },
        });
        return true;
      }
    }
    // need this 'else if' and 'else' at the end so that when these fields are validated
    // on submit, the validation isn't flipped (unique fields gets an error, and non-unique field gets no error)
    else if (
      validatedField[fieldName].isValidated === true &&
      validatedField[fieldName].isUnique === false
    )
      return false;
    else return true;
  };

  // for fields with async validation - when field changes, sets all validation state values to default
  const fieldChangedHandler = (fieldName, fieldValue, handleChange) => {
    setValidatedField({
      ...validatedField,
      [fieldName]: { isValidated: false, isUnique: null },
    });
    handleChange(fieldValue);
  };

  const initialValues = isEdit
    ? {
        firstName: selectedUser.firstName || "",
        lastName: selectedUser.lastName || "",
        username: selectedUser.username || "",
        email: selectedUser.email || "",
        isAdmin: selectedUser.isAdmin || false,
      }
    : {
        firstName: "",
        lastName: "",
        username: "",
        email: "",
        isAdmin: false,
        vendorId: vendorId || "",
      };

  const validationSchema = isEdit
    ? Yup.object().shape({
        firstName: Yup.string()
          .max(100, "First Name must be under 100 characters.")
          .required("First Name is required."),
        lastName: Yup.string()
          .max(100, "Last Name must be under 100 characters.")
          .required("Last Name is required."),
        username: Yup.string()
          .max(100, "Username must be under 100 characters.")
          .required("Username is required."),
        email: Yup.string()
          .email("Must be a valid email.")
          .max(150, "Email must be under 100 characters.")
          .required("Email Address is required."),
      })
    : Yup.object().shape({
        firstName: Yup.string()
          .max(100, "First Name must be under 100 characters.")
          .required("First Name is required."),
        lastName: Yup.string()
          .max(100, "Last Name must be under 100 characters.")
          .required("Last Name is required."),
        username: Yup.string()
          .max(100, "Username must be under 100 characters.")
          .required("Username is required.")
          .test(
            "Unique Username",
            "Username already in use",
            async (value) => await checkForExistingField("username", value)
          ),
        email: Yup.string()
          .email("Must be a valid email.")
          .max(150, "Email must be under 100 characters.")
          .required("Email Address is required.")
          .test(
            "Unique Email",
            "Email Address already in use.",
            async (value) => await checkForExistingField("email", value)
          ),
      });

  return (
    <>
      {!selectedUser?.isAdmin && (
        <p className="text-end">
          <Button onClick={logInAsUser}>Log In as User</Button>
        </p>
      )}
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setErrors, setSubmitting, resetForm }) => {
          setSubmitting(true);
          console.log(values);

          if (isEdit) {
            const submittingToast = toast.loading("Saving changes...");

            const result = await userService
              .updateUser(selectedUser, values)
              .catch((error) => {
                console.error(error);
                setErrors({
                  submit:
                    error?.response?.data?.message ||
                    error?.message ||
                    "Something went wrong.",
                });
                toast.dismiss(submittingToast);
                toast.error(
                  error?.response?.data?.message ||
                    error?.message ||
                    "Something went wrong."
                );
                setSubmitting(false);
              });

            if (result.success) {
              toast.dismiss(submittingToast);
              toast.success("User successfully updated!");

              const response = await userService.getUsers().catch((error) => {
                console.error(error);
              });

              setUsers(response);
            } else {
              toast.dismiss(submittingToast);
              toast.error("User unsuccessfully updated!");
            }

            setSubmitting(false);
            setModal({ show: false, type: null });
            setSelectedUser(null);
          } else {
            const submittingToast = toast.loading("Creating user...");

            const result = await userService
              .createUser(values)
              .catch((error) => {
                console.error(error);
                setErrors({
                  submit:
                    error?.response?.data?.message ||
                    error?.message ||
                    "Something went wrong.",
                });
                toast.dismiss(submittingToast);
                toast.error(
                  error?.response?.data?.message ||
                    error?.message ||
                    "Something went wrong."
                );
                setSubmitting(false);
              });

            if (result.success) {
              toast.dismiss(submittingToast);
              toast.success("User successfully created!");

              const response = await userService.getUsers().catch((error) => {
                console.error(error);
              });

              setUsers(response);
            } else {
              toast.dismiss(submittingToast);
              toast.success("User unsuccessfully created!");
            }

            setSubmitting(false);
            setModal({ show: false, type: null });
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          touched,
          values,
        }) => (
          <Form onSubmit={handleSubmit}>
            {errors?.submit && (
              <Alert className="my-3" variant="danger">
                <div className="alert-icon d-flex align-items-center">
                  <AlertTriangle />
                </div>
                <div className="alert-message">{errors.submit}</div>
              </Alert>
            )}
            <Form.Group className="mb-3">
              <Form.Label className="mb-0">First Name</Form.Label>
              <Form.Control
                type="text"
                name="firstName"
                placeholder="Enter first name..."
                value={values.firstName}
                isInvalid={Boolean(touched.firstName && errors.firstName)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {!!touched.firstName && (
                <Form.Control.Feedback type="invalid">
                  {errors.firstName}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className="mb-0">Last Name</Form.Label>
              <Form.Control
                type="text"
                name="lastName"
                placeholder="Enter last name..."
                value={values.lastName}
                isInvalid={Boolean(touched.lastName && errors.lastName)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {!!touched.lastName && (
                <Form.Control.Feedback type="invalid">
                  {errors.lastName}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className="mb-0">Username</Form.Label>
              <Form.Control
                type="text"
                name="username"
                placeholder="Enter username..."
                value={values.username}
                isInvalid={Boolean(touched.username && errors.username)}
                onBlur={handleBlur}
                onChange={(value) =>
                  fieldChangedHandler("username", value, handleChange)
                }
              />
              {!!touched.username && (
                <Form.Control.Feedback type="invalid">
                  {errors.username}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className="mb-0">Email Address</Form.Label>
              <Form.Control
                type="email"
                name="email"
                placeholder="Enter email address..."
                value={values.email}
                isInvalid={Boolean(touched.email && errors.email)}
                onBlur={handleBlur}
                onChange={(value) =>
                  fieldChangedHandler("email", value, handleChange)
                }
              />
              {!!touched.email && (
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            {!isEdit && !vendorId && (
              <Form.Group className="mb-3">
                <Form.Label className="mb-0">Vendor</Form.Label>
                <VendorSelect
                  value={values.vendorId}
                  setFieldValue={setFieldValue}
                />
              </Form.Group>
            )}
            <Form.Group className="mb-3">
              <Form.Label className="mb-0">Administrator</Form.Label>
              <Form.Check
                type="checkbox"
                name="isAdmin"
                label="Check this if this user is an Administrator."
                checked={values.isAdmin}
                onChange={handleChange}
              />
            </Form.Group>
            {isEdit ? (
              <div className="d-flex justify-content-between">
                <Button type="submit" variant="success" disabled={isSubmitting}>
                  Save Changes
                </Button>
                {vendorId ? (
                  <Button
                    variant="secondary"
                    onClick={() => {
                      setModal({ show: false, type: null });
                    }}
                    disabled={isSubmitting}
                  >
                    Cancel
                  </Button>
                ) : (
                  <>
                    <Button
                      variant="danger"
                      onClick={() => {
                        setModal({ show: true, type: "resetPassword" });
                      }}
                      disabled={isSubmitting}
                    >
                      Reset password
                    </Button>
                    <Button
                      variant="danger"
                      onClick={() => {
                        setModal({ show: true, type: "delete" });
                      }}
                      disabled={
                        isSubmitting ||
                        selectedUser.userId === loggedInUser.userId
                      }
                    >
                      Delete User
                    </Button>
                  </>
                )}
              </div>
            ) : (
              <div className="d-flex justify-content-between">
                <Button type="submit" variant="success" disabled={isSubmitting}>
                  Create User
                </Button>
                <Button
                  variant="secondary"
                  onClick={() => {
                    setModal({ show: false, type: null });
                  }}
                  disabled={isSubmitting}
                >
                  Cancel
                </Button>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </>
  );
};

export default UserAccountForm;
