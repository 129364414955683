import { Button } from "react-bootstrap";

const AmazonASINLink = ({ ASIN }) => {
  return (
    <Button
      variant="link"
      className="link-info"
      title={"Click to view this ASIN on Amazon"}
      onClick={() => {
        window.open(`https://www.amazon.com/dp/${ASIN}`, "_blank");
      }}
    >
      {ASIN}
    </Button>
  );
};

export default AmazonASINLink;
