import React from "react";

import useAuth from "../../hooks/useAuth";

import { Dropdown } from "react-bootstrap";

import { Settings } from "react-feather";

const NavbarUser = () => {
  const { signOut } = useAuth();

  const vendor = JSON.parse(localStorage.getItem("vendor"));
  const wasRememberMeChecked = JSON.parse(
    localStorage.getItem("portal.returnsWorldwide.rememberMe")
  );
  const user = JSON.parse(localStorage.getItem("user"));
  const isAdmin = user?.isAdmin;

  return (
    <Dropdown className="nav-item" align="end">
      <span className="d-inline-block d-sm-none">
        <Dropdown.Toggle as="a" className="nav-link">
          <Settings size={18} className="align-middle" />
        </Dropdown.Toggle>
      </span>
      <span className="d-none d-sm-inline-block">
        <Dropdown.Toggle as="a" className="nav-link">
          {/* <img
            src={avatar}
            className="avatar img-fluid rounded-circle me-1"
            alt="Chris Wood"
          /> */}
          <span className="text-dark">{vendor?.name || "ADMIN"}</span>
        </Dropdown.Toggle>
      </span>
      <Dropdown.Menu drop="end">
        {/* <Dropdown.Item>
          <User size={18} className="align-middle me-2" />
          Profile
        </Dropdown.Item>
        <Dropdown.Item>
          <PieChart size={18} className="align-middle me-2" />
          Analytics
        </Dropdown.Item>
        <Dropdown.Divider /> */}
        {!isAdmin ? (
          <>
            <Dropdown.Item onClick={() => (window.location = "/profile")}>
              Profile
            </Dropdown.Item>
            <Dropdown.Item onClick={() => (window.location = "/help")}>
              Help
            </Dropdown.Item>
          </>
        ) : null}

        <Dropdown.Item
          onClick={() => {
            signOut(!wasRememberMeChecked);
            window.location = "/";
          }}
        >
          Sign Out
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default NavbarUser;
